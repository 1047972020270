import { es } from "date-fns/locale";
import { SalesPerHour } from "src/api/reports";
import { Generic } from "src/types";
import { format as dateFnsFormat } from "date-fns/format";
import { add as dateFnsAdd } from "date-fns/add";
import { sub as dateFnsSub } from "date-fns/sub";
import { eachWeekOfInterval } from "date-fns/eachWeekOfInterval";
import { endOfWeek } from "date-fns/endOfWeek";
import { eachDayOfInterval } from "date-fns/eachDayOfInterval";
import { startOfDay } from "date-fns/startOfDay";
import { endOfDay } from "date-fns/endOfDay";

type Timing = {
  add?: Generic;
  remove?: Generic;
};

export const isArgentinaTime = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const hoursOffset = offset !== 0 ? offset / 60 : 0;

  return hoursOffset === 3;
};

export const defaultDateFormat = "yyyy-MM-dd";

export const toArgentinaTime = { add: { hours: 3 } };

export const dateFormat = (
  date: Date | string,
  formatStr: string = defaultDateFormat,
  timing?: Timing
): string => {
  let mappedDate = date;
  const { add = null, remove = null } = timing ?? {};

  if (mappedDate === "") {
    mappedDate = new Date();
  }

  if (typeof mappedDate === "string") {
    // if (timeZone) {
    //   return dateFnsFormat(new Date(date), formatStr, { locale: es });
    // }

    const mappedDate = add
      ? dateFnsAdd(new Date(date), add)
      : remove
      ? dateFnsSub(new Date(date), remove)
      : new Date(date);

    return dateFnsFormat(mappedDate, formatStr, { locale: es });
  }

  // if (timeZone) {
  //   return dateFnsFormat(mappedDate, formatStr, { locale: es });
  // }

  const innerDate = add
    ? dateFnsAdd(mappedDate, add)
    : remove
    ? dateFnsSub(mappedDate, remove)
    : mappedDate;

  return dateFnsFormat(innerDate, formatStr, { locale: es });
};

export type Week = {
  weekNumber: number;
  from: Date;
  to: Date;
};

export const getWeeksOfYear = (): Week[] => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();
  const weeks: Week[] = [];

  const firstDayOfYear = new Date(year, 0, 1);
  const lastDayOfYear = new Date(year, month, day);

  const allWeeks = eachWeekOfInterval({
    start: firstDayOfYear,
    end: lastDayOfYear,
  });

  allWeeks.forEach((weekStart, index) => {
    const weekEnd = endOfWeek(weekStart);

    weeks.push({
      weekNumber: index + 1,
      from: weekStart,
      to: weekEnd,
    });
  });

  return weeks.reverse();
};

export const getDaysInRange = (dateFrom: Date, dateTo: Date): string[] => {
  const days: string[] = [];

  const allDays = eachDayOfInterval({
    start: startOfDay(dateFrom),
    end: endOfDay(dateTo),
  });

  allDays.forEach((day) => {
    days.push(dateFormat(day, defaultDateFormat));
  });

  return days;
};

export const populateDateRange = (
  dateRange: string[],
  values: SalesPerHour[]
): Map<string, Pick<SalesPerHour, "amount" | "date">[]> => {
  const filteredValues = new Map<
    string,
    Pick<SalesPerHour, "amount" | "date">[]
  >();

  dateRange.forEach((date) => {
    filteredValues.set(date, []);
  });

  values.forEach((value) => {
    const valueDate = value.date.substring(0, 10);

    const prevValues = filteredValues.get(valueDate) ?? [];

    filteredValues.set(valueDate, [
      ...prevValues,
      {
        amount: value.amount,
        date: value.date.substring(11, 16),
      },
    ]);
  });

  return filteredValues;
};

export const populateTimeRange = (
  timeRange: string[],
  values: Map<string, Pick<SalesPerHour, "amount" | "date">[]>
): { label: string; values: number[] }[] => {
  const populatedTimeRange: { label: string; values: number[] }[] = [];
  const timeRangesPerDate = Array.from(values.keys());

  timeRangesPerDate.forEach((date) => {
    const valuesPerDay = values.get(date) ?? [];
    const innerTimeRange = timeRange.map((time) => {
      const value = valuesPerDay.find((value) => value.date === time);

      return value?.amount ?? 0;
    });

    populatedTimeRange.push({
      label: date,
      values: innerTimeRange,
    });
  });

  return populatedTimeRange;
};
