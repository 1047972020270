import { useMutation } from "@apollo/client";
import {
  CCol,
  CRow,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CBadge,
  CFormSwitch,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Api from "src/api";
import { Company } from "src/api/companies";
import { CompanyPlan, Plan, PlanType, StorePlan } from "src/api/plans";
import { formatCurrency } from "src/helpers/numbers";

type StorePlanRowProps = {
  changeCompanyPlan: (value: PlanType) => void;
  plans: Plan[];
  companyPlan: CompanyPlan;
  currentPlan: StorePlan;
};

type ForwardedStorePlanRowProps = StorePlanRowProps & {
  values: any;
};

// @ts-expect-error
const StorePlanRow: ForwardRefRenderFunction<
  ForwardedStorePlanRowProps,
  StorePlanRowProps
> = forwardRef(
  ({ changeCompanyPlan, plans, companyPlan, currentPlan }, ref) => {
    const [activePlan, setActivePlan] = useState<PlanType | undefined>(
      currentPlan.type
    );

    const formik = useFormik({
      initialValues: {
        id: currentPlan?.store.id,
        users: companyPlan?.users ?? 1,
        invoicing: currentPlan.invoicing ?? 0,
        customPermissions: companyPlan?.customPermissions ?? false,
        support: companyPlan?.support ?? "BASIC",
        fixedPriceList: currentPlan?.fixedPriceList ?? true,
        type: currentPlan?.type,
      },
      onSubmit: () => {},
    });

    useImperativeHandle(
      ref,
      () => ({
        getValues: () => formik.values,
      }),
      [formik]
    );

    useEffect(() => {
      if (!activePlan) {
        formik.resetForm();
      } else {
        const newPlan = plans.find((plan) => plan.key === activePlan);
        const HOPlan = plans.find((plan) => plan.key === companyPlan.type);

        if (newPlan && HOPlan) {
          formik.setValues({
            id: currentPlan.store.id,
            users: newPlan?.users ?? 0,
            invoicing: newPlan?.invoicing ?? 0,
            fixedPriceList: newPlan?.fixedPriceList ?? false,
            customPermissions: newPlan?.customPermissions ?? false,
            support: newPlan?.support ?? "BASIC",
            type: activePlan,
          });

          if (newPlan.id > HOPlan.id) {
            changeCompanyPlan(newPlan.key);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan]);

    return (
      <>
        <CRow className="mt-3 px-1">
          <h5>🏣 {currentPlan.store.name}</h5>
        </CRow>
        <CRow className="mt-1">
          <CCol>
            {plans.map((plan) => (
              <CBadge
                style={{ userSelect: "none", cursor: "pointer" }}
                onClick={() =>
                  setActivePlan((prevPlan) =>
                    prevPlan === plan.key ? undefined : plan.key
                  )
                }
                key={plan.key}
                className="mr-2"
                color={plan.key === activePlan ? "primary" : "dark"}
              >
                {plan.name.toUpperCase()}
              </CBadge>
            ))}
          </CCol>
        </CRow>
        <CRow className="mt-3">
          <CCol sm={2}>
            <CFormInput
              readOnly={activePlan !== PlanType.Business}
              floatingLabel="Usuarios"
              type="number"
              min={1}
              value={formik.values.users}
              onChange={formik.handleChange}
              name="users"
            />
          </CCol>
          <CCol sm={2}>
            <CFormInput
              readOnly={activePlan !== PlanType.Business}
              name="invocing"
              floatingLabel="Tope de Facturacion"
              value={formatCurrency(formik.values.invoicing)}
            />
          </CCol>
          <CCol sm={2}>
            <CFormLabel>Lista Fija</CFormLabel>
            <CFormSwitch
              name="fixedPriceList"
              readOnly
              checked={formik.values.fixedPriceList}
            />
          </CCol>
          <CCol sm={3}>
            <CFormLabel>Permisos personalizados</CFormLabel>
            <CFormSwitch
              name="customPermissions"
              readOnly
              checked={formik.values.customPermissions}
            />
          </CCol>
          <CCol sm={3}>
            <CFormSelect
              floatingLabel="Soporte"
              readOnly={activePlan !== PlanType.Business}
              value={formik.values.support}
              onChange={formik.handleChange}
              name="support"
            >
              <option value="BASIC">Basico</option>
              <option value="MEDIUM">Intermedio</option>
              <option value="CUSTOM">Personalizado</option>
            </CFormSelect>
          </CCol>
        </CRow>
      </>
    );
  }
);

const PlanTab = (
  {
    company,
    companyPlan,
    onLoading,
    plans,
  }: {
    company: Company;
    companyPlan: CompanyPlan;
    onLoading: (v: boolean) => void;
    plans: Plan[];
  },
  ref
) => {
  const storesRef = useRef<ForwardedStorePlanRowProps[]>();

  const [activePlan, setActivePlan] = useState<PlanType | undefined>(
    companyPlan?.type
  );

  const [updateMutation, { loading: updating }] = useMutation(
    Api.Plans.UPDATE_COMPANY_PLAN,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      users: companyPlan?.users ?? 1,
      invoicing: companyPlan?.stores[0]?.invoicing ?? 0,
      customPermissions: companyPlan?.customPermissions ?? false,
      support: companyPlan?.support ?? "BASIC",
      fixedPriceList: companyPlan?.stores[0]?.fixedPriceList ?? true,
      type: companyPlan?.type,
    },
    onSubmit: (data) => {
      const storesPlans = Object.entries(storesRef)
        .filter(([key]) => key !== "current")
        .map(([_, value]) => value.getValues());

      if (!updating) {
        const input = {
          users: data.users,
          invoicing: data.invoicing,
          fixedPriceList: data.fixedPriceList,
          customPermissions: data.customPermissions,
          support: data.support,
          type: data.type,
          storesPlans,
        };

        updateMutation({
          variables: {
            companyId: company.id,
            input,
          },
        });
      }
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      submit: () => formik.handleSubmit(),
    }),
    [formik]
  );

  useEffect(() => {
    if (!activePlan) {
      formik.resetForm();
    } else {
      const currentPlan = plans.find((plan) => plan.key === activePlan);

      formik.setValues({
        users: currentPlan?.users ?? 0,
        invoicing: currentPlan?.invoicing ?? 0,
        fixedPriceList: currentPlan?.fixedPriceList ?? false,
        customPermissions: currentPlan?.customPermissions ?? false,
        support: currentPlan?.support ?? "BASIC",
        type: activePlan,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlan]);

  useEffect(() => {
    onLoading(updating);
  }, [updating, onLoading]);

  return (
    <>
      <CRow>
        <CCol>
          {plans.map((plan) => (
            <CBadge
              style={{ userSelect: "none", cursor: "pointer" }}
              onClick={() =>
                setActivePlan((prevPlan) =>
                  prevPlan === plan.key ? undefined : plan.key
                )
              }
              key={plan.id}
              className="mr-2"
              color={plan.key === activePlan ? "primary" : "dark"}
            >
              {plan.name.toUpperCase()}
            </CBadge>
          ))}
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol sm={2}>
          <CFormInput
            readOnly={activePlan !== PlanType.Business}
            floatingLabel="Usuarios"
            type="number"
            min={1}
            value={formik.values.users}
            onChange={formik.handleChange}
            name="users"
          />
        </CCol>
        <CCol sm={2}>
          <CFormInput
            readOnly={activePlan !== PlanType.Business}
            name="invocing"
            floatingLabel="Tope de Facturacion"
            value={formatCurrency(formik.values.invoicing)}
          />
        </CCol>
        <CCol sm={2}>
          <CFormLabel>Lista Fija</CFormLabel>
          <CFormSwitch
            name="fixedPriceList"
            readOnly
            checked={formik.values.fixedPriceList}
          />
        </CCol>
        <CCol sm={3}>
          <CFormLabel>Permisos personalizados</CFormLabel>
          <CFormSwitch
            name="customPermissions"
            readOnly
            checked={formik.values.customPermissions}
          />
        </CCol>
        <CCol sm={3}>
          <CFormSelect
            floatingLabel="Soporte"
            readOnly={activePlan !== PlanType.Business}
            value={formik.values.support}
            onChange={formik.handleChange}
            name="support"
          >
            <option value="BASIC">Basico</option>
            <option value="MEDIUM">Intermedio</option>
            <option value="CUSTOM">Personalizado</option>
          </CFormSelect>
        </CCol>
      </CRow>

      {companyPlan?.stores.map((storePlan) => (
        <StorePlanRow
          changeCompanyPlan={setActivePlan}
          plans={plans}
          key={storePlan.store.id}
          currentPlan={storePlan}
          companyPlan={companyPlan}
          // @ts-expect-error
          ref={(e) => (storesRef[storePlan.store.id] = e)}
        />
      ))}
    </>
  );
};

export default forwardRef(PlanTab);
