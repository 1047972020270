import { MercadoLibreSale, MercadoLibreStatus } from "src/api/mercado-libre";
import Config from "src/config";

export const parseSaleStatus = (status: MercadoLibreStatus) => {
  switch (status) {
    case MercadoLibreStatus.Rejected:
      return {
        label: "Rechazada",
        color: "danger",
      };

    default:
    case MercadoLibreStatus.Approved:
      return {
        label: "Aprobada",
        color: "success",
      };

    case MercadoLibreStatus.Paid:
      return {
        label: "Pagada",
        color: "info",
      };
  }
};

export const calculateSummary = (sale: MercadoLibreSale) => {
  const summary = sale.payments.reduce(
    (prev, curr) => ({
      paid: prev.paid + curr.amounts.paid,
      subtotal: prev.subtotal + curr.amounts.transaction,
      shipping: prev.shipping + curr.amounts.shipping,
      fee: prev.fee,
      total: prev.total,
    }),
    {
      paid: 0,
      subtotal: 0,
      total: 0,
      shipping: 0,
      fee: sale.items.reduce((p, c) => c.fee + p, 0),
    }
  );

  return {
    ...summary,
    total: summary.subtotal - summary.fee,
  };
};

export const generateConnectUrl = (type: string, storeId: number) => {
  const clientId = type === "mp" ? Config.mpClientId : Config.mlClientId;
  const redirectUrl =
    type === "mp" ? Config.mpRedirectUrl : Config.mlRedirectUrl;
  const path = type === "mp" ? "mercadopago" : "mercadolibre";

  return `https://auth.${path}.com.ar/authorization?client_id=${clientId}&response_type=code&platform_id=mp&state=${type}-${storeId}&redirect_url=${redirectUrl}`;
};
