import { CAlert, CCard, CCardBody } from "@coreui/react-pro";

const AfipError = () => (
  <CCard>
    <CCardBody>
      <CAlert color="danger" className="mb-0">
        En este momento los servicios de AFIP no se encuentran funcionando, por
        lo que esta pagina no funcionara. Prueba nuevamente mas tarde.
      </CAlert>
    </CCardBody>
  </CCard>
);

export default AfipError;
