import { gql } from "@apollo/client";

export const LIST_SESSIONS = gql`
  query sessions($filters: CashRegisterSessionFilters) {
    data: sessions(filters: $filters) {
      data {
        id
        initialAmount
        openedAt
        closedAt

        user {
          id
          name
          lastname
        }

        register {
          id
          name

          store {
            id
            name
          }
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;
