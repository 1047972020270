import { useMutation } from "@apollo/client";
import {
  CCardBody,
  CCardFooter,
  CCol,
  CForm,
  CFormInput,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useState } from "react";
import { SelectSearchOption } from "react-select-search";
import Api from "src/api";
import { Customer } from "src/api/customers";
import { Order, OrderStatus } from "src/api/orders";
import { dateFormat } from "src/helpers/dates";
import { calculateSummary } from "src/helpers/orders";
import SummaryRow from "./SummaryRow";

const PendingOrder = ({
  customers,
  order,
  products,
}: {
  customers: Customer[];
  order: Order;
  products: SelectSearchOption[];
}) => {
  const [summary, setSummary] = useState(calculateSummary(order.items, 0));

  const [updateOrder, { loading: updating }] = useMutation(
    Api.Orders.UPDATE_ORDER,
    {
      onCompleted: ({ data }) => {
        if (data.status === OrderStatus.Confirmed) {
          alert("El pedido ha sido confirmado existosamente.");
        }

        if (data.status === OrderStatus.Cancelled) {
          alert("El pedido ha sido cancelado existosamente");
        }

        window.location.reload();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      customerId: order.customer.id,
      discount: 0,
      status: order.status,
      items: order.items,
    },
    onSubmit: (data) => {
      if (!updating) {
        const input = {
          discount: data.discount,
          items: data.items.map((item) => ({
            id: item.id,
            productId: item.product.id,
            priceListItemId: item.priceListItem.id,
            price: item.confirmedPrice,
            quantity: item.confirmedQuantity,
            tax: item.confirmedTax,
          })),
          status: OrderStatus.Confirmed,
        };

        updateOrder({
          variables: {
            id: order.id,
            input,
          },
        });
      }
    },
  });

  return (
    <>
      <CCardBody>
        <CForm>
          <CRow className="align-items-center justify-content-center mb-2">
            <CCol
              sm={6}
              className="pl-4"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <span>
                Cliente: <strong>{order.customer.name}</strong>
              </span>
            </CCol>
            <CCol sm={6} className="px-4 text-right">
              Fecha de Solicitud:{" "}
              <strong>
                {dateFormat(new Date(order.date), "dd/MM/yyyy HH:mm")}
              </strong>
            </CCol>
          </CRow>

          <div
            className="position-relative table-responsive"
            style={{ overflow: "visible" }}
          >
            <table className="table table-striped ">
              <thead>
                <tr>
                  <th
                    className=""
                    style={{
                      verticalAlign: "middle",
                      overflow: "hidden",
                    }}
                  >
                    <div className="d-inline">Producto</div>
                  </th>
                  <th
                    className="text-center"
                    style={{
                      verticalAlign: "middle",
                      overflow: "hidden",
                    }}
                  >
                    <div className="d-inline">Cantidad</div>
                  </th>
                  <th
                    className="text-right"
                    style={{
                      verticalAlign: "middle",
                      overflow: "hidden",
                    }}
                  >
                    <div className="d-inline">Precio</div>
                  </th>
                  <th
                    className="text-right"
                    style={{
                      verticalAlign: "middle",
                      overflow: "hidden",
                    }}
                  >
                    <div className="d-inline">Precio Actual</div>
                  </th>
                  <th
                    className="text-right"
                    style={{
                      verticalAlign: "middle",
                      overflow: "hidden",
                    }}
                  >
                    <div className="d-inline">Subtotal</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {formik.values.items.map((item, index) => {
                  return (
                    <tr key={item.product.id}>
                      <td width="50%">
                        <CFormInput
                          readOnly
                          disabled
                          value={item.product?.name ?? ""}
                        />
                      </td>
                      <td className="text-center">
                        <CFormInput
                          defaultValue={item.confirmedQuantity}
                          min={0}
                          type="number"
                          className="text-center"
                          onChange={(e) => {
                            formik.values.items[index].confirmedQuantity =
                              Number(e.target.value);

                            formik.setFieldValue("items", formik.values.items);

                            setSummary(
                              calculateSummary(
                                formik.values.items,
                                formik.values.discount
                              )
                            );
                          }}
                        />
                      </td>
                      <td className="text-right">
                        <CFormInput
                          className="text-right"
                          type="number"
                          readOnly
                          disabled
                          value={item.requestedPrice}
                        />
                      </td>
                      <td className="text-right">
                        <CFormInput
                          className="text-right"
                          type="number"
                          min={0}
                          defaultValue={item.confirmedPrice}
                          onChange={(e) => {
                            formik.values.items[index].confirmedPrice = Number(
                              e.target.value
                            );

                            formik.setFieldValue("items", formik.values.items);

                            setSummary(
                              calculateSummary(
                                formik.values.items,
                                formik.values.discount
                              )
                            );
                          }}
                        />
                      </td>
                      <td className="text-right">
                        <CFormInput
                          className="text-right"
                          type="number"
                          readOnly
                          disabled
                          value={item.confirmedQuantity * item.confirmedPrice}
                        />
                      </td>
                    </tr>
                  );
                })}
                {/*
                    <tr>
                      <td width="50%">
                        <SmartSelect
                          name="productId"
                          options={products}
                          search
                          value={selectValue as unknown as string}
                          placeholder="Seleccione producto"
                          debounce={300}
                          onChange={handleChange}
                        />
                      </td>
                      <td className="text-center">
                        <CFormInput
                          defaultValue={1}
                          id="quantity_0"
                          className="text-center"
                        />
                      </td>
                      <td className="text-right">
                        <CFormInput
                          className="text-right"
                          readOnly
                          value={currentItem?.price ?? ""}
                        />
                      </td>
                      <td className="text-right">
                        <CFormInput
                          className="text-right"
                          value={currentItem?.price ?? ""}
                        />
                      </td>
                      <td className="text-right">
                        <CButton type="button" onClick={addItem} color="info">
                          <Icon name="plus" />
                        </CButton>
                      </td>
                    </tr>
                    */}
              </tbody>
            </table>
          </div>
        </CForm>

        <hr className="pt-0 mt-0" />

        <SummaryRow label="Subtotal" value={summary.subtotal} />
        <SummaryRow label="IVA" value={summary.tax} />
        <SummaryRow label="Descuento" value={summary.discount} />
        <SummaryRow label="Total" value={summary.total} />
      </CCardBody>
      <CCardFooter className="px-3">
        <CRow className="px-0 align-items-center">
          <CCol md="12" className="flex justify-content-end">
            <CLoadingButton
              onClick={() => {
                updateOrder({
                  variables: {
                    id: order.id,
                    input: {
                      status: OrderStatus.Cancelled,
                      items: [],
                    },
                  },
                });
              }}
              loading={updating}
              type="button"
              className="mr-2"
              size="sm"
              color="danger"
            >
              Rechazar
            </CLoadingButton>
            <CLoadingButton
              onClick={formik.handleSubmit}
              type="button"
              loading={updating}
              size="sm"
              color="success"
            >
              Confirmar
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </>
  );
};

export default PendingOrder;
