import { useMutation, useQuery } from "@apollo/client";
import {
  CCol,
  CForm,
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CCardFooter,
  CButton,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Api from "src/api";
import { Company } from "src/api/companies";
import { CreatePermissionGroup } from "src/api/permissions";
import { logEvent } from "src/helpers/analytics";
import { getValidity } from "src/helpers/validation";
import { GraphQLMeta } from "src/types";
import CompanyPermissions from "./components/CompanyPermissions";
import StorePermissions from "./components/StorePermissions";

const NewGroup = () => {
  const navigate = useNavigate();
  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );
  const [mutation, { loading }] = useMutation(Api.Permissions.CREATE_GROUP, {
    onCompleted: ({ data: group }) => {
      navigate(`/groups/${group.id}`);
    },
  });

  const formik = useFormik<CreatePermissionGroup>({
    initialValues: {
      name: "",
      companyId: 0,
      permissions: [],
    },
    onSubmit: (data) => {
      if (!loading) {
        const input = {
          name: data.name,
          companyId: data.companyId ? Number(data.companyId) : null,
          permissions: data.permissions,
        };

        logEvent("permissions-group.create", {
          input,
        });

        mutation({
          variables: {
            input,
          },
        });
      }
    },
  });

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Crear Grupo de Permisos</CCardHeader>
          <CCardBody>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="companyId">Empresa</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="companyId"
                  name="companyId"
                  onChange={formik.handleChange}
                  defaultValue={formik.values.companyId}
                  {...getValidity(
                    formik.values.companyId,
                    formik.errors.companyId
                  )}
                >
                  <option key="0" value="0">
                    Seleccione Empresa
                  </option>
                  {companies?.data.data.map((company: Company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="form-row mt-3">
              <CFormLabel>Permisos de Empresa</CFormLabel>
              <CompanyPermissions formik={formik} />
            </CRow>
            <CRow className="form-row mt-3">
              <CFormLabel>Permisos de Punto de Venta</CFormLabel>
              <StorePermissions formik={formik} />
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow className="px-0">
              <CCol md="9"></CCol>
              <CCol md="3" className="flex justify-content-end">
                <CButton
                  color="primary"
                  size="sm"
                  disabled={loading || !formik.isValid}
                  type="submit"
                >
                  Crear
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default NewGroup;
