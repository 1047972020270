import { useLocation } from "react-router-dom";
import { AppLoader } from "src/components/Loader/Loader";
import { useAdminStore } from "src/store";

import NewRegisterAdmin from "./components/NewRegisterAdmin";
import NewRegisterOwner from "./components/NewRegisterOwner";

const NewRegister = () => {
  const location = useLocation();
  const { user } = useAdminStore();
  const { storeId } = location.state ?? {};

  if (!user) {
    return <AppLoader />;
  }

  if (user?.isAdmin) {
    return <NewRegisterAdmin storeId={storeId} />;
  }

  return <NewRegisterOwner />;
};

export default NewRegister;
