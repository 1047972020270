import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import { useMemo } from "react";
import Api from "src/api";
import { AfipInvoiceType, AfipPointOfSale } from "src/api/afip";
import { GraphQLFind } from "src/types";

export const useAfipData = (companyId: number, storeId?: number) => {
  const { data: invoiceTypes, error: invoiceError } = useQuery<
    GraphQLFind<AfipInvoiceType[]>
  >(Api.Afip.LIST_INVOICE_TYPES, {
    variables: {
      companyId,
      storeId,
    },
  });

  const { data: pos, error: posError } = useQuery<
    GraphQLFind<AfipPointOfSale[]>
  >(Api.Afip.LIST_POINT_OF_SALES, {
    variables: {
      companyId,
      storeId,
    },
  });

  const afipData = useMemo(
    () => ({
      invoiceTypes: sortBy(invoiceTypes?.data ?? [], "name"),
      pos: sortBy(pos?.data ?? [], "type"),
    }),
    [invoiceTypes?.data, pos?.data]
  );

  return {
    afipData,
    canOperate: afipData.pos.length > 0 && afipData.invoiceTypes.length > 0,
    afipError: posError || invoiceError,
  };
};
