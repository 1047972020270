import { gql } from "@apollo/client";

export const GET_DATA = gql`
  query getData($storeId: Int!, $registerId: Int) {
    data: getData(storeId: $storeId, registerId: $registerId) {
      pos {
        id
        name
      }
      stores {
        id
        name
      }
      storeId
      registerId
    }
  }
`;

export const CREATE_LINK_STORE = gql`
  mutation createAndLinkStore($storeId: Int!, $name: String!) {
    createAndLinkStore(storeId: $storeId, name: $name) {
      id
    }
  }
`;

export const LINK_STORE = gql`
  mutation linkStore($storeId: Int!, $mercadoPagoPOSId: Int!) {
    linkStore(storeId: $storeId, mercadoPagoPOSId: $mercadoPagoPOSId) {
      id
    }
  }
`;

export const CREATE_LINK_REGISTER = gql`
  mutation createAndLinkRegister($registerId: Int!, $name: String!) {
    createAndLinkRegister(registerId: $registerId, name: $name) {
      id
    }
  }
`;

export const LINK_REGISTER = gql`
  mutation linkRegister($registerId: Int!, $mercadoPagoRegisterId: Int!) {
    linkRegister(
      registerId: $registerId
      mercadoPagoRegisterId: $mercadoPagoRegisterId
    ) {
      id
    }
  }
`;
