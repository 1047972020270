import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CSmartTable,
  CCollapse,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react-pro";
import { Product, ProductAudit, mapProductAudit } from "src/api/products";
import { dateFormat } from "src/helpers/dates";
import { parseAuditAction, parseAuditChanges } from "src/api/audit";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import Api from "src/api";
import { GraphQLFind } from "src/types";

const AuditCard = ({ categories, suppliers, productId }) => {
  const [auditDetails, setAuditDetails] = useState<number>();
  const { data: audits, loading: auditing } = useQuery<
    GraphQLFind<Product["audits"]>
  >(Api.Products.LIST_AUDITS, {
    variables: {
      id: productId,
    },
  });

  return (
    <CCard className="mt-3">
      <CCardHeader>Historial de Cambios</CCardHeader>
      <CCardBody>
        <CSmartTable
          items={audits?.data}
          loading={auditing}
          columns={[
            { key: "user", label: "Usuario" },
            { key: "action", label: "Acción" },
            {
              key: "date",
              label: "Fecha",
              _props: { className: "text-right" },
            },
          ]}
          tableProps={{
            striped: true,
            hover: true,
          }}
          onRowClick={(item: Item) => {
            if (item.action !== "UPDATE") return;

            const changes = JSON.parse(item.to);

            if (changes?.deletedAt === null) {
              return;
            }

            setAuditDetails((prev) => (prev === item.id ? undefined : item.id));
          }}
          clickableRows
          scopedColumns={{
            action: (item: ProductAudit) => <td>{parseAuditAction(item)}</td>,
            user: (item: ProductAudit) => (
              <td>
                {item.user.name} {item.user.lastname}
              </td>
            ),
            changes: (item: ProductAudit) => (
              <td>{parseAuditChanges(item.action, item.to)}</td>
            ),
            date: (item: ProductAudit) => (
              <td align="right">{dateFormat(item.date, "dd/MM/yyyy HH:mm")}</td>
            ),
            details: (item: Item) => (
              <CCollapse visible={auditDetails === item.id}>
                <CTable className="mb-0">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">Campo</CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Valor Anterior
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">
                        Valor Posterior
                      </CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {mapProductAudit(item as ProductAudit, {
                      categories,
                      suppliers,
                    }).map((row, index) => (
                      <CTableRow key={index}>
                        <CTableDataCell>{row.label}</CTableDataCell>
                        <CTableDataCell>
                          {row.key === "images" && Array.isArray(row.prevValue)
                            ? row.prevValue.map((src, idx) => (
                                <img
                                  key={idx}
                                  src={`${src}`}
                                  height={40}
                                  width={40}
                                  className="mr-1"
                                  alt=""
                                />
                              ))
                            : row.prevValue}
                        </CTableDataCell>
                        <CTableDataCell>
                          {row.key === "images" && Array.isArray(row.nextValue)
                            ? row.nextValue.map((src, idx) => (
                                <img
                                  key={idx}
                                  src={`${src}`}
                                  height={40}
                                  width={40}
                                  className="mr-1"
                                  alt=""
                                />
                              ))
                            : row.nextValue}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCollapse>
            ),
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default AuditCard;
