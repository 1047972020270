import { CSmartPagination } from "@coreui/react-pro";

const Pagination = ({ meta, page, pageChange }) => {
  if ((meta?.data.meta.lastPage ?? 0) > 1) {
    return (
      <CSmartPagination
        activePage={page}
        onActivePageChange={pageChange}
        pages={meta?.data.meta.lastPage ?? 1}
        doubleArrows={false}
        align="center"
      />
    );
  }

  return null;
};

export default Pagination;
