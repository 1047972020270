import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Api from "src/api";
import { Customer } from "src/api/customers";
import { Sale } from "src/api/sales";
import { logEvent } from "src/helpers/analytics";
import { ForwardedModalProps, GraphQLMeta } from "src/types";

type AssignCustomerToSale = {
  customerId: number | "new";
  name: string;
  lastname: string;
  email: string;
  dni: number;
};

const CustomersModal: ForwardRefRenderFunction<ForwardedModalProps> = (
  _,
  ref
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [sale, setSale] = useState<Sale>();
  const [newCustomer, setnewCustomer] = useState<boolean>(false);

  const { data: customers } = useQuery<GraphQLMeta<Customer>>(
    Api.Customers.LIST_CUSTOMERS,
    {
      skip: !sale,
      variables: {
        filters: {
          limit: 0,
          companyId: sale?.store.company.id,
          storeId: sale?.store.id,
        },
      },
    }
  );

  const [mutation, { loading }] = useMutation(Api.Customers.ASSIGN_TO_SALE, {
    onCompleted: () => {
      window.location.reload();
      handleDismiss();
    },
  });

  const handleDismiss = () => {
    setVisible(false);
    setSale(undefined);
  };

  const formik = useFormik<AssignCustomerToSale>({
    initialValues: {
      customerId: 0,
      dni: 0,
      name: "",
      lastname: "",
      email: "",
    },
    onSubmit: (values) => {
      if (!loading && sale) {
        const customerId = Number(values.customerId);

        const input = {
          saleId: sale.id,
          customerId,
          customer:
            customerId === -1
              ? {
                  dni: Number(values.dni),
                  name: values.name,
                  lastname: values.lastname,
                  email: values.email,
                }
              : null,
        };

        logEvent("sale.assign-client", {
          sale,
          input,
        });

        mutation({
          variables: {
            input,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (Number(formik.values.customerId) === -1) {
      setnewCustomer(true);
    } else {
      setnewCustomer(false);
    }
  }, [formik, formik.values.customerId]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (s: Sale) => {
        setVisible(true);
        setSale(s);
      },
    }),
    []
  );

  return (
    <CModal alignment="center" visible={visible} onClose={handleDismiss}>
      <CModalHeader closeButton>
        Asignar cliente a Venta #{sale?.id}
      </CModalHeader>
      <CModalBody>
        <CForm>
          <CFormSelect
            floatingLabel="Cliente"
            name="customerId"
            onChange={formik.handleChange}
          >
            <option value={0}>Consumidor Final</option>
            <option value={-1}>Nuevo cliente</option>
            {customers?.data.data.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {`${customer.id} - ${
                  customer.name !== ""
                    ? `${customer.name} - ${customer.utid}`
                    : customer.utid
                }`}
              </option>
            ))}
          </CFormSelect>

          {newCustomer && (
            <>
              <CRow className="mt-3">
                <CCol sm="6">
                  <CFormInput
                    name="name"
                    onChange={formik.handleChange}
                    floatingLabel="Nombre"
                  />
                </CCol>
                <CCol sm="6">
                  <CFormInput
                    name="lastname"
                    onChange={formik.handleChange}
                    floatingLabel="Apellido"
                  />
                </CCol>
              </CRow>
              <CRow className="mt-3">
                <CCol sm="6">
                  <CFormInput
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    floatingLabel="Email"
                  />
                </CCol>
                <CCol sm="6">
                  <CFormInput
                    name="dni"
                    type="number"
                    onChange={formik.handleChange}
                    floatingLabel="Número de DNI / CUIL / CUIT"
                  />
                </CCol>
              </CRow>
            </>
          )}
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={() => handleDismiss()}>
          Cancelar
        </CButton>
        <CButton
          size="sm"
          disabled={!formik.isValid}
          color="success"
          onClick={() => formik.handleSubmit()}
        >
          Asignar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(CustomersModal);
