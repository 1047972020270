import { forwardRef } from "react";
import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { GraphQLFind } from "src/types";
import { AppLoader } from "src/components/Loader/Loader";
import { normalize } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import { useAdminStore } from "src/store";
import { Sector, UpdateSector } from "src/api/sectors";

const Screen = () => {
  const { user } = useAdminStore();
  const navigate = useNavigate();
  const params = useParams();
  const sectorId = Number(params.id);

  if (!sectorId || !user?.isAdmin) {
    navigate("/sectors");
  }

  const { data: sector } = useQuery<GraphQLFind<Sector>>(
    Api.Sectors.GET_SECTOR,
    {
      variables: {
        id: sectorId,
      },
    }
  );

  if (!sector?.data) {
    return <AppLoader />;
  }

  return (
    <CCard>
      <Form sector={sector.data} />
    </CCard>
  );
};

const Form = ({ sector }: { sector: Sector }) => {
  const navigate = useNavigate();

  const [mutation, { error, loading }] = useMutation(
    Api.Sectors.UPDATE_SECTOR,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const onNameChange = (e) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("slug", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  const formik = useFormik<UpdateSector>({
    initialValues: {
      name: sector.name,
      slug: sector.slug,
      notes: sector.notes,
    },
    onSubmit: (input) => {
      if (!loading) {
        const data = {
          name: input.name?.trim().toUpperCase(),
          slug: input.slug ?? normalize(input.name),
          notes: input.notes.trim() ?? null,
        };

        logEvent("sector.update", {
          input: data,
        });

        mutation({
          variables: {
            id: sector.id,
            input: data,
          },
        });
      }
    },
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCardHeader>Editar Rubro: {sector.name}</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xs="12">
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={onNameChange}
                  value={formik.values.name}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="slug">Identificador</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="slug"
                  placeholder="Identificador"
                  name="slug"
                  disabled
                  value={formik.values.slug ?? ""}
                  readOnly
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="notes">Notas</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="notes"
                  placeholder="Notas"
                  onChange={formik.handleChange}
                  name="notes"
                  defaultValue={formik.values.notes}
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow className="px-0">
          <CCol md="9">
            {error && (
              <ErrorMessage text="Los datos ingresados son incorrectos" />
            )}
          </CCol>
          <CCol md="3" className="flex justify-content-end">
            <CLoadingButton
              loading={loading}
              size="sm"
              disabled={loading}
              color="primary"
              type="submit"
            >
              Guardar cambios
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export default forwardRef(Screen);
