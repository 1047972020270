import { CCol, CLink, CRow } from "@coreui/react-pro";
import { formatCurrency } from "src/helpers/numbers";

const SummaryRow = ({
  leftLabel,
  leftValue,
  leftLink,
  label,
  value,
}: {
  leftLabel?: string;
  leftValue?: string;
  leftLink?: string;
  label: string;
  value: number;
}) => (
  <CRow className="ml-0 mr-0 pl-0 pr-0 align-items-center justify-content-between mb-2">
    <CCol
      sm={5}
      md={6}
      className="row justify-content-start align-items-center"
    >
      <CCol
        sm={3}
        md={5}
        className="px-0 text-left font-weight-bold text-uppercase"
      >
        {leftLabel}
      </CCol>
      <CCol
        sm={6}
        className="text-right font-weight-bold"
        style={{ paddingRight: 12 }}
      >
        {leftLink ? <CLink href={leftLink}>{leftValue}</CLink> : leftValue}
      </CCol>
    </CCol>
    <CCol
      sm={5}
      md={6}
      className="pr-0 row justify-content-end align-items-center"
    >
      <CCol
        sm={6}
        md={7}
        className="px-0 text-right font-weight-bold text-uppercase"
      >
        {label}
      </CCol>
      <CCol
        sm={6}
        md={5}
        className="text-right font-weight-bold"
        style={{ paddingRight: 12 }}
      >
        {formatCurrency(value)}
      </CCol>
    </CCol>
  </CRow>
);

export default SummaryRow;
