import {
  CButton,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { CashRegister } from "src/api/registers";

export type DialogProps = {
  closeOnSubmit?: boolean;
  visible?: boolean;
  onSubmit?: (props?: any) => void;
  onDismiss?: () => void;
};

export type CustomDialogProps = DialogProps & {
  open: (props?: any) => void;
  close: (props?: any) => void;
};

const ConnectModal: ForwardRefRenderFunction<CustomDialogProps, DialogProps> = (
  { closeOnSubmit = false, onDismiss, onSubmit, visible = false },
  ref
) => {
  const [isVisible, setIsVisible] = useState<boolean>(visible);
  const [MPData, setMPData] = useState<any>();

  const formik = useFormik({
    initialValues: {
      name: "",
      registerId: undefined,
      mercadoPagoRegisterId: undefined,
    },
    onSubmit: (data) => {
      if (data.mercadoPagoRegisterId && data.registerId) {
        onSubmit?.({
          name: data.name,
          mercadoPagoRegisterId: Number(data.mercadoPagoRegisterId),
          registerId: Number(data.registerId),
        });

        if (closeOnSubmit) {
          handleDismiss();
        }
      }
    },
  });

  const handleDismiss = useCallback(() => {
    setIsVisible(false);
    formik.resetForm();

    onDismiss?.();
  }, [formik, onDismiss]);

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();

    if (closeOnSubmit) {
      onDismiss?.();
    }
  }, [closeOnSubmit, formik, onDismiss]);

  useImperativeHandle(
    ref,
    () => ({
      open: ({ register, mpData }: { register: CashRegister; mpData: any }) => {
        formik.setFieldValue("registerId", register.id);
        formik.setFieldValue("name", register.name);
        setIsVisible(true);
        setMPData(mpData);
      },

      close: () => handleDismiss(),
    }),
    [formik, handleDismiss]
  );

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <CModal alignment="center" visible={isVisible} onClose={handleDismiss}>
      <CModalHeader closeButton>Conectar con MercadoPago</CModalHeader>
      <CModalBody>
        <p>
          Elige la <strong>Caja de Mercado Pago</strong> con la que quieres
          conectar esta caja
        </p>
        <CFormSelect
          name="mercadoPagoRegisterId"
          onChange={formik.handleChange}
        >
          <option value="0">Seleccione Caja</option>
          {MPData?.pos?.map(({ id, name }: any) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
          <option value="-1">Crear Nueva</option>
        </CFormSelect>
        {formik.values.mercadoPagoRegisterId === "-1" && (
          <CFormInput
            className="mt-3"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Nombre de la Caja"
            floatingLabel="Nombre"
          />
        )}
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={handleDismiss}>
          Cancelar
        </CButton>
        <CButton size="sm" color="success" onClick={handleSubmit}>
          {formik.values.mercadoPagoRegisterId === "-1" ? "Crear y " : ""}
          Conectar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(ConnectModal);
