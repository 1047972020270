import { gql } from "@apollo/client";

export const DOWNLOAD_RAPPI_PRODUCTS = gql`
  mutation downloadRappiProducts($priceListId: Int!) {
    data: downloadRappiProducts(priceListId: $priceListId) {
      data {
        id
        name
        price
        sku
      }
      meta {
        lastPage
      }
    }
  }
`;

export const DOWNLOAD_IMAGES = gql`
  mutation downloadRappiImages($storeId: Int!) {
    data: downloadRappiImages(storeId: $storeId) {
      data {
        id
      }
    }
  }
`;
