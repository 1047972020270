import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
  CMultiSelect,
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Company } from "src/api/companies";
import { Store, StoreSchema } from "src/api/stores";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { GraphQLFind, GraphQLMeta } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { useAdminStore } from "src/store";
import { Warehouse, WarehouseUpdate } from "src/api/warehouses";
import { logEvent } from "src/helpers/analytics";
import { useState } from "react";

const WarehouseScreen = () => {
  const navigate = useNavigate();
  const { currentCompany } = useAdminStore();
  const params = useParams();
  const warehouseId = Number(params.id);

  if (!currentCompany?.id || !warehouseId) {
    navigate("/warehouses");
  }

  const { data: company } = useQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      variables: {
        id: currentCompany?.id,
      },
    }
  );

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          companyId: currentCompany?.id,
        },
      },
    }
  );

  const { data: warehouse } = useQuery<GraphQLFind<Warehouse>>(
    Api.Warehouses.GET_WAREHOUSE,
    {
      variables: {
        id: warehouseId,
      },
      fetchPolicy: "no-cache",
    }
  );

  if (!company?.data || !stores?.data.data || !warehouse?.data) {
    return null;
  }

  return (
    <CCol lg={12}>
      <Form
        company={company.data}
        stores={stores.data.data}
        warehouse={warehouse.data}
      />
    </CCol>
  );
};

const Form = ({
  company,
  stores,
  warehouse,
}: {
  company: Company;
  stores: Store[];
  warehouse: Warehouse;
}) => {
  const { hasPermission } = useAdminStore();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [mutation, { loading }] = useMutation(Api.Warehouses.UPDATE_WAREHOUSE, {
    onCompleted: () => {
      navigate(`/warehouses`);
    },
  });

  const [deleteMutation, { loading: delLoading }] = useMutation(
    Api.Warehouses.DELETE_WAREHOUSE,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const onDelete = () => {
    if (!delLoading) {
      deleteMutation({
        variables: {
          id: warehouse.id,
        },
      });
    }
  };

  const storeIds = warehouse.stores.map((store) => store.id);

  const formik = useFormik<WarehouseUpdate>({
    initialValues: {
      companyId: warehouse.company.id,
      name: warehouse.name,
      stores: storeIds,
      streetName: warehouse.streetName ?? "",
      streetNumber: warehouse.streetNumber ?? "",
      phone: warehouse.phone ?? "",
      notes: warehouse.notes ?? "",
    },
    onSubmit: (data) => {
      if (!loading) {
        const input = {
          ...data,
        };

        logEvent("warehouses.update", {
          id: warehouse.id,
          data: input,
        });

        mutation({
          variables: {
            id: warehouse.id,
            input: data,
          },
        });
      }
    },
    validationSchema: StoreSchema,
  });

  const canUpdate = hasPermission("UPDATE_WAREHOUSE");
  const canDeleteWarehouse =
    hasPermission("DELETE_PRODUCT") && warehouse.deletedAt === null;

  const warehouseStores = warehouse.stores.map((store) => store.id);
  const options = stores
    .filter((store) => !store.warehouseId || warehouseStores.includes(store.id))
    .map((store) => ({
      value: store.id,
      text: store.name,
      label: store.name,
      selected: storeIds.includes(store.id),
    }));

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>Editar Depósito "{warehouse.name}"</CCardHeader>
        <CCardBody>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel>Empresa</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <p className="form-control-static">{company.name}</p>
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="stores">Puntos de Venta</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CMultiSelect
                id="stores"
                options={options}
                placeholder="Puntos de Venta"
                onChange={(e) => {
                  formik.setFieldValue(
                    "stores",
                    e.map(({ value }) => value)
                  );
                }}
              />
            </CCol>
          </CRow>

          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                readOnly={!canUpdate}
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="streetName">Calle</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetName"
                placeholder="Calle"
                name="streetName"
                readOnly={!canUpdate}
                onChange={formik.handleChange}
                defaultValue={formik.values.streetName}
                {...getValidity(
                  formik.values.streetName,
                  formik.errors.streetName
                )}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="streetNumber">Altura</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetNumber"
                placeholder="Altura"
                name="streetNumber"
                readOnly={!canUpdate}
                onChange={formik.handleChange}
                defaultValue={formik.values.streetNumber}
                {...getValidity(
                  formik.values.streetNumber,
                  formik.errors.streetNumber
                )}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="phone">Teléfono</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="phone"
                placeholder="Teléfono"
                name="phone"
                readOnly={!canUpdate}
                onChange={formik.handleChange}
                defaultValue={formik.values.phone}
                {...getValidity(formik.values.phone, formik.errors.phone)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="notes">Notas</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="notes"
                placeholder="Notas"
                name="notes"
                readOnly={!canUpdate}
                onChange={formik.handleChange}
                defaultValue={formik.values.notes}
                {...getValidity(formik.values.notes, formik.errors.notes)}
              />
            </CCol>
          </CRow>
        </CCardBody>
        {canUpdate && (
          <CCardFooter>
            <CRow className="px-0 align-items-center">
              <CCol md="6"></CCol>
              <CCol md="6" className="flex justify-content-end">
                {canDeleteWarehouse && (
                  <>
                    <CButton
                      type="button"
                      size="sm"
                      color="danger"
                      className="mr-2"
                      onClick={() => setShowModal(true)}
                    >
                      Eliminar
                    </CButton>

                    <CModal
                      size="sm"
                      alignment="center"
                      visible={showModal}
                      onClose={() => setShowModal(false)}
                    >
                      <CModalHeader closeButton>
                        ¿Estás seguro de eliminar este deposito?
                      </CModalHeader>
                      <CModalFooter>
                        <CButton
                          size="sm"
                          color="secondary"
                          onClick={() => {
                            setShowModal(false);
                          }}
                        >
                          Cancelar
                        </CButton>
                        <CButton size="sm" color="danger" onClick={onDelete}>
                          Si, quiero eliminarlo
                        </CButton>
                      </CModalFooter>
                    </CModal>
                  </>
                )}

                <CLoadingButton
                  size="sm"
                  loading={loading}
                  color="primary"
                  disabled={loading || !formik.isValid}
                  type="submit"
                >
                  Guardar cambios
                </CLoadingButton>
              </CCol>
            </CRow>
          </CCardFooter>
        )}
      </CCard>
    </CForm>
  );
};

export default WarehouseScreen;
