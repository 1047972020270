import { CAlert, CCard, CCardBody } from "@coreui/react-pro";

const UnavailableStatus = () => (
  <CCard>
    <CCardBody>
      <CAlert color="danger" className="mb-0">
        Para poder usar este servicio, debes tener un CUIT asociado a tu
        empresa.
      </CAlert>
    </CCardBody>
  </CCard>
);

export default UnavailableStatus;
