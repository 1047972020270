import { useQuery } from "@apollo/client";
import { CFormCheck, CSmartTable } from "@coreui/react-pro";
import Api from "src/api";
import { Store } from "src/api/stores";
import { UserUpdateForm } from "src/api/users";
import { AppLoader } from "src/components/Loader/Loader";
import { GraphQLMeta } from "src/types";

const UserDetail = ({ formik }) => {
  const companyIds = Array.from(formik.values.companies.keys());

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          companyIds,
          limit: 0,
        },
      },
    }
  );

  if (!stores?.data.data) {
    return <AppLoader />;
  }

  const changeType = (e, id: number) => {
    const stores: UserUpdateForm["stores"] = formik.values.stores;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, companyId] = e.target.name.split("-");

    if (e.target.value === "NONE") {
      stores.delete(id);
    } else {
      stores.set(id, `${companyId}-${e.target.value}`);
    }

    formik.setFieldValue("stores", stores);
  };

  return (
    <>
      <CSmartTable
        itemsPerPage={stores.data.data.length ?? 20}
        items={stores.data.data}
        columns={[
          {
            key: "name",
            label: "Punto de Venta",
          },
          {
            key: "owner",
            label: "Dueño",
            _props: {
              className: "text-center",
            },
          },
          {
            key: "supervisor",
            label: "Supervisor",
            _props: {
              className: "text-center",
            },
          },
          {
            key: "employee",
            label: "Empleado",
            _props: {
              className: "text-center",
            },
          },
          {
            key: "none",
            label: "Nada",
            _props: {
              className: "text-center",
            },
          },
        ]}
        scopedColumns={{
          name: (item: Store) => (
            <td className="font-weight-bold">
              <span className="font-weight-bold">{item.name}</span>
            </td>
          ),
          owner: (item: Store) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`store-${item.company.id}-${item.id}`}
                value="OWNER"
                defaultChecked={formik.values.stores
                  .get(item.id)
                  ?.includes("OWNER")}
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
          supervisor: (item: Store) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`store-${item.company.id}-${item.id}`}
                value="SUPERVISOR"
                defaultChecked={formik.values.stores
                  .get(item.id)
                  ?.includes("SUPERVISOR")}
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
          employee: (item: Store) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`store-${item.company.id}-${item.id}`}
                value="EMPLOYEE"
                defaultChecked={formik.values.stores
                  .get(item.id)
                  ?.includes("EMPLOYEE")}
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
          none: (item: Store) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`store-${item.company.id}-${item.id}`}
                value="NONE"
                defaultChecked={!formik.values.stores.get(item.id)}
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
        }}
      />
    </>
  );
};

export default UserDetail;
