import {
  CHeader,
  CContainer,
  CSidebarBrand,
  CHeaderToggler,
  CHeaderNav,
} from "@coreui/react-pro";
import { memo } from "react";
import Icon from "src/components/Icon";
import { useAdminStore } from "src/store";

import AppBreadcrumb from "./AppBreadcrumb";
import AppHeaderDropdown from "./AppHeaderDropdown";
import CompanyDropdown from "./CompanyDropdown";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const TheHeader = () => {
  const { toggleSidebar } = useAdminStore();

  return (
    <CHeader position="sticky" className={classNames("w-100 no-print")}>
      <CContainer className="mx-0 w-100">
        <CHeaderToggler
          className="ps-1 d-flex flex-1 web-hidden"
          onClick={() => toggleSidebar()}
        >
          <Icon name="bars-3" color="black" />
        </CHeaderToggler>

        <CSidebarBrand
          as={NavLink}
          to="/home"
          className="d-flex flex-2 web-hidden"
        >
          <img alt="Daruma Logo" src="avatars/default.png" height="38" />
        </CSidebarBrand>

        <div className="flex mobile-hidden">
          <AppBreadcrumb />
        </div>

        <CHeaderNav className="ps-1 d-flex justify-content-end">
          <CompanyDropdown />
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default memo(TheHeader);
