import yup from "src/helpers/validation";
import { gql } from "@apollo/client";
import { WithTrash } from "src/types";

export const userAccess = [
  {
    id: 1,
    value: "ADMIN",
    label: "Administrador",
  },
  {
    id: 2,
    value: "FRONT",
    label: "App Web",
  },
  {
    id: 3,
    value: "MOBILE_FRONT",
    label: "App Móvil",
  },
];

export const adminUserAccess = [
  {
    id: 1,
    value: "ADMIN",
    label: "Administrador",
  },
  {
    id: 2,
    value: "FRONT",
    label: "App Web",
  },
  {
    id: 3,
    value: "MOBILE_FRONT",
    label: "App Móvil",
  },
  {
    id: 4,
    value: "MOBILE_ADMIN",
    label: "Admin Móvil",
  },
  {
    id: 5,
    value: "LANDINGS",
    label: "Landings",
  },
  {
    id: 6,
    value: "CUSTOMER_FIDELITY",
    label: "Fidelización",
  },
  {
    id: 7,
    value: "FACTURADOR",
    label: "Facturador",
  },
];

export const UserSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  lastname: yup.string().required(),
  password: yup.string().required(),
  loginCode: yup.string().numeric().max(4),
  userLoginCode: yup.string().numeric().max(4),
});

export const UpdateUserSchema = yup.object().shape({
  name: yup.string().required(),
  lastname: yup.string(),
  loginCode: yup.string().numeric().max(4),
  userLoginCode: yup.string().numeric().max(4),
});

export type UserPermission = {
  permissionId: number;
  key: string;
  value: string;
  permission: {
    type: "Company" | "Store";
  };
};

export type User = WithTrash<{
  id: number;
  name: string;
  lastname: string;
  isAdmin: boolean;
  image: string;
  email: string;
  permissions: UserPermission[];
  companies: CompanyUser[];
  stores: StoreUser[];
  password?: string;
  loginCode?: number;
  userLoginCode?: number;
  userAccess: string[];
  isP: boolean;
}>;

export enum UserRole {
  Owner = "OWNER",
  Employee = "EMPLOYEE",
  Branch = "BRANCH",
}

export type CompanyUser = {
  companyId: number;
  role: UserRole;
};

export type StoreUser = {
  companyId: number;
  storeId: number;
  userId: number;
  role: UserRole;
};

export type UserCreation = Pick<
  User,
  | "name"
  | "lastname"
  | "email"
  | "password"
  | "loginCode"
  | "userLoginCode"
  | "userAccess"
>;

export type UserUpdate = Omit<UserCreation, "email"> & {
  permissions: number[];
};

export type UserUpdateForm = Pick<
  User,
  | "name"
  | "lastname"
  | "password"
  | "loginCode"
  | "userLoginCode"
  | "userAccess"
> & {
  companies: Map<number, UserRole>;
  stores: Map<number, string>;
  permissions: string[];
};

export const LIST_USERS = gql`
  query getUsers($filters: UserFilters) {
    data: users(filters: $filters) {
      data {
        id
        name
        lastname
        email
        loginCode
        userLoginCode
        isAdmin
        deletedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: Int!) {
    data: user(id: $id) {
      id
      name
      lastname
      email
      isAdmin
      loginCode
      userLoginCode
      userAccess

      companies: companyUsers {
        companyId
        role
      }

      stores: storeUsers {
        companyId
        storeId
        userId
        role
      }

      permissions {
        permissionId
        key
        value

        permission {
          type
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    data: createUser(input: $input) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: Int!, $input: UpdateUserInput!) {
    data: updateUser(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($id: Int!, $password: String!) {
    data: updateUserPassword(id: $id, password: $password) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int!) {
    data: deleteUser(id: $id) {
      id
    }
  }
`;
