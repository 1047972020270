import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CCol,
  CRow,
  CFormCheck,
} from "@coreui/react-pro";
import Api from "src/api";
import { useMutation, useQuery } from "@apollo/client";
import { GraphQLMeta } from "src/types";
import { PriceList } from "src/api/price-lists";
import { useNavigate } from "react-router-dom";
import { findPointOfSale } from "src/helpers/stores";
import { useAdminStore } from "src/store";
import { getStorePaymentStatus } from "src/helpers/payments";
import { PaymentStatus } from "src/api/companies";

const SuccessModal = ({ priceListId }, ref) => {
  const { currentCompany } = useAdminStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [priceListIds, setPriceListIds] = useState<number[]>([]);
  const [createProductIds, setCreateProductIds] = useState<number[]>([]);
  const [updateProductIds, setUpdateProductIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const { data: priceLists } = useQuery<GraphQLMeta<PriceList>>(
    Api.PriceLists.LIST_PRICE_LISTS,
    {
      variables: {
        filters: {
          type: "ACTIVE",
        },
      },
      fetchPolicy: "no-cache",
    }
  );
  const [mutation, { loading }] = useMutation(
    Api.PriceLists.PROPAGATE_PRICE_LIST,
    {
      onCompleted: () => {
        alert("Los cambios han sido propagados");

        navigate("/price-lists");
      },
    }
  );

  useImperativeHandle(
    ref,
    () => ({
      open: (createIds: number[], updateIds: number[]) => {
        setShowModal(true);
        setCreateProductIds(createIds);
        setUpdateProductIds(updateIds);
      },
      close: () => setShowModal(false),
    }),
    []
  );

  const togglePriceList = (id: number) => {
    setPriceListIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((priceListId) => priceListId !== id);
      }

      return [...prev, id];
    });
  };

  const updatePriceLists = () => {
    if (
      (createProductIds.length === 0 && updateProductIds.length === 0) ||
      filteredPriceLists.length === 0
    ) {
      return navigate("/price-lists");
    }

    if (!loading) {
      mutation({
        variables: {
          id: priceListId,
          priceListIds,
          createProductIds,
          updateProductIds,
        },
      });
    }
  };

  const filteredPriceLists = useMemo(() => {
    if (priceLists?.data.data) {
      return priceLists.data.data.filter(
        (priceList) => priceList.id !== priceListId
      );
    }

    return [];
  }, [priceListId, priceLists?.data.data]);

  useEffect(() => {
    if (priceLists?.data.data) {
      setPriceListIds(priceLists?.data.data.map((p) => p.id));
    }
  }, [priceLists?.data.data]);

  return (
    <CModal
      alignment="center"
      size="lg"
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>
        Actualización de Lista de Precios
      </CModalHeader>
      <CModalBody>
        La lista de precios ha sido actualizada.
        {(createProductIds.length > 0 || updateProductIds.length > 0) &&
          filteredPriceLists.length > 0 && (
            <>
              ¿Quieres hacer que estos cambios repercutan en otras listas de
              precios?
              <CRow className="mt-3">
                <CCol md="12" className="mt-2 mb-3">
                  {filteredPriceLists.map((priceList) => {
                    const currentType = findPointOfSale(priceList.store.type);
                    const StoreIcon = () => currentType?.icon;
                    const isStoreDebtor =
                      getStorePaymentStatus(
                        priceList.store.id,
                        currentCompany?.paymentStatus
                      ) === PaymentStatus.DEBTOR;

                    return (
                      <CFormCheck
                        key={priceList.id}
                        inline
                        id={`priceList-${priceList.id}`}
                        value={priceList.id}
                        checked={
                          isStoreDebtor
                            ? false
                            : priceListIds.includes(priceList.id)
                        }
                        disabled={isStoreDebtor}
                        label={
                          <div className="d-flex">
                            <StoreIcon />
                            <span className="ml-1">
                              {priceList.store?.name} - {priceList?.name}
                            </span>
                          </div>
                        }
                        name={`priceList-${priceList.id}`}
                        onChange={() => togglePriceList(priceList.id)}
                      />
                    );
                  })}
                </CCol>
              </CRow>
            </>
          )}
      </CModalBody>
      <CModalFooter>
        <CButton
          type="button"
          color="secondary"
          size="sm"
          disabled={loading}
          onClick={() => {
            setShowModal(false);
            navigate("/price-lists");
          }}
        >
          Cancelar
        </CButton>
        <CButton
          type="button"
          disabled={
            loading ||
            (filteredPriceLists.length > 0 && priceListIds.length === 0)
          }
          size="sm"
          onClick={() => updatePriceLists()}
          color="success"
        >
          Continuar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(SuccessModal);
