import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavItem,
  CNavLink,
  CSidebarHeader,
} from "@coreui/react-pro";

import useSidebar from "src/hooks/sidebar";
import { memo } from "react";
import { AppVersion } from "src/types";
import { useAdminStore } from "src/store";
import { AppSidebarNav } from "./AppSidebarNav";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const TheSidebar = () => {
  const { top } = useSidebar();
  const { visibleSidebar } = useAdminStore();

  return (
    <CSidebar
      className={classNames("h-100 no-print border-end")}
      colorScheme="dark"
      visible={visibleSidebar}
    >
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand as={NavLink} to="/home">
          <div className="sidebar-brand-full">
            <img alt="Daruma Logo" src="avatars/default.png" height="38" />
            <span className="h2 mb-0 ml-2 c-sidebar-brand-full">Daruma</span>
          </div>
        </CSidebarBrand>
      </CSidebarHeader>

      <AppSidebarNav items={top} />

      <CSidebarNav style={{ flex: "none", justifyContent: "flex-end" }}>
        <CNavItem>
          <CNavLink>v{AppVersion}</CNavLink>
        </CNavItem>
      </CSidebarNav>
    </CSidebar>
  );
};

export default memo(TheSidebar);
