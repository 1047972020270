const svgComponent = ({
  containerClassName = "",
  color = "#FFFFFF",
  size = 32,
}) => (
  <svg
    fill="none"
    viewBox="0 0 56.69 56.69"
    height={size}
    width={size}
    className={containerClassName ?? ""}
  >
    <g>
      <path
        fill={color}
        d="M 55.652 40.068 L 45.618 17.821 L 39.695 4.227 C 39.142 3.069 37.984 2.144 36.737 2.144 L 25.705 2.144 C 24.201 2.144 23.094 3.024 22.445 4.386 L 0.779 51.906 C 0.407 52.808 0.429 53.689 0.869 54.382 C 1.287 55.052 2.027 55.423 2.882 55.423 L 28.319 55.423 C 29.707 55.423 30.84 54.752 31.347 53.618 L 34.445 46.634 L 51.769 46.613 C 53.108 46.682 54.473 45.963 55.259 44.807 C 56.139 43.513 56.299 41.826 55.652 40.068 M 29.266 52.672 C 29.082 53.065 28.528 53.11 28.296 53.11 L 2.86 53.11 C 2.811 53.11 2.766 53.11 2.743 53.11 C 2.743 53.065 2.766 52.969 2.811 52.855 L 24.434 5.382 C 24.711 4.825 25.06 4.618 25.683 4.618 L 36.714 4.618 C 37.036 4.618 37.452 4.803 37.662 5.196 L 43.582 18.792 L 43.696 19.071 C 43.79 19.279 43.835 19.463 43.835 19.625 C 43.835 19.811 43.79 19.995 43.673 20.251 L 43.65 20.32 C 42.98 21.845 29.405 52.347 29.266 52.672 M 53.409 43.467 C 53.061 43.975 52.438 44.324 51.837 44.324 L 51.769 44.324 L 35.418 44.324 L 45.177 22.378 C 47.929 28.436 53.478 40.762 53.548 40.878 C 53.71 41.339 53.802 41.756 53.802 42.172 C 53.802 42.682 53.686 43.095 53.409 43.467"
      />
      {/**
      <path
        fill="#ffffff"
        d="M73.1,35.95h-9c-0.87,0-1.46-0.93-1.46-1.79V18.58c0-0.5,0.16-0.95,0.44-1.26c0.27-0.29,0.65-0.49,1.09-0.5 h9.05c0.38,0,0.72,0.18,0.98,0.46c0.23,0.23,0.49,0.66,0.49,1.37L74.68,34.2C74.68,35.29,73.86,35.95,73.1,35.95 M73.22,17.72v0.14 l-9.06,0.01c-0.17,0-0.3,0.05-0.39,0.14c-0.12,0.13-0.18,0.35-0.17,0.63v15.52c0,0.44,0.27,0.79,0.49,0.79h9 c0.12,0,0.61-0.13,0.61-0.76l0-15.55c0-0.29-0.07-0.53-0.2-0.66c-0.08-0.08-0.17-0.12-0.29-0.12V17.72z"
      />
      <path
        fill="#ffffff"
        d="M98.95,17.67c-0.29-0.49-0.81-0.81-1.43-0.84H87c-0.01,0-0.03,0-0.04,0h-8.51c-1.3,0-1.89,0.97-1.89,1.92 v15.42c0,0.52,0.18,1.02,0.5,1.34c0.28,0.29,0.66,0.45,1.08,0.45h8.75c0.72,0,1.71-0.61,1.71-1.75l0-3.57h0.43l3.14,0 c0.58,0,2.02-0.16,2.67-1.6L99,19.64C99.3,18.95,99.28,18.21,98.95,17.67 M86.89,34.95h-8.74c-0.17,0-0.3-0.05-0.4-0.15 c-0.13-0.14-0.21-0.38-0.21-0.64V18.74c0-0.27,0.09-0.89,0.92-0.89c0.02,0,8.3,0.01,8.47,0.01h0c0.04,0,0.08,0,0.12-0.01h0.6 l0,16.35C87.64,34.73,87.11,34.95,86.89,34.95 M98.11,19.23l-4.13,9.37c-0.4,0.89-1.29,1.02-1.79,1.02l-3.57,0.01l0.01-11.78h8.89 c0.2,0.03,0.46,0.09,0.62,0.34c0.07,0.12,0.12,0.28,0.12,0.45C98.25,18.83,98.2,19.03,98.11,19.23"
      />
      <path
        fill="#ffffff"
        d="M60.57,17.44c-0.24-0.37-0.63-0.61-1.08-0.61h-8.81c-0.02,0-0.03,0-0.04,0h-8.73c-1.12,0-1.81,0.68-1.81,1.77 v15.74c0,0.92,0.64,1.65,1.46,1.65h9.01c0.76,0,1.58-0.67,1.58-1.75l0-3.64h1.35h0.31c0.52-0.01,0.97,0.02,1.3-0.12 c0.2-0.09,0.36-0.26,0.5-0.54l1.68-3.82c0.06-0.17,0.25-0.52,0.46-0.99c0.13-0.3,0.29-0.64,0.45-1c0.17-0.38,0.33-0.76,0.52-1.16 c0.12-0.26,0.25-0.57,0.37-0.81l1.56-3.49C60.83,18.26,60.8,17.8,60.57,17.44 M51.18,34.23c0,0.62-0.49,0.75-0.61,0.75h-9.01 c-0.28,0-0.48-0.27-0.48-0.64V18.6c0-0.55,0.23-0.77,0.83-0.77c0.02,0,8.64,0.03,8.69,0.03c0.02,0,0.05,0,0.08-0.01v0h0.5 L51.18,34.23z M56.75,24.78c-0.02,0.06-1.11,2.79-1.79,4.18c-0.32,0.66-0.32,0.66-1.01,0.66h-1.79l0-5.42h4.29 c0.11,0,0.22,0.06,0.28,0.16c0.04,0.06,0.06,0.13,0.06,0.21C56.79,24.64,56.77,24.71,56.75,24.78 M59.66,18.3l-1.73,3.82 c-0.47,1.12-0.56,1.1-1.66,1.1h-4.12l0-5.38h7.23c0.12,0,0.21,0.05,0.26,0.14c0.03,0.05,0.04,0.11,0.04,0.18 C59.69,18.22,59.68,18.26,59.66,18.3"
      />
      */}
    </g>
  </svg>
);

export default svgComponent;
