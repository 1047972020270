import { TiendaNubeOrderStatus } from "src/api/tienda-nube";

export const parseOrderStatus = (status: TiendaNubeOrderStatus) => {
  switch (status) {
    case TiendaNubeOrderStatus.Cancelled:
      return {
        label: "Cancelada",
        color: "danger",
      };

    default:
    case TiendaNubeOrderStatus.Open:
      return {
        label: "En curso",
        color: "info",
      };

    case TiendaNubeOrderStatus.Closed:
      return {
        label: "Archivada",
        color: "success",
      };
  }
};
