import { memo } from "react";
import { useAdminStore } from "src/store";
import SalesDashboard from "./SalesDashboard";

const OwnerDashboard = ({ today = false }: { today?: boolean }) => {
  const { hasPermission } = useAdminStore();
  const canSeeSales = hasPermission("LIST_SALES", { force: true });

  return <>{canSeeSales && <SalesDashboard today={today} />}</>;
};

export default memo(OwnerDashboard);
