const svgComponent = ({
  containerClassName,
  color = "#2c3356",
  size = 32,
}: {
  containerClassName?: string;
  color?: string;
  size: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 385 272"
    className={containerClassName ?? ""}
  >
    <g id="g42">
      <path
        d="m243.42,7.53c-31.97,-0.51 -61.83,11.24 -89.04,34.02c-2.43,2.03 -4.21,2.51 -7.23,1.38c-16.4,-6.12 -33.4,-7.87 -50.78,-5.44c-55.47,7.77 -94.13,56.88 -87.55,111.03c7.76,63.78 75.22,103.96 137.44,81.75c3.51,-1.25 5.64,-0.94 8.51,1.57c25.05,21.88 54.61,32.71 88.28,33.19c6.23,0.27 12.4,-0.38 18.5,-1.21c72.91,-9.92 123.86,-75.43 112.51,-146.12c-7.98,-49.68 -37.14,-84.61 -85.78,-101.75c-15.36,-5.41 -30.33,-8.17 -44.86,-8.41zm1.2,29.05c47.33,0.81 92.13,33.47 100.33,84.52c9.16,57.05 -33.26,109.72 -92.45,114.81c-58.42,5.02 -109.32,-37.97 -111.86,-94.64c-0.18,-3.84 -1.14,-5.11 -5.21,-4.9c-6.81,0.34 -13.67,0.27 -20.49,0.01c-3.49,-0.13 -4.32,0.91 -4.17,4.23c1.05,21.77 6.95,42.13 18.34,60.96c0.63,1.06 1.2,2.15 1.83,3.27c-37.21,11.94 -79.93,-11.25 -90.71,-49.02c-11.25,-39.41 13.37,-79.63 54.45,-88.95c40.5,-9.19 80.91,16.71 88.72,56.88c0.54,2.78 1.11,5.64 0.99,8.43c-0.17,3.49 0.89,4.58 4.61,4.4c6.81,-0.33 13.68,-0.39 20.48,0.01c4.2,0.25 4.72,-1.39 4.51,-4.79c-1.69,-26.97 -12.33,-49.95 -32.83,-68.35c-3.7,-3.32 -2.86,-4.52 0.56,-6.99c19.36,-14.05 41.4,-20.26 62.91,-19.88z"
        fill={color}
        id="path4"
      />
    </g>
  </svg>
);

export default svgComponent;
