import {
  CButton,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Store } from "src/api/stores";

export type DialogProps = {
  closeOnSubmit?: boolean;
  visible?: boolean;
  onSubmit?: (props?: any) => void;
  onDismiss?: () => void;
};

export type CustomDialogProps = DialogProps & {
  open: (props?: any) => void;
  close: (props?: any) => void;
};

const ConnectModal: ForwardRefRenderFunction<CustomDialogProps, DialogProps> = (
  { closeOnSubmit = false, onDismiss, onSubmit, visible = false },
  ref
) => {
  const [isVisible, setIsVisible] = useState<boolean>(visible);
  const [MPData, setMPData] = useState<any>();

  const formik = useFormik({
    initialValues: {
      name: "",
      storeId: undefined,
      mercadoPagoPOSId: undefined,
    },
    onSubmit: (data) => {
      if (data.mercadoPagoPOSId && data.storeId) {
        onSubmit?.({
          name: data.name,
          mercadoPagoPOSId: Number(data.mercadoPagoPOSId),
          storeId: Number(data.storeId),
        });

        if (closeOnSubmit) {
          handleDismiss();
        }
      }
    },
  });

  const handleDismiss = useCallback(() => {
    setIsVisible(false);
    formik.resetForm();

    onDismiss?.();
  }, [formik, onDismiss]);

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();

    if (closeOnSubmit) {
      onDismiss?.();
    }
  }, [closeOnSubmit, formik, onDismiss]);

  useImperativeHandle(
    ref,
    () => ({
      open: ({ store, mpData }: { store: Store; mpData: any }) => {
        formik.setFieldValue("storeId", store.id);
        formik.setFieldValue("name", store.name);
        setMPData(mpData);

        setIsVisible(true);
      },

      close: () => handleDismiss(),
    }),
    [formik, handleDismiss]
  );

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <CModal alignment="center" visible={isVisible} onClose={handleDismiss}>
      <CModalHeader closeButton>Conectar con MercadoPago</CModalHeader>
      <CModalBody>
        <p>
          Elige la <strong>Sucursal de Mercado Pago</strong> con la que quieres
          conectar este punto de venta
        </p>
        <CFormSelect name="mercadoPagoPOSId" onChange={formik.handleChange}>
          <option value="0">Seleccione Sucursal</option>
          {MPData?.stores?.map(({ id, name }: any) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
          <option value="-1">Crear Nueva</option>
        </CFormSelect>
        {formik.values.mercadoPagoPOSId === "-1" && (
          <CFormInput
            className="mt-3"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Nombre de la Caja"
            floatingLabel="Nombre"
          />
        )}
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={handleDismiss}>
          Cancelar
        </CButton>
        <CButton size="sm" color="success" onClick={handleSubmit}>
          {formik.values.mercadoPagoPOSId === "-1" ? "Crear y " : ""}Conectar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(ConnectModal);
