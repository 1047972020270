import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
  CForm,
  CRow,
  CCol,
  CFormInput,
  CFormSwitch,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { GraphQLFind } from "src/types";
import Api from "src/api";
import { Plan } from "src/api/plans";
import { AppLoader } from "src/components/Loader/Loader";
import { useFormik } from "formik";

const PlanDetail = () => {
  const params = useParams();
  const planId = Number(params.id);
  const navigate = useNavigate();

  if (!planId) {
    navigate("/plans");
  }

  const { data: plan } = useQuery<GraphQLFind<Plan>>(Api.Plans.GET_PLAN, {
    variables: {
      id: planId,
    },
    fetchPolicy: "no-cache",
  });

  if (!plan?.data) {
    return <AppLoader />;
  }

  return <Form plan={plan.data} />;
};

const Form = ({ plan }) => {
  const [updateMutation, { loading: updating }] = useMutation(
    Api.Plans.UPDATE_PLAN,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      price: plan.price,
      discount: 0,

      users: plan.users,
      invoicing: plan.invoicing,
      fixedPriceList: plan.fixedPriceList,
      customPermissions: plan.customPermissions,
      support: plan.support,
    },
    onSubmit: (data) => {
      if (!updating) {
        const input = {
          price: data.price,
          discount: 0,
          users: data.users,
          invoicing: data.invoicing,
          fixedPriceList: data.fixedPriceList,
          customPermissions: data.customPermissions,
          support: data.support,
        };

        updateMutation({
          variables: {
            id: plan.id,
            input,
          },
        });
      }
    },
  });

  return (
    <CCard>
      <CForm onSubmit={formik.handleSubmit}>
        <CCardHeader>
          Plan <strong>{plan.name}</strong>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm={3}>
              <CFormInput
                floatingLabel="Precio"
                type="number"
                min={0}
                defaultValue={formik.values.price}
                onChange={formik.handleChange}
                name="price"
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol sm={2}>
              <CFormInput
                floatingLabel="Usuarios"
                type="number"
                min={1}
                defaultValue={formik.values.users}
                onChange={formik.handleChange}
                name="users"
              />
            </CCol>
            <CCol sm={2}>
              <CFormInput
                name="invocing"
                floatingLabel="Tope de Facturacion"
                defaultValue={formik.values.invoicing}
                onChange={formik.handleChange}
              />
            </CCol>
            <CCol sm={2}>
              <CFormLabel>Lista Fija</CFormLabel>
              <CFormSwitch
                name="fixedPriceList"
                defaultChecked={formik.values.fixedPriceList}
                onChange={formik.handleChange}
              />
            </CCol>
            <CCol sm={3}>
              <CFormLabel>Permisos personalizados</CFormLabel>
              <CFormSwitch
                name="customPermissions"
                defaultChecked={formik.values.customPermissions}
                onChange={formik.handleChange}
              />
            </CCol>
            <CCol sm={3}>
              <CFormSelect
                floatingLabel="Soporte"
                defaultValue={formik.values.support}
                onChange={formik.handleChange}
                name="support"
              >
                <option value="BASIC">Basico</option>
                <option value="MEDIUM">Intermedio</option>
                <option value="CUSTOM">Personalizado</option>
              </CFormSelect>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0">
            <CCol md="12" className="flex justify-content-end">
              <CLoadingButton
                loading={updating}
                size="sm"
                disabled={updating}
                type="submit"
                color="primary"
              >
                Guardar
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CForm>
    </CCard>
  );
};

export default PlanDetail;
