import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
} from "@coreui/react-pro";
import { useQuery } from "@apollo/client";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Supplier } from "src/api/suppliers";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm } from "src/types";
import Api from "src/api";
import { useAdminStore } from "src/store";
import { logEvent } from "src/helpers/analytics";
import Icon from "src/components/Icon";
import { useFormik } from "formik";
import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import Pagination from "src/components/Pagination";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import ProductsConfigModal, {
  ProductsConfigModalForwardedRef,
} from "../products/components/ProductsConfigModal";
import { useRef } from "react";

const SuppliersScreen = () => {
  const configModalRef = useRef<ProductsConfigModalForwardedRef>(null);
  const navigate = useNavigate();
  const querySearch = queryStringToObject(useLocation().search);
  const { user, hasPermission } = useAdminStore();

  const {
    data: suppliers,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Supplier>>(Api.Suppliers.LIST_SUPPLIERS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        search: querySearch.search ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination(
    "suppliers",
    refetch
  );

  const formik = useFormik<SearchForm>({
    initialValues: {
      search: querySearch.search,
    },

    onSubmit: (input) => {
      if (!loading) {
        resetAndSearch({
          search: input.search,
          page: 1,
        });
      }
    },
  });

  const fields: any[] = [
    { key: "id", label: "ID" },
    { key: "name", label: "Nombre", className: "font-weight-bold" },
    { key: "count", label: "# de Productos" },
    { key: "notes", label: "Notas" },
  ];

  if (user?.isAdmin) {
    fields.push({ key: "company", label: "Empresa" });
  }

  if (hasPermission("UPDATE_SUPPLIER")) {
    fields.push({
      key: "actions",
      label: "Acciones",
      _props: { className: "text-right" },
    });
  }

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={8} className="px-0">
                  Proveedores
                </CCol>
                <CCol sm={4} className="row justify-content-end">
                  {hasPermission("CREATE_SUPPLIER") && (
                    <Link
                      to="/suppliers/new"
                      className="flex justify-content-end p-0"
                    >
                      <CButton
                        size="sm"
                        color="primary"
                        onClick={() => {
                          logEvent("suppliers.create");
                        }}
                      >
                        Crear proveedor
                      </CButton>
                    </Link>
                  )}
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar..."
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 1 }}
                    />
                    <CButton
                      color="primary"
                      type="submit"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <Icon
                        name="magnifying-glass"
                        style={{ margin: "0 auto" }}
                      />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={suppliers?.data.data || []}
                columns={fields}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  company: (item: Supplier) => <td>{item.company.name}</td>,
                  count: (item: Supplier) => {
                    const productsCount = item.products.length;

                    return (
                      <td>
                        {productsCount} producto
                        {productsCount === 0 || productsCount > 1 ? "s" : ""}
                      </td>
                    );
                  },
                  notes: (item: Supplier) => <td>{item.notes}</td>,
                  actions: (item: Supplier) => (
                    <td className="text-right">
                      <CButton
                        size="sm"
                        className="mr-2"
                        color="primary"
                        onClick={(e) => {
                          configModalRef.current?.open(
                            item.id,
                            item.name,
                            "supplier"
                          );

                          e.stopPropagation();
                        }}
                      >
                        <Icon name="cog-8-tooth" />
                      </CButton>

                      <CButton
                        size="sm"
                        color="info"
                        onClick={(e) => {
                          navigate(`/suppliers/${item.id}`);

                          e.stopPropagation();
                        }}
                      >
                        <Icon name="pencil" />
                      </CButton>
                    </td>
                  ),
                }}
                clickableRows
                onRowClick={(item: Item) => {
                  logEvent("suppliers.navigate", {
                    date: item,
                  });

                  navigate(`/suppliers/${item.id}/products`);
                }}
              />

              <Pagination
                meta={suppliers}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <ProductsConfigModal ref={configModalRef} />
    </>
  );
};

export default SuppliersScreen;
