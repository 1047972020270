import { CCard, CCardBody, CCardHeader, CSmartTable } from "@coreui/react-pro";
import { camelCase } from "lodash";
import { Link } from "react-router-dom";
import { PriceListItem } from "src/api/price-lists";
import { Product } from "src/api/products";
import { findPointOfSale } from "src/helpers/stores";

const ProductConnections = ({
  connections,
}: {
  connections: Product["connections"];
}) => {
  return (
    <CCard className="mt-3">
      <CCardHeader>Conexiones</CCardHeader>
      <CCardBody>
        <CSmartTable
          items={connections}
          itemsPerPage={connections.length}
          columns={[
            { key: "store", label: "Tienda" },
            { key: "priceList", label: "Lista de Precios" },
            { key: "link", label: "Link" },
          ]}
          scopedColumns={{
            store: (item: PriceListItem) => {
              const currentType = findPointOfSale(item.priceList.store.type);

              const StoreIcon = () => currentType?.icon;

              return (
                <td>
                  <StoreIcon /> {item.priceList.store.name}
                </td>
              );
            },
            priceList: (item: PriceListItem) => <td>{item.priceList.name}</td>,
            link: (item: PriceListItem) => {
              const currentType = findPointOfSale(item.priceList.store.type);
              const key = `${currentType?.id}_URL`;
              const url = item.metadata?.[camelCase(key)];

              return (
                <td>
                  <Link to={url} target="_blank">
                    Ver Producto en {currentType?.label}
                  </Link>
                </td>
              );
            },
          }}
          tableProps={{
            striped: true,
            hover: true,
          }}
        />
      </CCardBody>
    </CCard>
  );
};

export default ProductConnections;
