import { Order, OrderItem, OrderStatus } from "src/api/orders";
import { getTaxValue } from "src/helpers/numbers";

export const useOrder = () => {
  const calculateItem = (item: OrderItem, status: OrderStatus) => {
    const quantity =
      status === OrderStatus.Pending
        ? item.requestedQuantity
        : item.confirmedQuantity;

    const price =
      status === OrderStatus.Pending
        ? item.requestedPrice
        : item.confirmedPrice;

    const tax =
      status === OrderStatus.Pending ? item.requestedTax : item.confirmedTax;

    const itemTax = getTaxValue(price, tax);

    const response = {
      price: price - itemTax,
      tax: itemTax,
      total: quantity * price,
    };

    return response;
  };

  const calculateSummary = (order: Order) => {
    const items = order.items.map((item) => ({
      ...item,
      summary: calculateItem(item, order.status),
    }));

    const response = items.reduce(
      (prev, curr) => {
        const quantity =
          order.status === OrderStatus.Pending
            ? curr.requestedQuantity
            : curr.confirmedQuantity;

        return {
          subtotal: prev.subtotal + curr.summary.price * quantity,
          tax: prev.tax + curr.summary.tax * quantity,
          total: prev.total + curr.summary.total,
        };
      },
      {
        subtotal: 0,
        tax: 0,
        total: 0,
      }
    );

    return {
      ...response,
      total: response.subtotal + response.tax,
    };
  };

  return {
    calculateItem,
    calculateSummary,
  };
};
