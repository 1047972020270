import { User, UserWithToken } from "src/types";
import { Get, Set, UserState } from "./store.types";
import { Company, PaymentStatus } from "src/api/companies";
import { StoreType } from "src/api/stores";
import { getCompanyPaymentStatus } from "src/helpers/payments";

export const usersInitialState = {
  user: null,
  users: [],

  currentCompany: null,
  tiendaNubePOS: null,
};

export const userStore = (
  set: Set<UserState>,
  get: Get<UserState>
): UserState => ({
  ...usersInitialState,

  setUser: (user: UserWithToken | null) => set((state) => ({ ...state, user })),
  setUsers: (users: User[]) => set((state) => ({ ...state, users })),
  setCurrentCompany: (company: Company) =>
    set((state) => ({
      ...state,
      currentCompany: company,
      tiendaNubePOS: company?.stores.find(
        (store) => store.type === StoreType.TiendaNube
      ),
    })),

  hasPermission: (
    key: string,
    value?: { type?: "company" | "store"; id?: number; force?: boolean }
  ) => {
    const user = get().user;
    const currentCompany = get().currentCompany;

    if (user?.isAdmin) {
      return true;
    }

    if (
      value?.force !== true &&
      getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
        PaymentStatus.DEBTOR
    ) {
      return false;
    }

    const permissions = user?.permissions.filter(
      (permission) => permission.key === key
    );

    if (!permissions || permissions.length === 0) {
      return false;
    }

    if (value?.type) {
      const permissionValues = permissions
        .flatMap((permission) => JSON.parse(permission.value))
        .filter(
          (permission) =>
            (value.type === "store" && permission.storeId) ||
            (value.type === "company" && permission.companyId)
        )
        .map((permission) => ({
          ...permission,
          id: permission.storeId ?? permission.companyId ?? null,
        }));

      return permissionValues.some((permission) => permission.id === value.id);
    }

    return !!permissions;
  },
});
