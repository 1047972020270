import { useLocation, useNavigate } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CForm,
  CFormInput,
  CButton,
  CFormSelect,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { PaymentMethod, Sale } from "src/api/sales";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency } from "src/helpers/numbers";
import { parsePaymentMethods, paymentMethods } from "src/helpers/payments";
import Icon from "src/components/Icon";
import { useFormik } from "formik";
import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta, SearchForm, WithDate } from "src/types";
import Api from "src/api";
import { useAdminStore } from "src/store";
import { queryStringToObject } from "src/helpers/strings";
import PlanAlert from "src/containers/PlanAlert";
import { useCanNavigate } from "src/helpers/permissions";
import Pagination from "src/components/Pagination";
import { Store, StoreType } from "src/api/stores";
import { sortBy } from "lodash";

const Sales = () => {
  const { hasPermission, currentCompany } = useAdminStore();
  const querySearch = queryStringToObject(useLocation().search);
  const canSeeSale = hasPermission("SHOW_SALE");
  const navigate = useNavigate();

  useCanNavigate("LIST_SALES");

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
          permission: "LIST_SALES",
          type: StoreType.Store,
          limit: 0,
        },
      },
    }
  );

  const {
    data: sales,
    refetch,
    loading,
  } = useQuery<GraphQLMeta<Sale>>(Api.Sales.LIST_SALES, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
        trashed: querySearch.trashed ? Number(querySearch.trashed) : -1,
        search: querySearch.search ?? "",
        storeId: querySearch.storeId ? Number(querySearch.storeId) : null,
        paymentMethod: querySearch.paymentMethod
          ? querySearch.paymentMethod
          : undefined,
        date: querySearch.date ?? "",
      },
    },
  });
  const { page, pageChange, resetAndSearch } = usePagination("sales", refetch);

  const formik = useFormik<
    WithDate<
      SearchForm & { storeId?: number | null; paymentMethod?: string | null }
    >
  >({
    initialValues: {
      date: querySearch.date,
      search: querySearch.search ?? "",
      trashed: Number(-1),
      storeId: querySearch.storeId ? Number(querySearch.storeId) : null,
      paymentMethod: querySearch.paymentMethod ?? "",
    },

    onSubmit: (input) => {
      if (!loading) {
        const query: Record<string, unknown> = {
          date: input.date ?? "",
          search: input.search ?? "",
          trashed: Number(input.trashed),
          page: 1,
        };

        if (input.storeId && Number(input.storeId) > 0) {
          query.storeId = Number(input.storeId);
        }

        if (input.paymentMethod) {
          query.paymentMethod = input.paymentMethod;
        }

        resetAndSearch(query);
      }
    },
  });

  return (
    <>
      <PlanAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm="6" xs="6" className="px-0">
                  Ventas
                </CCol>
                <CCol sm="6" xs="6" className="row justify-content-end"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={formik.handleSubmit} className="mb-3">
                <CRow className="align-items-center justify-content-center">
                  <CCol
                    sm={12}
                    className="row justify-content-end"
                    style={{ flexWrap: "nowrap" }}
                  >
                    <CFormInput
                      placeholder="Buscar por ID"
                      name="search"
                      defaultValue={formik.values.search}
                      onChange={formik.handleChange}
                      style={{ flex: 0.5 }}
                    />

                    <CFormSelect
                      name="storeId"
                      onChange={formik.handleChange}
                      style={{ flex: 0.4, marginLeft: 12 }}
                    >
                      <option value={-1}>Todos los Puntos de Venta</option>
                      {sortBy(stores?.data?.data, "name").map((store) => (
                        <option key={store.id} value={store.id}>
                          {store.name}
                        </option>
                      ))}
                    </CFormSelect>

                    <CFormSelect
                      name="paymentMethod"
                      onChange={formik.handleChange}
                      style={{ flex: 0.3, marginLeft: 12 }}
                    >
                      <option value="">Métodos de Pago</option>
                      {sortBy(
                        paymentMethods?.filter(
                          ({ id, digital }) => !digital && id > 0
                        ),
                        "name"
                      ).map((method) => (
                        <option key={method.id} value={method.type}>
                          {method.name}
                        </option>
                      ))}
                    </CFormSelect>

                    <CFormSelect
                      name="trashed"
                      onChange={formik.handleChange}
                      style={{ flex: 0.2, marginLeft: 12 }}
                    >
                      <option value={-1}>Todas</option>
                      <option value={1}>Eliminadas</option>
                      <option value={0}>Finalizadas</option>
                    </CFormSelect>
                    <CFormInput
                      name="date"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.date}
                      type="date"
                      style={{ flex: 0.2, marginLeft: 12 }}
                    />
                    <CButton
                      color="primary"
                      type="submit"
                      className="ml-2 p-0 w-10 h-10"
                    >
                      <Icon
                        name="magnifying-glass"
                        style={{ margin: "0 auto" }}
                      />
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>

              <CSmartTable
                itemsPerPage={20}
                items={
                  sales?.data.data.map((sale) => ({
                    ...sale,
                    _props: { color: sale.deletedAt ? "danger" : "default" },
                  })) || []
                }
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "store",
                    label: "Punto de Venta",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "user", label: "Vendedor" },
                  { key: "payments", label: "Métodos de Pago" },
                  {
                    key: "amount",
                    label: "Monto",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "date",
                    label: "Fecha",
                    _props: { className: "font-weight-bold text-right" },
                  },
                  {
                    key: "file",
                    label: "",
                    _props: { className: "text-right" },
                  },
                ]}
                loading={loading}
                scopedColumns={{
                  store: (sale: Sale) => <td>{sale.store.name}</td>,
                  user: (sale: Sale) => (
                    <td>
                      {sale.user?.name} {sale.user?.lastname}
                    </td>
                  ),
                  payments: (sale: Sale) => (
                    <td>{parsePaymentMethods(sale.payments)}</td>
                  ),
                  amount: (sale: Sale) => (
                    <td className="text-right">
                      {formatCurrency(
                        sale.payments
                          .filter(
                            (payment) =>
                              ![
                                PaymentMethod.Discount,
                                PaymentMethod.CustomerDiscount,
                              ].includes(payment.type)
                          )
                          .reduce((prev, curr) => prev + curr.amount, 0)
                      )}
                    </td>
                  ),
                  date: (sale: Sale) => (
                    <td className="text-right">
                      {dateFormat(sale.date, "dd/MM/yyyy HH:mm")}
                    </td>
                  ),
                  file: (sale: Sale) => (
                    <td className="text-right">
                      {sale.invoices?.length > 0 && (
                        <Icon name="document" color="black" className="ml-3" />
                      )}
                    </td>
                  ),
                }}
                tableProps={{
                  striped: true,
                  hover: canSeeSale,
                }}
                clickableRows={canSeeSale}
                onRowClick={(sale) => {
                  if (canSeeSale) {
                    navigate(`/sales/${sale.id}`);
                  }
                }}
              />

              <Pagination meta={sales} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Sales;
