import { ElectronicInvoice } from "src/api/afip";
import { Sale, SaleInvoice } from "src/api/sales";

export const getCustomer = (
  customer?: Sale["customer"],
  lightMode: boolean = false
) => {
  if (!customer) {
    return "Consumidor final";
  }

  const data: string[] = [];

  if (customer.id) {
    data.push(`#${customer.id} - `);
  }

  if (customer.name) {
    data.push(customer.name);
  }

  if (customer.lastname) {
    data.push(customer.lastname);
  }

  if (customer.utid && !lightMode) {
    const cuit =
      customer.utid.length > 8
        ? [
            customer.utid.slice(0, 2),
            customer.utid.slice(2, -1),
            customer.utid.slice(-1),
          ].join("-")
        : customer.utid;

    data.push(`| ${cuit}`);
  }

  if (customer.alias && !lightMode) {
    data.push(`| Alias: ${customer.alias}`);
  }

  return data.map((value) => value?.trim()).join(" ");
};

export const hasInvoiceAndCreditNotePair = (
  invoices: (SaleInvoice | ElectronicInvoice)[]
): boolean => {
  const invoiceMap = new Map<number, boolean>();

  // Recorrer el array para marcar facturas y notas de crédito
  invoices.forEach((invoice) => {
    if (!invoice.relatedTo) {
      // Es una factura
      invoiceMap.set(invoice.id, false);
    } else if (invoice.relatedTo) {
      // Es una nota de crédito, marcar la factura relacionada como cubierta
      if (invoiceMap.has(invoice.relatedTo)) {
        invoiceMap.set(invoice.relatedTo, true);
      }
    }
  });

  // Verificar si todas las facturas tienen su nota de crédito
  return Array.from(invoiceMap.values()).every(
    (hasCreditNote) => hasCreditNote
  );
};
