import { gql } from "@apollo/client";
import { WithDirty } from "src/types";
import { Store } from "./stores";

import yup from "src/helpers/validation";
import request from "./request";
import { Product } from "./products";
import { TiendaNubeProduct } from "./tienda-nube";
import { MercadoLibreProduct } from "./mercado-libre";

export type PriceList = {
  id: number;
  name: string;
  profit: number;
  dateFrom: Date;
  dateTo: Date | null;
  lastSyncAt: Date | null;
  storeId: number;
  editable: boolean;
  items: PriceListItem[];
  store: Store;
  updatedAt: Date;
};

export type PriceListItem = {
  id: number;
  productId: number;
  priceListId: number;
  name: string;
  description: string | null;
  sku: string;
  price: number;
  tax: number;
  metadata?: {
    tiendaNubeUrl: string;
    mercadoLibreUrl: string;
    mercadoShopsUrl: string;
  };
  product: Product;
  priceList: PriceList;
  tiendaNubeProduct: TiendaNubeProduct;
  mercadoLibreProduct: MercadoLibreProduct;
};

export const PriceListSchema = yup.object().shape({
  name: yup.string().required(),
  storeId: yup.string().integer().required(),
  dateFrom: yup.date().required(),
  dateTo: yup.date().nullable().afterThan(yup.ref("dateFrom")),
});

export type PriceListCreation = Omit<
  PriceList,
  "id" | "store" | "items" | "editable" | "lastSyncAt" | "updatedAt"
>;

export type PriceListItemCreation = Pick<
  PriceListItem,
  "name" | "price" | "description" | "priceListId" | "tax" | "productId"
>;

export type PriceListUpdate = Pick<
  Omit<PriceList, "items">,
  "name" | "dateTo" | "dateFrom" | "profit"
> & {
  items: WithDirty<PriceListItem>[];
};

export const LIST_PRICE_LISTS = gql`
  query getPriceLists($filters: PriceListFilters) {
    data: priceLists(filters: $filters) {
      data {
        id
        name
        dateFrom
        dateTo
        lastSyncAt
        editable
        autoPropagate
        store {
          id
          name
          type
        }
        updatedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_PRICE_LIST = gql`
  query getPriceList($id: Int!, $order: String) {
    data: priceList(id: $id, order: $order) {
      id
      name
      profit
      dateFrom
      dateTo
      editable
      store {
        id
        name
        type

        company {
          id
        }
      }
      items {
        id
        productId
        name
        price
      }
    }
  }
`;

export const CREATE_PRICE_LIST = gql`
  mutation createPriceList($input: CreatePriceListInput!) {
    data: createPriceList(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRICE_LIST = gql`
  mutation updatePriceList($id: Int!, $input: UpdatePriceListInput!) {
    data: updatePriceList(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_PRICE_LIST = gql`
  mutation deletePriceList($id: Int!) {
    data: deletePriceList(id: $id) {
      id
    }
  }
`;

export const LIST_PRICE_LIST_ITEMS = gql`
  query getPriceListItems($filters: PriceListItemFilters) {
    data: priceListItems(filters: $filters) {
      data {
        id
        name
        price
        sku
        productId
        metadata {
          tiendaNubeConnected
          tiendaNubeUrl

          mercadoLibreConnected
          mercadoLibreUrl

          mercadoShopsConnected
          mercadoShopsUrl

          rappiConnected
        }
      }
      meta {
        perPage
        total
        lastPage
      }
    }
  }
`;

export const LIST_DIFFERENT_ITEMS = gql`
  query differentItems($filters: PriceListItemFilters) {
    data: differentItems(filters: $filters) {
      data {
        id
        name
        price
        sku
        product {
          cost
          price
          name
        }
        tiendaNubeProduct {
          variants {
            price
          }
        }
        mercadoLibreProduct {
          price
        }
      }
      meta {
        perPage
        total
        lastPage
      }
    }
  }
`;

export const CREATE_PRICE_LIST_ITEM = gql`
  mutation createPriceListItem($input: CreatePriceListItemInput!) {
    data: createPriceListItem(input: $input) {
      id
    }
  }
`;

export const UPDATE_PRICE_LIST_ITEM = gql`
  mutation updatePriceListItem($id: Int!, $input: UpdatePriceListItemInput!) {
    data: updatePriceListItem(id: $id, input: $input) {
      id
    }
  }
`;

export const DUPLICATE_PRICE_LIST_ITEM = gql`
  mutation duplicatePriceList($id: Int!, $input: DuplicatePriceListInput!) {
    data: duplicatePriceList(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_PRICE_LIST_ITEM = gql`
  mutation deletePriceListItem($id: Int!) {
    data: deletePriceListItem(id: $id) {
      id
    }
  }
`;

export const PROPAGATE_PRICE_LIST = gql`
  mutation propagatePriceList(
    $id: Int!
    $priceListIds: [Int!]!
    $createProductIds: [Int!]!
    $updateProductIds: [Int!]!
  ) {
    data: propagatePriceList(
      id: $id
      priceListIds: $priceListIds
      createProductIds: $createProductIds
      updateProductIds: $updateProductIds
    ) {
      id
    }
  }
`;

export const ADD_ITEM_TO_LISTS = gql`
  mutation addItemToPriceLists($productId: Int!, $priceListIds: [Int!]!) {
    data: addItemToPriceLists(
      productId: $productId
      priceListIds: $priceListIds
    ) {
      id
    }
  }
`;

export const EDIT_ITEM_IN_LISTS = gql`
  mutation editItemInPriceLists($productId: Int!, $priceListIds: [Int!]!) {
    data: editItemInPriceLists(
      productId: $productId
      priceListIds: $priceListIds
    ) {
      id
    }
  }
`;

export const propagateFromImport = (
  priceListIds: number[],
  productIds: number[]
) => request.post(`/propagate`, { priceListIds, productIds });

export const importPriceList = (body: any) =>
  request.post(`/imports/price-lists`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
