const SvgComponent = ({ containerClassName = "", size = 24 }) => (
  <svg
    height={size}
    width={size}
    className={containerClassName}
    viewBox="0 0 162.37 162.97"
  >
    <g>
      <path
        d="M155.29,117.94v29.45c0,4.68-3.83,8.5-8.5,8.5h-29.15"
        fill="none"
        stroke="#1b1818"
        strokeLinecap="round"
        strokeWidth="14.17px"
      />
      <path
        d="M116.96,7.09h29.82c4.68,0,8.5,3.83,8.5,8.5v29.3"
        fill="none"
        stroke="#1b1818"
        strokeLinecap="round"
        strokeWidth="14.17px"
      />
      <path
        d="M7.09,44.89V15.59c0-4.68,3.83-8.5,8.5-8.5h29.07"
        fill="none"
        stroke="#1b1818"
        strokeLinecap="round"
        strokeWidth="14.17px"
      />
      <path
        d="M68.89,155.89h-2.27c-24.8,0-46.18-15.37-55.1-37.05"
        fill="none"
        stroke="#b0c936"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="14.17px"
      />
      <path
        d="M50.28,54.57c6.38,0,11.64,4.8,12.37,10.99.21,1.78-.19,2.36-2.2,2.36h-20.17c-1.18,0-2.66.11-2.34-2.48.77-6.13,6.01-10.87,12.35-10.87Z"
        fill="#b0c936"
        fillRule="evenodd"
        strokeWidth="0px"
      />
      <path
        d="M118.08,54.57c6.38,0,11.64,4.8,12.37,10.99.21,1.78-.19,2.36-2.2,2.36h-20.17c-1.18,0-2.66.11-2.34-2.48.77-6.13,6.01-10.87,12.35-10.87Z"
        fill="#b0c936"
        fillRule="evenodd"
        strokeWidth="0px"
      />
      <path
        d="M30.15,55.18c4.75-6.87,12.69-11.38,21.68-11.38"
        fill="none"
        stroke="#b0c936"
        strokeLinecap="round"
        strokeWidth="14.17px"
      />
      <path
        d="M119.05,43.81c-8.99,0-16.91,4.52-21.68,11.38-4,5.75-2.91,16.47-2.91,24.6v31.62c0,4.68-3.83,8.5-8.5,8.5h-15.42"
        fill="none"
        stroke="#b0c936"
        strokeLinecap="round"
        strokeWidth="14.17px"
      />
    </g>
  </svg>
);

export default SvgComponent;
