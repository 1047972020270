import { forwardRef, useState } from "react";
import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CCard,
  CCardBody,
  CCardHeader,
  CLoadingButton,
  CModalFooter,
  CModal,
  CButton,
  CModalHeader,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { normalize } from "src/helpers/strings";
import { logEvent } from "src/helpers/analytics";
import { Supplier, UpdateSupplier } from "src/api/suppliers";
import { useAdminStore } from "src/store";

const Screen = () => {
  const navigate = useNavigate();
  const params = useParams();
  const supplierId = Number(params.id);

  if (!supplierId) {
    navigate("/suppliers");
  }

  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );
  const { data: supplier } = useQuery<GraphQLFind<Supplier>>(
    Api.Suppliers.GET_SUPPLIER,
    {
      variables: {
        id: supplierId,
      },
    }
  );

  if (!companies?.data.data || !supplier?.data) {
    return <AppLoader />;
  }

  if (companies?.data.data.length === 0) {
    navigate(`/suppliers`);
    return null;
  }

  return (
    <CCard>
      <Form companies={companies.data.data ?? []} supplier={supplier.data} />
    </CCard>
  );
};

const Form = ({
  supplier,
  companies,
}: {
  supplier: Supplier;
  companies: Company[];
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { hasPermission } = useAdminStore();

  const [mutation, { error, loading }] = useMutation(
    Api.Suppliers.UPDATE_SUPPLIER,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const [deleteSupplier, { loading: deleting }] = useMutation(
    Api.Suppliers.DELETE_SUPPLIER,
    {
      onCompleted: () => {
        navigate(-1);
      },
    }
  );

  const onNameChange = (e) => {
    const { value } = e.currentTarget;

    formik.setFieldValue("identifier", normalize(value));
    formik.setFieldValue("name", value.toUpperCase());
  };

  const formik = useFormik<UpdateSupplier>({
    initialValues: {
      companyId: supplier.company.id,
      name: supplier.name,
      notes: supplier.notes,
    },
    onSubmit: (input) => {
      if (!loading) {
        const data = {
          id: supplier.id,
          companyId: Number(input.companyId),
          name: input.name?.trim().toUpperCase(),
          notes: input.notes?.trim() ?? null,
        };

        logEvent("supplier.update", {
          input: data,
        });

        mutation({
          variables: data,
        });
      }
    },
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCardHeader>Editar Proveedor: {supplier.name}</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xs="12">
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="companyId">Empresa</CFormLabel>
              </CCol>

              <CCol xs="12" md="9">
                <CFormSelect
                  id="companyId"
                  disabled={companies.length <= 1}
                  readOnly={companies.length <= 1}
                  name="companyId"
                  onChange={formik.handleChange}
                  value={formik.values.companyId}
                >
                  <option key="0" value="0">
                    Selecciona Empresa
                  </option>
                  {companies.map((company: Company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={onNameChange}
                  value={formik.values.name}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="notes">Notas</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="notes"
                  placeholder="Notas"
                  onChange={formik.handleChange}
                  name="notes"
                  defaultValue={formik.values.notes}
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow className="px-0">
          <CCol md="6">
            {error && (
              <ErrorMessage text="Los datos ingresados son incorrectos" />
            )}
          </CCol>
          <CCol md="6" className="flex justify-content-end">
            {hasPermission("DELETE_SUPPLIER") && (
              <>
                <CLoadingButton
                  loading={showModal || deleting}
                  size="sm"
                  color="danger"
                  className="mr-2"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar
                </CLoadingButton>

                <CModal
                  alignment="center"
                  visible={showModal}
                  onClose={() => setShowModal(false)}
                >
                  <CModalHeader closeButton>
                    ¿Estás seguro de eliminar este proveedor?
                    {supplier.products.length > 0 && (
                      <>
                        <br />
                        Ten en cuenta que hay {supplier.products.length}{" "}
                        producto(s) asignado(s) a este proveedor. Estos mismos
                        seran desvinculados.
                      </>
                    )}
                  </CModalHeader>
                  <CModalFooter>
                    <CButton
                      size="sm"
                      color="secondary"
                      onClick={() => setShowModal(false)}
                    >
                      Cancelar
                    </CButton>
                    <CLoadingButton
                      loading={deleting}
                      size="sm"
                      color="danger"
                      onClick={() => {
                        deleteSupplier({
                          variables: {
                            id: supplier.id,
                          },
                        });
                      }}
                    >
                      Si, quiero eliminarlo
                    </CLoadingButton>
                  </CModalFooter>
                </CModal>
              </>
            )}
            <CLoadingButton
              loading={loading}
              size="sm"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Guardar cambios
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export default forwardRef(Screen);
