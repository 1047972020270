import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CFormTextarea,
  CFormSelect,
  CModal,
  CModalHeader,
  CModalFooter,
  CAlert,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import { GraphQLFind, GraphQLMeta } from "src/types";
import Api from "src/api";
import { getValidity } from "src/helpers/validation";
import { Customer, CustomerUpdate, CustomerSchema } from "src/api/customers";
import { useAdminStore } from "src/store";
import { Store } from "src/api/stores";
import { useState } from "react";
import { AppLoader } from "src/components/Loader/Loader";
import { logEvent } from "src/helpers/analytics";

const CustomerScreen = () => {
  const params = useParams();
  const customerId = Number(params.id);
  const navigate = useNavigate();

  if (!customerId) {
    navigate("/customers");
  }

  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const { data: customer } = useQuery<GraphQLFind<Customer>>(
    Api.Customers.GET_CUSTOMER,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: customerId,
      },
      onError: () => {
        navigate(-1);
      },
    }
  );

  if (!stores?.data || !customer) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form customer={customer.data} stores={stores.data.data} />
    </CCol>
  );
};

const Form = ({
  customer,
  stores,
}: {
  customer: Customer;
  stores: Store[];
}) => {
  const { user } = useAdminStore();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const [updateMutation, { loading: updLoading, error: updError }] =
    useMutation(Api.Customers.UPDATE_CUSTOMER, {
      onCompleted: () => {
        window.location.reload();
      },
    });

  const [deleteMutation, { loading: delLoading, error: delError }] =
    useMutation(Api.Customers.DELETE_CUSTOMER, {
      onCompleted: () => {
        navigate("/customers");
      },
    });

  const onDelete = () => {
    if (!delLoading && !updLoading) {
      deleteMutation({
        variables: {
          id: customer.id,
        },
      });
    }
  };

  const formik = useFormik<CustomerUpdate>({
    initialValues: {
      companyId: customer.companyId,
      storeId: customer.storeId,
      genre: customer.genre,
      name: customer.name,
      lastname: customer.lastname,
      email: customer.email,
      alias: customer.alias,
      utid: customer.utid,
      notes: customer.notes,
      phone: customer.phone,
      streetName: customer.streetName,
      streetNumber: customer.streetNumber,
      discount: customer.discount,
    },
    onSubmit: (data) => {
      if (!updLoading && !delLoading) {
        const input = {
          ...data,
          companyId: Number(data.companyId),
          storeId: Number(data.storeId),
        };

        logEvent("customers.update", {
          id: customer.id,
          input,
        });

        updateMutation({
          variables: {
            id: customer.id,
            input,
          },
        });
      }
    },
    validationSchema: CustomerSchema,
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>
          Editar Cliente - #{customer.id} - {customer.name} {customer.lastname}
        </CCardHeader>
        <CCardBody>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="storeId">Punto de Venta</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormSelect
                id="storeId"
                name="storeId"
                defaultValue={formik.values.storeId}
                onChange={formik.handleChange}
                {...getValidity(formik.values.storeId, formik.errors.storeId)}
              >
                <option key="0" value="0">
                  Seleccione Punto de Venta
                </option>
                {stores.map((store) => (
                  <option key={store.id} value={store.id}>
                    {user?.isAdmin ? `${store.company.name} - ` : ""}
                    {store.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="utid">CUIL / CUIT</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="utid"
                placeholder="CUIL / CUIT"
                name="utid"
                defaultValue={formik.values.utid}
                onChange={formik.handleChange}
                {...getValidity(formik.values.utid, formik.errors.utid)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="genre">Sexo</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormSelect
                id="genre"
                name="genre"
                defaultValue={formik.values.genre}
                onChange={formik.handleChange}
                {...getValidity(formik.values.genre, formik.errors.genre)}
              >
                <option value="U">Seleccione Sexo / Tipo</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
                <option value="S">Empresa</option>
                <option value="U">Otro</option>
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
                defaultValue={formik.values.name}
                {...getValidity(formik.values.name, formik.errors.name)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="lastname">Apellido</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="lastname"
                placeholder="Apellido"
                name="lastname"
                defaultValue={formik.values.lastname}
                onChange={formik.handleChange}
                {...getValidity(formik.values.lastname, formik.errors.lastname)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="email">Email</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="email"
                placeholder="Email"
                defaultValue={formik.values.email}
                name="email"
                onChange={formik.handleChange}
                {...getValidity(formik.values.email, formik.errors.email)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="alias">Alias</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="alias"
                placeholder="Alias"
                name="alias"
                defaultValue={formik.values.alias}
                onChange={formik.handleChange}
                {...getValidity(formik.values.alias, formik.errors.alias)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="phone">Teléfono</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="phone"
                placeholder="Teléfono"
                defaultValue={formik.values.phone}
                name="phone"
                onChange={formik.handleChange}
                {...getValidity(formik.values.phone, formik.errors.phone)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="streetName">Calle</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetName"
                placeholder="Calle"
                name="streetName"
                defaultValue={formik.values.streetName}
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.streetName,
                  formik.errors.streetName
                )}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="streetNumber">Altura</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="streetNumber"
                placeholder="Altura"
                name="streetNumber"
                defaultValue={formik.values.streetNumber}
                onChange={formik.handleChange}
                {...getValidity(
                  formik.values.streetNumber,
                  formik.errors.streetNumber
                )}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="discount">Descuento (%)</CFormLabel>
            </CCol>
            <CCol md="9">
              <CFormInput
                id="discount"
                placeholder="0 %"
                name="discount"
                type="number"
                defaultValue={formik.values.discount}
                onChange={formik.handleChange}
                {...getValidity(formik.values.discount, formik.errors.discount)}
              />
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="notes">Notas</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormTextarea
                id="notes"
                placeholder="Notas"
                name="notes"
                rows={4}
                defaultValue={formik.values.notes}
                onChange={formik.handleChange}
                style={{ resize: "none" }}
                {...getValidity(formik.values.notes, formik.errors.notes)}
              />
            </CCol>
          </CRow>
        </CCardBody>

        <CCardFooter>
          <CRow className="px-0 align-items-center">
            <CCol md="6">
              {(delError || updError) && (
                <CAlert color="danger" className="my-0 py-1">
                  Ha ocurrido un error. Vuelve a intentar
                </CAlert>
              )}
            </CCol>
            <CCol md="6" className="flex justify-content-end">
              {customer.deletedAt === null && (
                <>
                  <CButton
                    size="sm"
                    type="button"
                    color="danger"
                    className="mr-2"
                    onClick={() => setShowModal(true)}
                  >
                    Eliminar
                  </CButton>

                  <CModal
                    alignment="center"
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                  >
                    <CModalHeader closeButton>
                      ¿Estás seguro de eliminar este cliente?
                    </CModalHeader>
                    <CModalFooter>
                      <CButton
                        size="sm"
                        color="secondary"
                        onClick={() => setShowModal(false)}
                      >
                        Cancelar
                      </CButton>
                      <CButton size="sm" color="danger" onClick={onDelete}>
                        Si, quiero eliminarlo
                      </CButton>
                    </CModalFooter>
                  </CModal>
                </>
              )}

              <CLoadingButton
                disabled={updLoading}
                loading={updLoading}
                size="sm"
                type="submit"
                color="primary"
              >
                Guardar {customer.deletedAt !== null ? "y rehabilitar" : ""}
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CForm>
  );
};

export default CustomerScreen;
