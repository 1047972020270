const SvgComponent = ({ containerClassName = "", size = 24 }) => (
  <svg
    viewBox="0 0 413.24 265.47"
    height={size}
    width={size}
    className={containerClassName}
  >
    <g>
      <g>
        <path
          fill="#F72717"
          d="M160.43,117.34c-6.79,0-11.1,4.42-12.44,10.28h24.16C171.33,121.15,166.8,117.34,160.43,117.34z"
        />
        <path
          fill="#F72717"
          d="M95.86,119.91c-8.64,0-14.09,5.96-14.09,14.29c0,8.23,5.55,14.39,14.09,14.39s14.29-5.86,14.29-14.39
			C110.15,125.67,104.39,119.91,95.86,119.91z"
        />
        <path
          fill="#F72717"
          d="M270.22,106.24c-12.97,0-23.07,5.18-24.72,18.63c-0.12,0.01,0.08,0-0.04,0
			C241.38,63.92,193.3,15.73,131.32,15.73c-48.98,0-90.91,30.11-108.35,72.82H64.5l-2.16,13.98H29.75v14.81h29.51l-2.26,13.47H29.75
			v8.64c0,5.24,2.26,7.71,6.58,7.71h27.86l-2.26,13.98H31.08c-1.5,0-2.93-0.14-4.29-0.38v0.01c0,0-5.34-0.58-8.65-4.88
			c-0.08-0.1-1.43-1.7-2.09-4.33c-0.17-0.69-0.19-0.73-0.32-1.27c0.22,1.74,0.59,4.08,0.76,4.94c0.94,4.88,2.13,9.01,2.24,9.3
			c13.86,49.16,59,85.21,112.59,85.21c54.82,0,101.13-37.71,113.82-88.59h-15.73v-28.58c0-7.71-2.98-12.65-10.59-12.65
			c-7.61,0-10.59,4.94-10.59,12.65v28.58H192.5v-32.49c0-15.53,11.31-22.41,26.32-22.41s26.32,6.89,26.32,22.41l-0.01,32.49h15.83
			v-28.58c0-7.71,2.06-12.65,9.56-12.65c2.37,0,5.24,0.72,6.27,1.23l2.98-13.78C278.04,107.16,273.62,106.24,270.22,106.24z
			M125.98,131.02c0,19.64-11.62,31.15-30.85,31.15c-17.79,0-29.82-11.93-29.82-27.97c0-16.76,11.41-27.97,28.48-27.97
			c7.81,0,13.57,3.08,16.45,6.17V88.55h15.73V131.02z M165.67,150.35c4.63,0,8.91-1.34,12.93-3.19l-1.31,12.44
			c-4.52,1.85-9.97,2.57-15.42,2.57c-18.1,0-30.85-10.59-30.85-28.58c0-15.63,11.41-27.35,28.89-27.35
			c19.74,0,29.61,12.54,29.2,31.87H148.4C149.94,146.96,158.37,150.35,165.67,150.35z"
        />
        <path
          fill="#F72717"
          d="M305.9,106.24c-17.48,0-28.89,11.72-28.89,27.35c0,17.99,12.75,28.58,30.85,28.58
			c5.45,0,10.9-0.72,15.42-2.57l1.31-12.44c-4.01,1.85-8.3,3.19-12.92,3.19c-7.3,0-15.73-3.39-17.27-12.24h40.72
			C335.51,118.78,325.64,106.24,305.9,106.24z M293.97,127.63c1.34-5.86,5.66-10.28,12.44-10.28c6.38,0,10.9,3.8,11.72,10.28H293.97
			z"
        />
        <path
          fill="#F72717"
          d="M381.78,88.55v23.85c-2.88-3.08-8.64-6.17-16.45-6.17c-17.07,0-28.48,11.21-28.48,27.97
			c0,16.04,12.03,27.97,29.82,27.97c19.23,0,30.85-11.52,30.85-31.15V88.55H381.78z M367.38,148.6c-8.53,0-14.09-6.17-14.09-14.39
			c0-8.33,5.45-14.29,14.09-14.29c8.53,0,14.29,5.76,14.29,14.29C381.68,142.74,375.92,148.6,367.38,148.6z"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
