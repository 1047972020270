import { useQuery } from "@apollo/client";
import Api from "src/api";
import { Company } from "src/api/companies";
import { AppLoader } from "src/components/Loader/Loader";
import { useAdminStore } from "src/store";
import { GraphQLFind, GraphQLMeta } from "src/types";
import CompanyData from "./components/CompanyData";
import CompanySettings from "./components/CompanySettings";
import StoreSettings from "./components/StoreSettings";
import RegisterSettings from "./components/RegisterSettings";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react-pro";
import { CashTransactionType } from "src/api/registers";
import { BankPromotion } from "src/api/bankPromotions";

const Settings = () => {
  const { currentCompany } = useAdminStore();
  const { data: company } = useQuery<GraphQLFind<Company>>(
    Api.Companies.GET_COMPANY,
    {
      skip: !currentCompany?.id,
      fetchPolicy: "no-cache",
      variables: {
        id: currentCompany?.id,
      },
    }
  );

  const { data: transactionTypes } = useQuery<GraphQLMeta<CashTransactionType>>(
    Api.Registers.LIST_TRANSACTION_TYPES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  const { data: bankPromotions } = useQuery<GraphQLMeta<BankPromotion>>(
    Api.BankPromotions.LIST_BANK_PROMOTIONS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  if (
    !company?.data ||
    !transactionTypes?.data.data ||
    !bankPromotions?.data.data
  ) {
    return <AppLoader />;
  }

  const storeIds = company.data.stores.map((store) => store.id);

  return (
    <>
      <CAccordion activeItemKey={1}>
        <CAccordionItem itemKey={1}>
          <CAccordionHeader>Datos Básicos</CAccordionHeader>
          <CAccordionBody>
            <CompanyData company={company.data} />
          </CAccordionBody>
        </CAccordionItem>

        <CAccordionItem itemKey={2}>
          <CAccordionHeader>Configuraciones de Empresa</CAccordionHeader>
          <CAccordionBody>
            <CompanySettings
              isFranchise={company.data.isFranchise}
              companyId={company.data.id}
              config={company.data.config}
            />
          </CAccordionBody>
        </CAccordionItem>

        <CAccordionItem itemKey={3}>
          <CAccordionHeader>
            Configuraciones de Puntos de Venta
          </CAccordionHeader>
          <CAccordionBody>
            <StoreSettings
              bankPromotions={bankPromotions.data.data}
              companyId={company.data.id}
            />
          </CAccordionBody>
        </CAccordionItem>

        <CAccordionItem itemKey={4}>
          <CAccordionHeader>
            Configuraciones de Cajas Registradoras
          </CAccordionHeader>
          <CAccordionBody>
            <RegisterSettings
              storeIds={storeIds}
              transactionTypes={transactionTypes.data.data}
            />
          </CAccordionBody>
        </CAccordionItem>
      </CAccordion>
    </>
  );
};

export default Settings;
