import { CAlert } from "@coreui/react-pro";
import classnames from "classnames";
import { useMemo } from "react";

const ErrorMessage = ({
  className,
  error,
  text = "Ha ocurrido un error. Vuelve a intentar",
}: {
  className?: string;
  error?: string;
  text?: string;
}) => {
  const message = useMemo(() => {
    if (!error) {
      return text;
    }

    switch (error) {
      case "email_already_exists":
        return "El email ya se encuentra en uso.";

      default:
        return text;
    }
  }, [error, text]);

  return (
    <CAlert color="danger" className={classnames("my-0 px-3 py-1", className)}>
      {message}
    </CAlert>
  );
};

export default ErrorMessage;
