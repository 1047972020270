import { gql } from "@apollo/client";

export type Sector = {
  id: number;
  slug: string;
  name: string;
  notes: string;
};

export type CreateSector = Omit<Sector, "id">;

export type UpdateSector = CreateSector;

export const LIST_SECTORS = gql`
  query sectors {
    data: sectors {
      data {
        id
        name
        slug
        notes
      }

      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_SECTOR = gql`
  query sector($id: Int!) {
    data: sector(id: $id) {
      id
      name
      slug
      notes
    }
  }
`;

export const CREATE_SECTOR = gql`
  mutation createSector($input: SectorInput!) {
    data: createSector(input: $input) {
      id
    }
  }
`;

export const UPDATE_SECTOR = gql`
  mutation updateSector($id: Int!, $input: SectorInput!) {
    data: updateSector(id: $id, input: $input) {
      id
    }
  }
`;
