import {
  CAlert,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import Api from "src/api";
import { logEvent } from "src/helpers/analytics";

const NotConnectedStatus = ({
  companyId,
  storeId,
}: {
  companyId: number;
  storeId?: number;
}) => {
  const certInputRef = useRef<HTMLInputElement>(null);
  const keyInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      if (!loading) {
        setLoading(true);
        try {
          const formData = new FormData();

          if (
            !certInputRef.current?.files?.[0] ||
            !keyInputRef.current?.files?.[0]
          ) {
            alert("Debes cargar ambos archivos.");

            return;
          }

          formData.append("certificate", certInputRef.current.files[0]);
          formData.append("key", keyInputRef.current.files[0]);
          formData.append("companyId", companyId.toString());

          if (storeId) {
            formData.append("storeId", storeId.toString());
          }

          await Api.Afip.importAfipFiles(formData);

          window.location.reload();
        } catch (e: any) {
          logEvent("afip.import.error", {
            error: e.message,
          });

          if (e.message === "invalid_file") {
            alert(
              "El archivo es inválido, por favor revisa que sea el correcto."
            );
          } else {
            alert("No se han podido cargar los certificados.");
          }
        } finally {
          if (certInputRef.current) {
            certInputRef.current.value = "";
          }

          if (keyInputRef.current) {
            keyInputRef.current.value = "";
          }

          setLoading(false);
        }
      }
    },
  });

  return (
    <CCard>
      <CCardBody>
        <CAlert color="warning" className="mb-0">
          Aun no has conectado tu cuenta a AFIP, para esto, necesitamos que
          sigas los siguientes pasos.
        </CAlert>

        <CForm encType="multipart/form-data" className="mt-3">
          <CRow className="mb-3">
            <CFormLabel>
              1 - Ingresa al sistema de AFIP y genera tus certificados.
            </CFormLabel>
          </CRow>

          <CRow className="file-input-row mb-3">
            <CFormInput
              ref={certInputRef}
              label="2 - Una vez generado el certificado (.crt), subelo en el siguiente campo"
              type="file"
              accept=".crt"
            />
          </CRow>

          <CRow className="file-input-row mb-3">
            <CFormInput
              ref={keyInputRef}
              label="3 - Una vez generada la clave (.key), subela en el siguiente campo"
              type="file"
              accept=".key"
            />
          </CRow>

          <CRow className="mb-2">
            <CFormLabel>
              4 - Sube los archivos y empieza a configurar tus facturas.
            </CFormLabel>
          </CRow>

          <CRow>
            <CCol md="12">
              <CLoadingButton
                size="sm"
                loading={loading}
                color="success"
                onClick={() => formik.handleSubmit()}
              >
                Subir archivos y conectarme a AFIP
              </CLoadingButton>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  );
};

export default NotConnectedStatus;
