import { CCol, CRow, CWidgetStatsF } from "@coreui/react-pro";
import { IncomesPerMethod } from "src/api/dashboard";
import { PaymentMethod } from "src/api/sales";
import Icon from "src/components/Icon";
import { formatCurrency } from "src/helpers/numbers";
import { parseMethodItem } from "src/helpers/payments";

const PaymentMethodsIncomes = ({ incomes }: { incomes: IncomesPerMethod }) => {
  return (
    <CRow className="mt-3">
      {Object.entries(incomes).map(([key, value]) => {
        if (key === "__typename" || !value || value === 0) {
          return null;
        }

        const item = parseMethodItem(key as PaymentMethod);

        const CustomIcon = item.CustomIcon;

        return (
          <CCol key={key} xs="12" sm="6" lg="3" className="mb-2">
            <CWidgetStatsF
              className="payment-widget"
              title={item.title}
              value={formatCurrency(value)}
              color={item.color}
              padding={false}
              icon={
                item.CustomIcon ? <CustomIcon /> : <Icon name={item.icon} />
              }
            />
          </CCol>
        );
      })}
    </CRow>
  );
};

export default PaymentMethodsIncomes;
