import { gql } from "@apollo/client";
import { CashRegisterSession } from "./registers";
import { User } from "./users";

export type Transaction = {
  id: number;
  registerId: number;
  sessionId: number;
  userId: number;
  amount: number;
  type: "Sell" | string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;

  user: User;
  session: CashRegisterSession;
};

export const LIST_TRANSACTIONS = gql`
  query transactions($filters: CashRegisterTransactionFilters) {
    data: transactions(filters: $filters) {
      data {
        id
        amount
        type
        description
        createdAt

        session {
          openedAt
          closedAt
        }

        user {
          name
          lastname
        }

        deletedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;
