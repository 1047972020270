import { useMutation } from "@apollo/client";
import {
  CButton,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import Api from "src/api";
import Config from "src/config";

export type ConnectModalProps = {
  open: () => void;
  close: () => void;
};

const ConnectModal: ForwardRefRenderFunction<
  ConnectModalProps,
  { storeId: number }
> = ({ storeId }, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mutation] = useMutation(Api.Stores.SET_METADATA, {
    onCompleted: () => {
      setShowModal(false);
      formik.resetForm();

      window.location.reload();
    },
  });

  const formik = useFormik({
    initialValues: {
      url: "",
    },
    onSubmit: (data) => {
      mutation({
        variables: {
          id: storeId,
          input: [
            {
              key: "tiendaNubeUrl",
              value: `${data.url}.mitiendanube.com`,
            },
          ],
        },
      });

      window.open(
        `https://${data.url}.mitiendanube.com/admin/apps/${Config.tiendaNubeClientId}/authorize`,
        "_blank"
      );
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setShowModal(true);
      },
      close: () => {
        setShowModal(false);
        formik.resetForm();
      },
    }),
    [formik]
  );

  return (
    <CModal
      alignment="center"
      size="lg"
      visible={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>
        Conectar Punto de Venta con Tienda Nube
      </CModalHeader>
      <CModalBody>
        <CFormLabel htmlFor="basic-url">URL de tu Tienda Nube</CFormLabel>
        <CInputGroup className="mb-3">
          <CInputGroupText>https://</CInputGroupText>
          <CFormInput
            name="url"
            placeholder="daruma"
            onChange={formik.handleChange}
          />
          <CInputGroupText>.mitiendanube.com</CInputGroupText>
        </CInputGroup>
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          onClick={() => {
            setShowModal(false);
            formik.resetForm();
          }}
        >
          Cancelar
        </CButton>
        <CButton
          size="sm"
          type="button"
          color="success"
          disabled={formik.values.url.length === 0}
          onClick={() => formik.handleSubmit()}
        >
          Continuar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(ConnectModal);
