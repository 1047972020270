import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Permission, PermissionMap } from "src/api/permissions";
import { UserPermission } from "src/api/users";
import { useAdminStore } from "src/store";
import { CompanyPermissionValue, StorePermissionValue } from "src/types";

export const permissionsMap = [
  { label: "Ventas", keys: ["SALE", "SALES"], singular: "Venta" },
  {
    label: "Cajas Registradoras",
    keys: ["REGISTER", "REGISTERS"],
    singular: "Caja Registradora",
  },
  {
    label: "Transacciones",
    keys: ["TRANSACTION", "TRANSACTIONS"],
    singular: "Transacción",
  },
  {
    label: "Listas de Precios",
    keys: ["PRICE_LIST", "PRICE_LISTS"],
    singular: "Lista de Precio",
  },
  {
    label: "Items de Lista de Precios",
    keys: ["PRICE_LIST_ITEM", "PRICE_LIST_ITEMS"],
    singular: "Item de Lista de Precios",
  },
  { label: "Empresas", keys: ["COMPANY", "COMPANIES"], singular: "Empresa" },
  {
    label: "Depósitos",
    keys: ["WAREHOUSE", "WAREHOUSES"],
    singular: "Depósito",
  },
  {
    label: "Ingresos",
    keys: ["BATCH", "BATCHES"],
    singular: "Ingreso",
  },
  {
    label: "Egresos",
    keys: ["RETIRE", "RETIRES"],
    singular: "Egreso",
  },
  {
    label: "Plan",
    keys: ["PLAN", "PLANS"],
    singular: "Plan",
  },
  {
    label: "Pedidos",
    keys: ["ORDER", "ORDERS"],
    singular: "Pedido",
  },
  {
    label: "Proveedores",
    keys: ["SUPPLIER", "SUPPLIERS"],
    singular: "Proveedor",
  },
  {
    label: "Categorías",
    keys: ["CATEGORY", "CATEGORIES"],
    singular: "Categoría",
  },
  { label: "Productos", keys: ["PRODUCT", "PRODUCTS"], singular: "Producto" },
  {
    label: "Puntos de Venta",
    keys: ["STORE", "STORES"],
    singular: "Punto de Venta",
  },
  {
    label: "Facturas",
    keys: ["INVOICE", "INVOICES"],
    singular: "Factura",
  },
  { label: "Usuarios", keys: ["USER", "USERS"], singular: "Usuario" },
  { label: "Reportes", keys: ["REPORT", "REPORTS"], singular: "Reporte" },
  {
    label: "Analíticas",
    keys: ["ANALYTIC", "ANALYTICS"],
    singular: "Analítica",
  },
];

export const mapPermissions = (permissions: Permission[]): PermissionMap[] => {
  if (!permissions) {
    return [];
  }

  const mapped = [];

  permissions.forEach((permission) => {
    if (!mapped[permission.entity]) {
      mapped[permission.entity] = {
        entity: permission.entity,
        label: permissionsMap.find((p) => p.keys.includes(permission.entity))
          ?.label,
      };
    }

    const [type] = permission.key.split("_");

    mapped[permission.entity] = {
      ...mapped[permission.entity],
      [type]: {
        id: permission.id,
        key: permission.key,
        name: permission.name,
      },
    };
  });

  return Object.entries(mapped).map(([key, value]) => {
    return value;
  });
};

export const useCanNavigate = (
  permissionKey: string,
  {
    route,
    condition,
    entity,
    entityId,
    force,
  }: {
    route?: string;
    condition?: boolean;
    entity?: "company" | "store";
    entityId?: number;
    force?: boolean;
  } = {}
) => {
  const navigate = useNavigate();
  const { hasPermission } = useAdminStore();
  const meetCondition = condition ? condition : true;

  useEffect(() => {
    if (!hasPermission(permissionKey, { force }) && meetCondition) {
      if (route) {
        navigate(route);
      } else {
        navigate(-1);
      }
    }
  }, [force, hasPermission, meetCondition, navigate, permissionKey, route]);
};

export const storesWithPermission = (
  userPermissions: UserPermission[],
  permissionKey: string
) => {
  const permissions = userPermissions.filter(
    (permission: UserPermission) => permission.key === permissionKey
  );

  if (permissions.length === 0) {
    return [0];
  }

  const values = permissions.flatMap((permission) => {
    const value =
      typeof permission.value === "string"
        ? JSON.parse(permission.value)
        : permission.value;

    return value as unknown as StorePermissionValue[];
  });

  const inStorePermission = values
    .filter((item) => {
      return (
        typeof item.storeId !== "undefined" &&
        item.storeId !== null &&
        item.value === true
      );
    })
    .map((value) => value.storeId);

  return inStorePermission.length === 0 ? [0] : inStorePermission;
};

export const companiesWithPermission = (
  userPermissions: UserPermission[],
  permissionKey: string
) => {
  const permissions = userPermissions.filter(
    (permission: UserPermission) => permission.key === permissionKey
  );

  if (permissions.length === 0) {
    return [0];
  }

  const values = permissions.flatMap(
    (permission) => permission.value as unknown as CompanyPermissionValue[]
  );

  const inCompanyPermission = values
    .filter(
      (item) =>
        typeof item.companyId !== "undefined" &&
        item.companyId !== null &&
        item.value === true
    )
    .map((value) => value.companyId);

  return inCompanyPermission.length === 0 ? [0] : inCompanyPermission;
};
