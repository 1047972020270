const SvgComponent = ({ containerClassName = "", size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 967 703"
    height={size}
    width={size}
    className={containerClassName ?? ""}
  >
    <path
      d="M327 1.6c-1.9.2-9.8.9-17.5 1.4-58.6 4.6-113.5 20.5-158 45.8C72.1 94 22.5 170.1 6 272c-7 43.4-7.7 94.5-2 139.5 13.3 104.1 59.8 184.9 135.2 234.8 45.8 30.4 98 47.6 162.8 53.9 19.4 1.8 74.7 1.6 98-.5 50.5-4.6 102.1-15.1 138.8-28.2l10.2-3.7V322H442v136.5c0 75.1-.3 136.5-.7 136.5-.5 0-6.6 1.1-13.8 2.4-49.3 9.1-101.8 9.3-142 .5-44.4-9.8-79.7-28.6-107.5-57.5-16.1-16.7-25.5-30.3-36.6-52.9-15.6-32-24.3-67.2-27.4-111.2-1.6-21.2-.8-60 1.4-77.3 9.1-69.7 36.8-121.8 83.1-156.1C243.6 109.5 301.4 95 377.6 98c46.1 1.8 86.7 8.6 134.7 22.6 6.6 1.9 12.3 3.4 12.8 3.4 2.1 0 12.2-37.9 15.8-59 2.5-14.8 4.5-37 3.4-38-1.4-1.3-23-8-36.3-11.2C480.5 9.2 444.8 4.4 405.3 2c-15.5-.9-69.6-1.2-78.3-.4zM694 52.5V80h55V25h-55v27.5zM803 52.5V80h55V25h-55v27.5zM911 52.5V80h55V25h-55v27.5zM694 161.5V189h55v-55h-55v27.5zM803 161.5V189h55l-.2-27.3-.3-27.2-27.2-.3-27.3-.2v27.5zM911 162v28h55v-56h-55v28zM694 270.5V298h55v-55h-55v27.5zM803 270.5V298h55v-55h-55v27.5zM911 270.5V298h55v-55h-55v27.5z"
      fill="#EC0000"
    />
  </svg>
);

export default SvgComponent;
