import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
  CLink,
  CCollapse,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CBadge,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { logEvent } from "src/helpers/analytics";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { Plan, CompanyPlan, StorePlan, PlanType } from "src/api/plans";
import { AppLoader } from "src/components/Loader/Loader";
import { formatCurrency } from "src/helpers/numbers";
import { dateFormat } from "src/helpers/dates";
import {
  checkPayments,
  findPlan,
  parseStatus,
  parseStatusBadge,
} from "src/helpers/plans";
import { usePagination } from "src/hooks/pagination";
import { queryStringToObject } from "src/helpers/strings";
import { useState } from "react";
import Icon from "src/components/Icon";
import { useAdminStore } from "src/store";
import { capitalize } from "lodash";
import Pagination from "src/components/Pagination";
import { isBefore } from "date-fns";
import { PaymentStatus } from "src/api/companies";

const PlansList = () => {
  const navigate = useNavigate();
  const { user } = useAdminStore();
  const { state, search } = useLocation();
  const { page, pageChange } = usePagination("plans");
  const querySearch = { ...queryStringToObject(search), ...state };
  const [companyPlan, setCompanyPlan] = useState<number>();

  if (!user?.isAdmin) {
    navigate("/");
  }

  const { data: plans, loading } = useQuery<GraphQLMeta<Plan>>(
    Api.Plans.LIST_PLANS
  );

  const {
    data: companyPlans,
    refetch,
    loading: loadingCompanyPlans,
  } = useQuery<GraphQLMeta<CompanyPlan>>(Api.Plans.LIST_COMPANY_PLANS, {
    fetchPolicy: "no-cache",
    variables: {
      filters: {
        page: querySearch.page ? Number(querySearch.page) : 1,
      },
    },
  });

  const [payMutation, { loading: paying }] = useMutation(Api.Plans.PAY_PLAN, {
    onCompleted: () => {
      refetch({
        filters: {
          page: 1,
        },
      });
    },
  });

  const [generateLinkMutation, { loading: generating }] = useMutation(
    Api.Plans.CREATE_PAYMENT_LINK,
    {
      onCompleted: () => {
        refetch({
          filters: {
            page: 1,
          },
        });
      },
    }
  );

  const downloadInvoice = async (storePlan: StorePlan) => {
    try {
      const invoice = await Api.Plans.EXPORT_INVOICE(
        `${storePlan.companyPlanId.toString().padStart(4, "0")}-${storePlan.id
          .toString()
          .padStart(4, "0")}`
      );

      const url = window.URL.createObjectURL(new Blob([invoice.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", invoice.fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {}
  };

  const generatePaymentLink = (storePlan: StorePlan) => {
    generateLinkMutation({
      variables: {
        storePlanId: storePlan.id,
      },
    });
  };

  if (!user?.isAdmin || !plans?.data.data) {
    return <AppLoader />;
  }

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={8}>Planes</CCol>
                <CCol sm={4} className="text-right"></CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={plans?.data.data || []}
                columns={[
                  {
                    key: "name",
                    label: "Nombre",
                  },
                  {
                    key: "price",
                    label: "Precio",
                    _props: {
                      className: "text-right",
                    },
                  },
                  {
                    key: "users",
                    label: "Usuarios",
                    _props: {
                      className: "text-center",
                    },
                  },
                  {
                    key: "invoicing",
                    label: "Tope de Facturacion",
                    _props: {
                      className: "text-right",
                    },
                  },
                ]}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  name: (item: Plan) => {
                    const currentType = findPlan(item);
                    const StoreIcon = () => currentType?.icon;

                    return (
                      <td>
                        <div className="d-flex">
                          <StoreIcon />
                          <span className="ml-2">{item.name}</span>
                        </div>
                      </td>
                    );
                  },
                  price: (item: Plan) => (
                    <td className="text-right">{formatCurrency(item.price)}</td>
                  ),
                  users: (item: Plan) => (
                    <td className="text-center">{item.users}</td>
                  ),
                  invoicing: (item: Plan) => (
                    <td className="text-right">
                      {formatCurrency(item.invoicing)}
                    </td>
                  ),
                }}
                clickableRows
                onRowClick={(item: Item) => {
                  logEvent("plans.navigate", {
                    date: item,
                  });

                  navigate(`/plans/${item.id}`);
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow className="mt-3">
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={8}>Facturas</CCol>
                <CCol sm={4} className="text-right">
                  <CLink href="/#/plans/invoice/new">
                    <CButton color="primary" size="sm">
                      Crear Factura
                    </CButton>
                  </CLink>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loadingCompanyPlans}
                items={companyPlans?.data.data || []}
                columns={[
                  {
                    key: "id",
                    label: "#",
                  },
                  {
                    key: "company",
                    label: "Empresa",
                  },
                  {
                    key: "dates",
                    label: "Periodo",
                    _props: {
                      className: "text-center",
                    },
                  },
                  {
                    key: "status",
                    label: "Estado del Plan",
                    _props: {
                      className: "text-center",
                    },
                  },
                  {
                    key: "actions",
                    label: "",
                    _props: { className: "font-weight-bold text-right" },
                  },
                ]}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  id: (item: CompanyPlan) => (
                    <td>{item.id.toString().padStart(4, "0")}</td>
                  ),
                  company: (item: CompanyPlan) => <td>{item.company.name}</td>,
                  dates: (item: CompanyPlan) => (
                    <td className="text-center">
                      {capitalize(dateFormat(item.dateTo, "MMMM ''yy"))}
                    </td>
                  ),
                  status: (item: CompanyPlan) => {
                    const totalAmount = item.stores.reduce(
                      (p, c) => p + (c.price - c.discount),
                      0
                    );
                    const isNext =
                      item.dateFrom &&
                      isBefore(new Date(), new Date(item.dateFrom));
                    const status = isNext
                      ? item.status
                      : (totalAmount === 0 &&
                          item.status === PaymentStatus.PENDING) ||
                        checkPayments(item.stores)
                      ? PaymentStatus.PAID
                      : item.status;

                    return (
                      <td className="text-center">
                        <CBadge
                          color={parseStatusBadge(
                            status,
                            item.dateFrom,
                            item.dateTo
                          )}
                        >
                          {parseStatus(
                            status,
                            item.dateFrom,
                            item.dateTo
                          ).toUpperCase()}
                        </CBadge>
                      </td>
                    );
                  },
                  actions: (item: CompanyPlan) => (
                    <td className="text-right">
                      <CButton
                        size="sm"
                        variant="ghost"
                        color="secondary"
                        onClick={() =>
                          setCompanyPlan((prev) =>
                            prev === item.id ? undefined : item.id
                          )
                        }
                      >
                        <Icon
                          name={
                            companyPlan === item.id
                              ? "chevron-up"
                              : "chevron-down"
                          }
                          color="black"
                        />
                      </CButton>
                    </td>
                  ),
                  details: (item: Item) => (
                    <CCollapse visible={companyPlan === item.id}>
                      <CTable>
                        <CTableHead>
                          <CTableRow>
                            <CTableHeaderCell scope="col">#</CTableHeaderCell>
                            <CTableHeaderCell scope="col">
                              Producto
                            </CTableHeaderCell>
                            <CTableHeaderCell scope="col">
                              Punto de Venta
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Precio
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Descuento
                            </CTableHeaderCell>
                            <CTableHeaderCell className="text-center">
                              Subtotal
                            </CTableHeaderCell>
                            <CTableHeaderCell
                              scope="col"
                              className="text-center"
                            >
                              Estado de Pago
                            </CTableHeaderCell>
                            {![PlanType.Free, PlanType.Trial].includes(
                              (item as CompanyPlan).type
                            ) && (
                              <CTableHeaderCell
                                scope="col"
                                className="text-right"
                              >
                                Acciones
                              </CTableHeaderCell>
                            )}
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {item.stores.map((storePlan: StorePlan) => {
                            const currentType = findPlan(storePlan);
                            const StoreIcon = () => currentType?.icon;
                            const payable =
                              storePlan.price - storePlan.discount > 0;
                            const isNext =
                              storePlan.dateFrom &&
                              isBefore(
                                new Date(),
                                new Date(storePlan.dateFrom)
                              );
                            const storeStatus = isNext
                              ? item.status
                              : (!payable &&
                                  item.status === PaymentStatus.PENDING) ||
                                storePlan.payedAt
                              ? PaymentStatus.PAID
                              : item.status;

                            return (
                              <CTableRow key={storePlan.id}>
                                <CTableDataCell>
                                  {item.id.toString().padStart(4, "0")}-
                                  {storePlan.id.toString().padStart(4, "0")}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div className="d-flex">
                                    <StoreIcon />
                                    <span className="ml-2">
                                      {currentType?.label}
                                    </span>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  {storePlan.store.name}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {formatCurrency(storePlan.price)}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {formatCurrency(storePlan.discount * -1)}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {formatCurrency(
                                    storePlan.price - storePlan.discount
                                  )}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  <CBadge
                                    color={parseStatusBadge(
                                      storeStatus,
                                      storePlan.dateFrom,
                                      storePlan.dateTo
                                    )}
                                  >
                                    {parseStatus(
                                      storeStatus,
                                      storePlan.dateFrom,
                                      storePlan.dateTo,
                                      true
                                    )}
                                    {storePlan.payedAt &&
                                      ` el ${dateFormat(
                                        storePlan.payedAt,
                                        "dd/MM/yy"
                                      )}`}
                                  </CBadge>
                                </CTableDataCell>
                                {![PlanType.Free, PlanType.Trial].includes(
                                  (item as CompanyPlan).type
                                ) && (
                                  <CTableDataCell className="text-right">
                                    {payable ? (
                                      <>
                                        {!storePlan.payedAt ? (
                                          <CLoadingButton
                                            loading={paying}
                                            disabled={paying}
                                            size="sm"
                                            color="success"
                                            onClick={() =>
                                              payMutation({
                                                variables: {
                                                  companyPlanId:
                                                    storePlan.companyPlanId,
                                                  storePlanId: storePlan.id,
                                                },
                                              })
                                            }
                                          >
                                            Confirmar Pago
                                          </CLoadingButton>
                                        ) : (
                                          <CButton
                                            color="primary"
                                            size="sm"
                                            onClick={() => {
                                              downloadInvoice(storePlan);
                                            }}
                                          >
                                            Descargar Factura
                                          </CButton>
                                        )}

                                        {!storePlan.paymentLink ? (
                                          <CLoadingButton
                                            className="ml-2"
                                            disabled={generating}
                                            loading={generating}
                                            size="sm"
                                            color="info"
                                            onClick={() => {
                                              generatePaymentLink(storePlan);
                                            }}
                                          >
                                            Generar Link
                                          </CLoadingButton>
                                        ) : storePlan.payedAt ? (
                                          <></>
                                        ) : (
                                          <CButton
                                            className="ml-2"
                                            size="sm"
                                            color="info"
                                          >
                                            <CLink
                                              target="_blank"
                                              href={storePlan.paymentLink}
                                            >
                                              Ver Link
                                            </CLink>
                                          </CButton>
                                        )}
                                      </>
                                    ) : (
                                      <span>Sin acciones</span>
                                    )}
                                  </CTableDataCell>
                                )}
                              </CTableRow>
                            );
                          })}
                          <CTableRow>
                            <CTableDataCell colSpan={4} />
                            <CTableDataCell className="text-center">
                              Resta pagar:
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {formatCurrency(
                                item.stores.reduce(
                                  (p: number, c: StorePlan) =>
                                    p + (!c.payedAt ? c.price - c.discount : 0),
                                  0
                                )
                              )}
                            </CTableDataCell>
                            <CTableDataCell className="text-center"></CTableDataCell>
                            {![PlanType.Free, PlanType.Trial].includes(
                              (item as CompanyPlan).type
                            ) && <CTableDataCell className="text-right" />}
                          </CTableRow>
                        </CTableBody>
                      </CTable>
                    </CCollapse>
                  ),
                }}
              />

              <Pagination
                meta={companyPlans}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default PlansList;
