import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CCol,
  CRow,
  CLoadingButton,
  CFormInput,
} from "@coreui/react-pro";
import Api from "src/api";
import { useFormik } from "formik";
import { useAdminStore } from "src/store";
import { Entity, Operation } from "src/types";

export type BatchChangeModalForwardedRef = {
  open: (entityType: Entity, entityId: number, operation: Operation) => void;
  close: () => void;
};

export type BatchChangeModalProps = {
  onClose: (productIds: number[]) => void;
};

const BatchChangeModal: ForwardRefRenderFunction<
  BatchChangeModalForwardedRef,
  BatchChangeModalProps
> = ({ onClose }, ref) => {
  const { currentCompany } = useAdminStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentEntityType, setCurrentEntityType] = useState<Entity>();
  const [currentOperation, setCurrentOperation] = useState<Operation>();

  const formik = useFormik({
    initialValues: {
      entityId: 0,
      amount: 0,
      companyId: currentCompany?.id ?? 0,
    },
    onSubmit: async (data) => {
      if (data.amount === 0) {
        return handleClose();
      }

      if (!loading) {
        setLoading(true);

        const amountToUpdate =
          currentOperation === Operation.Discount
            ? data.amount > 100
              ? -100
              : -data.amount
            : data.amount;

        if (currentEntityType === Entity.Product) {
          const response = await Api.Products.batchCostUpdate(
            data.companyId,
            amountToUpdate
          );

          setLoading(false);
          handleClose(response.productIds);
          return;
        }

        if (currentEntityType === Entity.Category) {
          const response = await Api.Products.categoriesBatchCostUpdate(
            data.companyId,
            data.entityId,
            amountToUpdate
          );

          setLoading(false);
          handleClose(response.productIds);
          return;
        }

        if (currentEntityType === Entity.Supplier) {
          const response = await Api.Products.suppliersBatchCostUpdate(
            data.companyId,
            data.entityId,
            amountToUpdate
          );

          setLoading(false);
          handleClose(response.productIds);
          return;
        }

        setLoading(false);
      }
    },
  });

  const handleClose = useCallback(
    (productIds?: number[]) => {
      formik.resetForm();
      setShowModal(false);
      setCurrentEntityType(undefined);
      setCurrentOperation(undefined);

      if (productIds) {
        onClose(productIds);
      }
    },
    [formik, onClose]
  );

  useImperativeHandle(
    ref,
    () => ({
      open: (entityType: Entity, entityId: number, operation: Operation) => {
        setShowModal(true);
        setCurrentEntityType(entityType);
        setCurrentOperation(operation);
        formik.setFieldValue("entityId", entityId);
        formik.setFieldValue("companyId", currentCompany?.id ?? 0);
      },
      close: () => {
        handleClose();
      },
    }),
    [currentCompany?.id, formik, handleClose]
  );

  return (
    <CModal
      alignment="center"
      size="sm"
      visible={showModal}
      onClose={() => {
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>
        {currentOperation === Operation.Recharge
          ? "Aumentar precios"
          : "Reducir precios"}
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol md="12">
            <CFormInput
              floatingLabel="Porcentaje"
              name="amount"
              defaultValue={formik.values.amount}
              autoFocus
              min={0}
              max={999}
              onChange={formik.handleChange}
              type="number"
            />
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          disabled={loading}
          onClick={() => {
            handleClose();
          }}
        >
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          loading={loading}
          disabled={loading}
          onClick={() => formik.handleSubmit()}
          color="success"
        >
          Continuar
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(BatchChangeModal);
