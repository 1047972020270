import { useNavigate } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import Pagination from "src/components/Pagination";
import { Sector } from "src/api/sectors";
import { logEvent } from "src/helpers/analytics";

const SectorsScreen = () => {
  const navigate = useNavigate();
  const { page, pageChange } = usePagination("sectors");
  const { data: sectors, loading } = useQuery<GraphQLMeta<Sector>>(
    Api.Sectors.LIST_SECTORS,
    {
      fetchPolicy: "no-cache",
    }
  );

  const columns = [
    { key: "slug", label: "Slug" },
    {
      key: "name",
      label: "Nombre",
      _props: { className: "font-weight-bold" },
    },
    { key: "notes", label: "Notas" },
  ];

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol md="6" className="flex align-items-center">
                  Rubros
                </CCol>
                <CCol md="6" className="text-end">
                  <CButton
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={() => {
                      logEvent("sectors.new-sector");

                      navigate("/sectors/new");
                    }}
                  >
                    Nuevo Rubro
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={sectors?.data.data.length ?? 20}
                loading={loading}
                items={sectors?.data.data || []}
                columns={columns}
                tableProps={{
                  striped: true,
                  hover: true,
                }}
                clickableRows
                onRowClick={(item) => {
                  navigate(`/sectors/${item.id}`);
                }}
              />

              <Pagination meta={sectors} page={page} pageChange={pageChange} />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default SectorsScreen;
