import { gql } from "@apollo/client";

export type BankPromotion = {
  id: number;
  companyId: number;

  name: string;
  key: string;
  notes: string;

  discount: number;
  paymentMethods: string[];

  dateFrom?: Date;
  dateTo?: Date;

  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type CreateBankPromotion = Omit<
  BankPromotion,
  "id" | "companyId" | "notes" | "key"
>;

export const LIST_BANK_PROMOTIONS = gql`
  query bankPromotions($filters: BankPromotionFilters) {
    data: bankPromotions(filters: $filters) {
      data {
        id
        name
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_BANK_PROMOTION = gql`
  query bankPromotion($id: Int!) {
    data: bankPromotion(id: $id) {
      id
    }
  }
`;

export const CREATE_BANK_PROMOTION = gql`
  mutation createBankPromotion($input: CreateBankPromotionInput!) {
    data: createBankPromotion(input: $input) {
      id
    }
  }
`;
