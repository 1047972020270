import { useEffect, useMemo, useState } from "react";
import {
  CBadge,
  CCol,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CSmartTable,
} from "@coreui/react-pro";
import {
  Permission,
  PermissionGroup,
  PermissionMap,
} from "src/api/permissions";
import { GraphQLMeta } from "src/types";
import { useLazyQuery, useQuery } from "@apollo/client";
import Api from "src/api";
import { Company } from "src/api/companies";
import { UserRole } from "src/api/users";
import { mapPermissions } from "src/helpers/permissions";
import { allItems } from "src/helpers/arrays";

const CompanyPermissions = ({
  formik,
  groups,
}: {
  formik: any;
  groups: PermissionGroup[];
}) => {
  const [companies, setCompanies] = useState<Company[]>();
  const [companyId, setCompanyId] = useState<number>();

  const [fetchCompanies] = useLazyQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      onCompleted: ({ data: companyList }) => {
        setCompanies(companyList.data);

        if (companyList.data.length === 1) {
          setCompanyId(companyList.data[0].id);
        }
      },
    }
  );

  const { data: permissions } = useQuery<GraphQLMeta<Permission>>(
    Api.Permissions.LIST_PERMISSIONS,
    {
      variables: {
        filters: {
          limit: 0,
          type: "Company",
        },
      },
    }
  );

  const mappedPermissions = useMemo(
    () => mapPermissions(permissions?.data.data ?? []),
    [permissions]
  );

  const onPermissionChange = (e: any) => {
    const userPermissions = formik.values.permissions;
    const { checked, name: permissionKey } = e.target;

    if (checked) {
      formik.setFieldValue("permissions", [...userPermissions, permissionKey]);
    } else {
      formik.setFieldValue(
        "permissions",
        userPermissions.filter((p) => p !== permissionKey)
      );
    }
  };

  useEffect(() => {
    const companyIds: number[] = Array.from(
      formik.values.companies as Map<number, UserRole>
    ).map(([cId]) => cId);

    fetchCompanies({
      variables: {
        filters: {
          companyIds,
        },
      },
    });
  }, [fetchCompanies, formik.values.companies]);

  const togglePermissions = (permissionsList: Permission[]) => {
    const userPermissions = formik.values.permissions;
    const groupPermissions = permissionsList
      .map((p) => {
        const [type, id] = p.key.split("_");

        return `${type}_${companyId}_${id}`;
      })
      .filter((p) => p.startsWith("c_"));

    formik.setFieldValue(
      "permissions",
      Array.from(new Set([...userPermissions, ...groupPermissions]))
    );
  };

  if (!companies || companies.length === 0) {
    return null;
  }

  return (
    <>
      <CFormSelect
        label="Empresa"
        value={companyId}
        disabled={companies.length === 1}
        onChange={(e) => {
          setCompanyId(Number(e.target.value));
        }}
      >
        {companies.length > 1 && <option value="0">Seleccione Empresa</option>}
        {companies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        ))}
      </CFormSelect>

      {companyId && companyId > 0 && (
        <>
          <CFormLabel className="mt-3">Grupos de Permisos</CFormLabel>
          <CCol sm={12} className="d-flex ">
            {groups.map((group) => {
              const groupPermissions = group.permissions
                .map((p) => {
                  const [type, id] = p.key.split("_");

                  return `${type}_${companyId}_${id}`;
                })
                .filter((p) => p.startsWith("c_"));

              return (
                <h5 key={group.id} className="d-flex">
                  <CBadge
                    color={
                      allItems(groupPermissions, formik.values.permissions)
                        ? "primary"
                        : "secondary"
                    }
                    className="mr-2"
                    onClick={() => togglePermissions(group.permissions)}
                  >
                    {group.name}
                  </CBadge>
                </h5>
              );
            })}
          </CCol>

          <CSmartTable
            className="mt-3"
            itemsPerPage={mappedPermissions.length}
            items={mappedPermissions}
            columns={[
              {
                key: "entity",
                label: "Entidad",
              },
              {
                key: "create",
                label: "Crear",
                _props: {
                  className: "text-center",
                },
              },
              {
                key: "update",
                label: "Editar",
                _props: {
                  className: "text-center",
                },
              },
              {
                key: "delete",
                label: "Eliminar",
                _props: {
                  className: "text-center",
                },
              },
              {
                key: "list",
                label: "Listar",
                _props: {
                  className: "text-center",
                },
              },
              {
                key: "show",
                label: "Mostrar",
                _props: {
                  className: "text-center",
                },
              },
            ]}
            scopedColumns={{
              entity: (item: PermissionMap) => (
                <td className="font-weight-bold">
                  <span className="font-weight-bold">{item.label}</span>
                </td>
              ),
              create: (item: PermissionMap) => {
                if (
                  !item.CREATE ||
                  ["COMPANY", "STORE"].includes(item.entity)
                ) {
                  return <td />;
                }

                const permKey = `c_${companyId}_${item.CREATE.id}`;

                return (
                  <td className="text-center">
                    <CFormCheck
                      name={permKey}
                      checked={formik.values.permissions.includes(permKey)}
                      onChange={onPermissionChange}
                    />
                  </td>
                );
              },
              update: (item: PermissionMap) => {
                if (!item.UPDATE) {
                  return <td />;
                }

                const permKey = `c_${companyId}_${item.UPDATE.id}`;

                return (
                  <td className="text-center">
                    <CFormCheck
                      name={permKey}
                      checked={formik.values.permissions.includes(permKey)}
                      onChange={onPermissionChange}
                    />
                  </td>
                );
              },
              delete: (item: PermissionMap) => {
                if (!item.DELETE) {
                  return <td />;
                }

                const permKey = `c_${companyId}_${item.DELETE.id}`;

                return (
                  <td className="text-center">
                    <CFormCheck
                      name={permKey}
                      checked={formik.values.permissions.includes(permKey)}
                      onChange={onPermissionChange}
                    />
                  </td>
                );
              },
              list: (item: PermissionMap) => {
                if (!item.LIST) {
                  return <td />;
                }

                const permKey = `c_${companyId}_${item.LIST.id}`;

                return (
                  <td className="text-center">
                    <CFormCheck
                      name={permKey}
                      checked={formik.values.permissions.includes(permKey)}
                      onChange={onPermissionChange}
                    />
                  </td>
                );
              },
              show: (item: PermissionMap) => {
                if (!item.SHOW) {
                  return <td />;
                }

                const permKey = `c_${companyId}_${item.SHOW.id}`;

                return (
                  <td className="text-center">
                    <CFormCheck
                      name={permKey}
                      checked={formik.values.permissions.includes(permKey)}
                      onChange={onPermissionChange}
                    />
                  </td>
                );
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default CompanyPermissions;
