import { isSameDay } from "date-fns";
import { dateFormat, toArgentinaTime } from "./dates";

export const getDateRangeErrorMessage = (dateFrom: Date, dateTo: Date) => {
  // Caso 1: Las fechas son el mismo día
  if (isSameDay(dateFrom, dateTo)) {
    return `No hay datos para el día ${dateFormat(
      dateFrom,
      "EEEE d 'de' LLLL",
      toArgentinaTime
    )}`;
  }

  // Caso 2: Las fechas son un rango de días
  return `No hay datos para los días comprendidos entre el ${dateFormat(
    dateFrom,
    "EEEE d 'de' LLLL",
    toArgentinaTime
  )} y el ${dateFormat(dateTo, "EEEE d 'de' LLLL", toArgentinaTime)}`;
};
