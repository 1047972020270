import { useLazyQuery, useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import Api from "src/api";
import { AuditRegisterValues } from "src/api/audit";
import { CashRegisterSession } from "src/api/registers";
import { CashRegisterAction, Sale, PaymentMethod } from "src/api/sales";
import { StoreType } from "src/api/stores";
import { dateFormat } from "src/helpers/dates";
import { formatCurrency, rest } from "src/helpers/numbers";
import { findPaymentMethod, findRegisterAction } from "src/helpers/payments";

const AuditValues = (props: AuditRegisterValues) => {
  const { audit, session } = props;

  const totalPayments = Number(
    audit.payments
      .filter(
        (p) =>
          ![PaymentMethod.Discount, PaymentMethod.CustomerDiscount].includes(
            p.type
          )
      )
      .reduce((prev, curr) => prev + curr.amount, 0)
      .toFixed(2)
  );
  const cashPayments =
    audit.payments
      .filter(
        (p) =>
          ![PaymentMethod.Discount, PaymentMethod.CustomerDiscount].includes(
            p.type
          )
      )
      .find((payment) => payment.type)?.amount ?? 0;
  const totalSold = Number(
    (
      audit.sales.spent -
      audit.sales.itemDiscount -
      audit.sales.saleDiscount +
      audit.sales.itemRecharge
    ).toFixed(2)
  );
  const { cashInSales, totalCash } = audit.register.reduce(
    (prev, curr) => {
      if (curr.type === CashRegisterAction.WithdrawMoney) {
        return {
          ...prev,
          totalCash: prev.totalCash - curr.amount,
        };
      }

      if (curr.type === CashRegisterAction.Sell) {
        return {
          ...prev,
          totalCash: prev.totalCash + curr.amount,
          cashInSales: prev.cashInSales + curr.amount,
        };
      }

      return {
        ...prev,
        totalCash: prev.totalCash + curr.amount,
      };
    },
    { totalCash: 0, cashInSales: 0 }
  );

  return (
    <CCol md="8" sm="12" xs="12">
      <CCard>
        <CCardHeader>Números de la sesion #{session.id}</CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="6" className="mb-3">
              <CFormLabel>Pagos</CFormLabel>
              {audit.payments
                .filter(
                  (p) =>
                    ![
                      PaymentMethod.Discount,
                      PaymentMethod.CustomerDiscount,
                    ].includes(p.type)
                )
                .map((payment, index) => (
                  <CRow className="mx-1" key={index}>
                    {findPaymentMethod(payment.type)?.name}:{" "}
                    {formatCurrency(payment.amount)}
                  </CRow>
                ))}
              <CRow className="mx-1">
                Total en Ventas: {formatCurrency(totalPayments)}
              </CRow>
            </CCol>
            <CCol sm="6">
              <CFormLabel>Venta de Productos</CFormLabel>
              <CRow className="mx-1">
                Vendido: {formatCurrency(audit.sales.spent)}
              </CRow>
              <CRow className="mx-1">
                Descontado: {formatCurrency(audit.sales.saleDiscount)}
              </CRow>
              <CRow className="mx-1">Total: {formatCurrency(totalSold)}</CRow>
            </CCol>
          </CRow>
          <hr />

          <CFormLabel>Movimientos Caja Registradora</CFormLabel>
          {audit.register.map((move, index) => (
            <CRow className="mx-1" key={index}>
              {findRegisterAction(move.type)?.name}:{" "}
              {formatCurrency(move.amount)}
            </CRow>
          ))}
          <CRow className="mx-1">
            Total en Caja: {formatCurrency(totalCash)}
          </CRow>

          <hr />
          <CFormLabel>Resumen</CFormLabel>
          <CRow className="mx-1">
            {totalPayments === totalSold
              ? "Pagos correctos ✅"
              : `Discrepancia en pagos ❌ (Pagos: ${formatCurrency(
                  totalPayments
                )} !== Ventas: ${formatCurrency(totalSold)})`}
          </CRow>
          <CRow className="mx-1">
            {cashPayments === cashInSales
              ? "Efectivo por ventas correcto ✅"
              : `Discrepancia en efectivo en relacion a las ventas ❌ (Pagos: ${formatCurrency(
                  cashPayments
                )} !== Ventas: ${formatCurrency(cashInSales)})`}
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

const AuditSaleTable = (props: AuditRegisterValues) => {
  const { sales, payments } = props;

  const salesList = useMemo(() => {
    const mappedSales = new Map(sales.map((sale) => [sale.id, sale]));

    payments.forEach((payment) => {
      const sale = mappedSales.get(payment.saleId);

      if (sale) {
        mappedSales.set(sale.id, {
          ...sale,
          payments: [...(sale.payments ?? []), payment],
        });
      }
    });

    return Array.from(mappedSales.values());
  }, [sales, payments]);

  return (
    <CRow>
      <CCard className="mt-3 mx-2 px-0">
        <CCardHeader>Ventas de la sesion</CCardHeader>
        <CCardBody>
          <CSmartTable
            itemsPerPage={salesList.length}
            items={salesList}
            clickableRows
            onRowClick={(item: Item) => {
              window.open(`/#/sales/${item.id}`, "_blank");
            }}
            columns={[
              { key: "id", label: "ID" },
              { key: "itemsPrice", label: "Precio en Items" },
              { key: "discount", label: "Descuento en Venta" },
              { key: "itemsDiscount", label: "Descuento en Items" },
              { key: "recharge", label: "Recargo en Venta" },
              { key: "payments", label: "Pagos" },
              {
                key: "summary",
                label: "Resumen",
                _props: { className: "text-center" },
              },
            ]}
            scopedColumns={{
              discount: (item: Sale) => (
                <td>{formatCurrency(item.discount)}</td>
              ),
              recharge: (item: Sale) => (
                <td>
                  {formatCurrency(
                    item.items.reduce(
                      (prev, curr) => prev + curr.quantity * curr.recharge,
                      0
                    )
                  )}
                </td>
              ),
              itemsPrice: (item: Sale) => (
                <td>
                  {formatCurrency(
                    item.items.reduce(
                      (prev, curr) => prev + curr.quantity * curr.price,
                      0
                    )
                  )}
                </td>
              ),
              itemsDiscount: (item: Sale) => (
                <td>
                  {formatCurrency(
                    item.items.reduce(
                      (prev, curr) => prev + curr.quantity * curr.discount,
                      0
                    )
                  )}
                </td>
              ),
              payments: (item: Sale) => (
                <td>
                  {formatCurrency(
                    item.payments
                      .filter(
                        (pay) =>
                          ![
                            PaymentMethod.Discount,
                            PaymentMethod.CustomerDiscount,
                          ].includes(pay.type)
                      )
                      .reduce((prev, curr) => prev + curr.amount, 0)
                  )}
                </td>
              ),
              paymentsDiscounts: (item: Sale) => (
                <td>
                  {item.payments.find((pay) =>
                    [
                      PaymentMethod.Discount,
                      PaymentMethod.CustomerDiscount,
                    ].includes(pay.type)
                  )?.amount ?? 0}
                </td>
              ),
              summary: (item: Sale) => {
                const saleDiscount = item.discount;

                const itemsPrice = Number(
                  item.items
                    .reduce(
                      (prev, curr) => prev + curr.quantity * curr.price,
                      0
                    )
                    .toFixed(2)
                );

                const itemsDiscount = Number(
                  item.items
                    .reduce(
                      (prev, curr) => prev + curr.quantity * curr.discount,
                      0
                    )
                    .toFixed(2)
                );

                const itemsRecharge = Number(
                  item.items
                    .reduce(
                      (prev, curr) => prev + curr.quantity * curr.recharge,
                      0
                    )
                    .toFixed(2)
                );

                const payments = item.payments
                  .filter((pay) => pay.type !== PaymentMethod.Discount)
                  .reduce((prev, curr) => prev + curr.amount, 0);

                const calculate =
                  rest(itemsPrice, saleDiscount, itemsDiscount, payments) +
                  itemsRecharge;

                return (
                  <td className="text-center">
                    {calculate === 0 ? "✅" : "❌"}
                  </td>
                );
              },
            }}
          />
        </CCardBody>
      </CCard>
    </CRow>
  );
};

const Audit = () => {
  const [auditValues, setAuditValues] = useState<AuditRegisterValues>();

  const { data: sessions } = useQuery(Api.Sessions.LIST_SESSIONS, {
    variables: {
      filters: {
        type: StoreType.Store,
      },
    },
  });

  const [fetch, { loading }] = useLazyQuery(Api.Audit.GET_AUDIT, {
    onCompleted: ({ auditSession }) => {
      setAuditValues(auditSession);
    },
    onError: () => {
      setAuditValues(undefined);
    },
  });

  const formik = useFormik<{ sessionId: number }>({
    initialValues: {
      sessionId: 0,
    },
    onSubmit: (data) => {
      if (!loading) {
        fetch({
          variables: {
            id: Number(data.sessionId),
          },
        });
      }
    },
  });

  return (
    <CRow>
      <CCol md="4" sm="12" xs="12">
        <CCard>
          <CCardHeader>Auditar Sesion de Caja Registradora</CCardHeader>
          <CCardBody>
            <CFormInput
              label="ID Session"
              placeholder={formik.values.sessionId.toString()}
              type="number"
              onChange={formik.handleChange}
              name="sessionId"
              value={formik.values.sessionId.toString()}
            />
          </CCardBody>
          <CCardFooter className="d-flex justify-content-end">
            <CButton
              color="primary"
              disabled={loading}
              onClick={() => {
                formik.handleSubmit();
              }}
              size="sm"
            >
              Auditar
            </CButton>
          </CCardFooter>
        </CCard>
        {auditValues && (
          <CCard className="mt-3 mb-3">
            <CCardHeader>
              Datos de la sesion #{auditValues.session.id}
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CFormLabel>
                  Punto de Venta: {auditValues.session.register.store.name}
                </CFormLabel>
                <CFormLabel>
                  Caja: {auditValues.session.register.name}
                </CFormLabel>
                <CFormLabel>
                  Abierta el:{" "}
                  {dateFormat(
                    auditValues.session.openedAt,
                    "dd/MM/yyyy 'a las' HH:mm 'hs'"
                  )}
                </CFormLabel>
                <CFormLabel>
                  Abierta por: {auditValues.session.user.name}{" "}
                  {auditValues.session.user.lastname}
                </CFormLabel>
                {auditValues.session.closedAt ? (
                  <CFormLabel>
                    Cerrada el:{" "}
                    {dateFormat(
                      auditValues.session.closedAt,
                      "dd/MM/yyyy 'a las' HH:mm 'hs'"
                    )}
                  </CFormLabel>
                ) : (
                  <CFormLabel>Aun abierta</CFormLabel>
                )}
              </CRow>
            </CCardBody>
          </CCard>
        )}
      </CCol>
      {auditValues ? (
        <>
          <AuditValues {...auditValues} />
          <AuditSaleTable {...auditValues} />
        </>
      ) : (
        <CCol md="8" sm="12" xs="12">
          <CCard>
            <CCardHeader>Ultimas 10 sesiones</CCardHeader>
            <CCardBody>
              <CSmartTable
                items={sessions?.data?.data ?? []}
                columns={[
                  { key: "id", label: "ID Sesión" },
                  { key: "storeId", label: "ID Punto de Venta" },
                  { key: "store", label: "Punto de Venta" },
                  { key: "cashRegister", label: "Caja" },
                  { key: "status", label: "Estado" },
                ]}
                scopedColumns={{
                  store: (item: CashRegisterSession) => (
                    <td>{item.register.store.name}</td>
                  ),
                  storeId: (item: CashRegisterSession) => (
                    <td>{item.register.store.id}</td>
                  ),
                  cashRegister: (item: CashRegisterSession) => (
                    <td>{item.register.name}</td>
                  ),
                  status: (item: CashRegisterSession) => (
                    <td>{item.closedAt === null ? "Abierta" : "Cerrada"}</td>
                  ),
                }}
                clickableRows
                onRowClick={(item: Item) => {
                  formik.setFieldValue("sessionId", item.id);
                  formik.handleSubmit();
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      )}
    </CRow>
  );
};

export default Audit;
