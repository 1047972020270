import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CBadge,
  CButton,
  CCollapse,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CLink,
} from "@coreui/react-pro";

import { dateFormat } from "src/helpers/dates";
import { useAdminStore } from "src/store";
import { useMemo, useState } from "react";
import { AppLoader } from "src/components/Loader/Loader";
import { CompanyPlan, PlanType, StorePlan } from "src/api/plans";
import {
  checkPayments,
  findPlan,
  parseStatus,
  parseStatusBadge,
} from "src/helpers/plans";
import { useQuery } from "@apollo/client";
import Api from "src/api";
import { GraphQLMeta } from "src/types";
import Icon from "src/components/Icon";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { formatCurrency } from "src/helpers/numbers";
import { capitalize } from "lodash";
import { isAfter } from "date-fns/isAfter";
import { isBefore } from "date-fns/isBefore";
import { useCanNavigate } from "src/helpers/permissions";
import Config from "src/config";
import { PaymentStatus } from "src/api/companies";

const PlansList = ({
  plans,
  title,
}: {
  plans: CompanyPlan[];
  title: string;
}) => {
  const { hasPermission } = useAdminStore();
  const [companyPlan, setCompanyPlan] = useState<number>();

  const downloadInvoice = async (storePlan: StorePlan) => {
    try {
      const invoice = await Api.Plans.EXPORT_INVOICE(
        `${storePlan.companyPlanId.toString().padStart(4, "0")}-${storePlan.id
          .toString()
          .padStart(4, "0")}`
      );

      const url = window.URL.createObjectURL(new Blob([invoice.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", invoice.fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {}
  };

  return (
    <CRow className="mb-3">
      <CCol xl={12}>
        <CCard>
          <CCardHeader>{title}</CCardHeader>
          <CCardBody>
            <CSmartTable
              itemsPerPage={plans.length}
              loading={false}
              items={plans}
              columns={[
                { key: "invoice", label: "Comprobante" },
                { key: "status", label: "Estado" },
                {
                  key: "period",
                  label: "Periodo",
                  _props: { className: "text-center" },
                },
                {
                  key: "actions",
                  label: "Acciones",
                  _props: { className: "text-right" },
                },
              ]}
              scopedColumns={{
                invoice: (item: CompanyPlan) => (
                  <td>Comprobante #{item.id.toString().padStart(4, "0")}</td>
                ),
                status: (item: CompanyPlan) => {
                  const stores = item.stores.filter((storePlan: StorePlan) =>
                    hasPermission("SHOW_PLAN", {
                      type: "store",
                      id: storePlan.store.id,
                    })
                  );
                  const totalAmount = stores.reduce(
                    (p, c) => p + (c.price - c.discount),
                    0
                  );
                  const status =
                    (totalAmount === 0 &&
                      item.status === PaymentStatus.PENDING) ||
                    checkPayments(stores)
                      ? isBefore(new Date(), item.dateFrom)
                        ? item.status
                        : PaymentStatus.PAID
                      : item.status;

                  return (
                    <td>
                      <CBadge
                        color={parseStatusBadge(
                          status,
                          item.dateFrom,
                          item.dateTo
                        )}
                      >
                        {parseStatus(
                          status,
                          item.dateFrom,
                          item.dateTo
                        ).toUpperCase()}
                      </CBadge>
                    </td>
                  );
                },
                period: (item: CompanyPlan) => (
                  <td className="text-center">
                    {capitalize(dateFormat(item.dateTo, "MMMM ''yy"))}
                  </td>
                ),
                actions: (item: CompanyPlan) => (
                  <td className="text-right">
                    <CButton
                      size="sm"
                      variant="ghost"
                      color="secondary"
                      onClick={() =>
                        setCompanyPlan((prev) =>
                          prev === item.id ? undefined : item.id
                        )
                      }
                    >
                      <Icon
                        name={
                          companyPlan === item.id
                            ? "chevron-up"
                            : "chevron-down"
                        }
                        color="black"
                      />
                    </CButton>
                  </td>
                ),
                details: (item: Item) => (
                  <CCollapse visible={companyPlan === item.id}>
                    <CTable>
                      <CTableHead>
                        <CTableRow>
                          <CTableHeaderCell scope="col">#</CTableHeaderCell>
                          <CTableHeaderCell scope="col">
                            Producto
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col">
                            Punto de Venta
                          </CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Precio
                          </CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Descuento
                          </CTableHeaderCell>
                          <CTableHeaderCell className="text-center">
                            Subtotal
                          </CTableHeaderCell>
                          <CTableHeaderCell scope="col" className="text-center">
                            Estado de Pago
                          </CTableHeaderCell>
                          {![PlanType.Free, PlanType.Trial].includes(
                            (item as CompanyPlan).type
                          ) && (
                            <CTableHeaderCell
                              scope="col"
                              className="text-right"
                            >
                              Acciones
                            </CTableHeaderCell>
                          )}
                        </CTableRow>
                      </CTableHead>
                      <CTableBody>
                        {item.stores
                          .filter((storePlan: StorePlan) =>
                            hasPermission("SHOW_PLAN", {
                              type: "store",
                              id: storePlan.store.id,
                              force: true,
                            })
                          )
                          .map((storePlan: StorePlan) => {
                            const currentType = findPlan(storePlan);
                            const StoreIcon = () => currentType?.icon;
                            const payable =
                              storePlan.price - storePlan.discount > 0;
                            const storeStatus =
                              (!payable &&
                                item.status === PaymentStatus.PENDING) ||
                              storePlan.payedAt
                                ? PaymentStatus.PAID
                                : item.status;

                            return (
                              <CTableRow key={storePlan.id}>
                                <CTableDataCell>
                                  {item.id.toString().padStart(4, "0")}-
                                  {storePlan.id.toString().padStart(4, "0")}
                                </CTableDataCell>
                                <CTableDataCell>
                                  <div className="d-flex">
                                    <StoreIcon />
                                    <span className="ml-2">
                                      {currentType?.label}
                                    </span>
                                  </div>
                                </CTableDataCell>
                                <CTableDataCell>
                                  {storePlan.store.name}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {formatCurrency(storePlan.price)}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {formatCurrency(storePlan.discount * -1)}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  {formatCurrency(
                                    storePlan.price - storePlan.discount
                                  )}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                  <CBadge
                                    color={parseStatusBadge(
                                      storeStatus,
                                      storePlan.dateFrom,
                                      storePlan.dateTo
                                    )}
                                  >
                                    {parseStatus(
                                      storeStatus,
                                      storePlan.dateFrom,
                                      storePlan.dateTo,
                                      true
                                    )}
                                    {storePlan.payedAt &&
                                      ` el ${dateFormat(
                                        storePlan.payedAt,
                                        "dd/MM/yy"
                                      )}`}
                                  </CBadge>
                                </CTableDataCell>
                                {![PlanType.Free, PlanType.Trial].includes(
                                  (item as CompanyPlan).type
                                ) && (
                                  <CTableDataCell className="text-right">
                                    {payable ? (
                                      <>
                                        {!storePlan.payedAt ? (
                                          <>
                                            {storePlan.paymentLink && (
                                              <CButton
                                                size="sm"
                                                color="success"
                                              >
                                                <CLink
                                                  className="text-white"
                                                  target="_blank"
                                                  href={storePlan.paymentLink}
                                                >
                                                  Pagar
                                                </CLink>
                                              </CButton>
                                            )}
                                            <CButton
                                              size="sm"
                                              color="success"
                                              className="ml-2"
                                            >
                                              <CLink
                                                className="text-white"
                                                target="_blank"
                                                href={`${
                                                  Config.whatsAppUrl
                                                }&text=Hola, queria avisar del pago del plan de Daruma ${item.id
                                                  .toString()
                                                  .padStart(
                                                    4,
                                                    "0"
                                                  )}-${storePlan.id
                                                  .toString()
                                                  .padStart(4, "0")}`}
                                              >
                                                Aviso de Pago
                                              </CLink>
                                            </CButton>
                                          </>
                                        ) : (
                                          <CButton
                                            color="primary"
                                            size="sm"
                                            onClick={() => {
                                              downloadInvoice(storePlan);
                                            }}
                                          >
                                            Descargar Comprobante
                                          </CButton>
                                        )}
                                      </>
                                    ) : (
                                      <span>Sin acciones</span>
                                    )}
                                  </CTableDataCell>
                                )}
                              </CTableRow>
                            );
                          })}
                        <CTableRow>
                          <CTableDataCell colSpan={4} />
                          <CTableDataCell className="text-center">
                            Resta pagar:
                          </CTableDataCell>
                          <CTableDataCell className="text-center">
                            {formatCurrency(
                              item.stores
                                .filter((storePlan: StorePlan) =>
                                  hasPermission("SHOW_PLAN", {
                                    type: "store",
                                    id: storePlan.store.id,
                                    force: true,
                                  })
                                )
                                .reduce(
                                  (p: number, c: StorePlan) =>
                                    p + (!c.payedAt ? c.price - c.discount : 0),
                                  0
                                )
                            )}
                          </CTableDataCell>
                          <CTableDataCell className="text-center"></CTableDataCell>
                          {![PlanType.Free, PlanType.Trial].includes(
                            (item as CompanyPlan).type
                          ) && (
                            <CTableDataCell className="text-right">
                              {item.stores
                                .filter((storePlan: StorePlan) =>
                                  hasPermission("SHOW_PLAN", {
                                    type: "store",
                                    id: storePlan.store.id,
                                    force: true,
                                  })
                                )
                                .reduce(
                                  (p: number, c: StorePlan) =>
                                    p + (!c.payedAt ? c.price - c.discount : 0),
                                  0
                                ) > 0 && (
                                <CButton
                                  size="sm"
                                  color="primary"
                                  className="ml-2"
                                >
                                  <CLink
                                    className="text-white"
                                    target="_blank"
                                    href={`${
                                      Config.whatsAppUrl
                                    }&text=Hola, queria avisar del pago total del plan de Daruma ${item.id
                                      .toString()
                                      .padStart(4, "0")}`}
                                  >
                                    Aviso de Pago Total
                                  </CLink>
                                </CButton>
                              )}
                            </CTableDataCell>
                          )}
                        </CTableRow>
                      </CTableBody>
                    </CTable>
                  </CCollapse>
                ),
              }}
              tableProps={{
                striped: true,
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

const PlanScreen = () => {
  const { currentCompany } = useAdminStore();

  useCanNavigate("UPDATE_COMPANY", { force: true });

  const { data: plan } = useQuery<GraphQLMeta<CompanyPlan>>(
    Api.Plans.LIST_COMPANY_PLANS,
    {
      skip: !currentCompany?.id,
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId: currentCompany?.id,
        },
      },
    }
  );

  const { pastInvoices, activeInvoices } = useMemo(() => {
    if (!plan?.data.data) {
      return {
        pastInvoices: [],
        activeInvoices: [],
      };
    }

    const planList = plan.data.data;
    const today = new Date();

    return {
      pastInvoices: planList.filter((companyPlan: CompanyPlan) =>
        isAfter(today, new Date(companyPlan.dateTo))
      ),
      activeInvoices: planList
        .filter((companyPlan: CompanyPlan) => {
          const isAfterFrom = isAfter(today, new Date(companyPlan.dateFrom));
          const isBeforeTo = isBefore(today, new Date(companyPlan.dateTo));
          const isBeforeFrom = isBefore(today, new Date(companyPlan.dateFrom));

          return (isAfterFrom && isBeforeTo) || isBeforeFrom;
        })
        .reverse(),
    };
  }, [plan?.data]);

  if (!currentCompany) {
    return <AppLoader />;
  }

  return (
    <>
      <PlansList plans={activeInvoices} title="Planes Activos" />

      <PlansList plans={pastInvoices} title="Planes Pasados" />
    </>
  );
};

export default PlanScreen;
