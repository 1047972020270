import { FC } from "react";
import * as HIcons from "@heroicons/react/24/outline";
import { pascalCase } from "src/helpers/strings";
import { Generic } from "src/types";

const Icon: FC<{
  name: string;
  style?: Generic;
  color?: string;
  containerClassName?: string;
  className?: string;
}> = (props) => {
  const { ...icons } = HIcons;
  const iconName = `${pascalCase(props.name)
    .replaceAll(" ", "")
    .replaceAll("-", "")}Icon`;

  // @ts-ignore
  const TheIcon: JSX.Element = icons[iconName];

  if (!TheIcon) {
    console.warn(`The icon ${iconName} does not exist`);
    return null;
  }

  const textColor = `text-${props.color ?? "white"}`;

  return (
    <>
      <div
        className={`h-6 w-6 ${props.containerClassName ?? ""}`}
        style={props.style}
      >
        {/* @ts-ignore */}
        <TheIcon
          className={`flex h-6 w-6 ${textColor} ${props.className ?? ""}`}
          aria-hidden="true"
        />
      </div>
    </>
  );
};

export default Icon;
