import { StoreType } from "src/api/stores";
import Icon, {
  MercadoShops,
  MercadoLibre,
  TiendaNube,
  Rappi,
} from "src/components/Icon";

export const parsePointOfSaleType = (type: StoreType) =>
  findPointOfSale(type)?.label ?? "";

export const findPointOfSale = (type: StoreType) =>
  storeTypes.find((t) => t.id === type);

export const storeTypes = [
  {
    id: StoreType.Store,
    label: "Tienda Fisica",
    icon: <Icon color="black" name="building-storefront" />,
  },
  {
    id: StoreType.Digital,
    label: "Tienda Digital",
    icon: <Icon color="black" name="globe-alt" />,
  },
  {
    id: StoreType.TiendaNube,
    label: "Tienda Nube",
    icon: <TiendaNube size={24} />,
  },
  {
    id: StoreType.MercadoLibre,
    label: "Mercado Libre",
    icon: <MercadoLibre size={24} />,
  },
  {
    id: StoreType.MercadoShops,
    label: "Mercado Shops",
    icon: <MercadoShops size={24} />,
  },
  {
    id: StoreType.Rappi,
    label: "Rappi",
    icon: <Rappi size={24} />,
  },
];
