import { useQuery } from "@apollo/client";
import Api from "src/api";
import { AfipStatus } from "src/api/afip";
import { AppLoader } from "src/components/Loader/Loader";
import { useAdminStore } from "src/store";
import { GraphQLFind } from "src/types";
import AfipError from "./components/AfipError";
import UnavailableStatus from "./components/UnavailableStatus";
import NotConnectedStatus from "./components/NotConnectedStatus";
import AfipConnected from "./components/AfipConnected";
import Franchise from "./components/Franchise";
import { StoreType } from "src/api/stores";
import { CButton, CCol, CRow } from "@coreui/react-pro";
import { Link } from "react-router-dom";

const Invoicing = () => {
  const { currentCompany } = useAdminStore();
  const { data: connection } = useQuery<GraphQLFind<AfipStatus[]>>(
    Api.Afip.GET_STATUS,
    {
      skip: !currentCompany?.id,
      fetchPolicy: "no-cache",
      variables: {
        companyId: currentCompany?.id,
      },
    }
  );

  const isFranchise = connection?.data?.[0].isFranchise ?? false;

  if (!connection?.data || !currentCompany) {
    return <AppLoader />;
  }

  if (isFranchise) {
    return <Franchise connection={connection.data} />;
  }

  const companyConnection = connection?.data[0];

  if (companyConnection?.status === "NOT_CONNECTED" && currentCompany.id) {
    return <NotConnectedStatus companyId={currentCompany.id} />;
  }

  if (companyConnection?.status === "UNAVAILABLE") {
    return <UnavailableStatus />;
  }

  const isAfipDown =
    companyConnection?.app !== "OK" ||
    companyConnection?.auth !== "OK" ||
    companyConnection?.db !== "OK";

  if (isAfipDown) {
    return <AfipError />;
  }

  return (
    <>
      <CRow className="mb-2">
        <CCol className="d-flex justify-content-end">
          <Link to="/invoicing/certificates">
            <CButton size="sm" color="primary">
              Certificados
            </CButton>
          </Link>
        </CCol>
      </CRow>
      <AfipConnected
        companyId={currentCompany.id}
        type={StoreType.Store}
        services={companyConnection.services ?? []}
      />
    </>
  );
};

export default Invoicing;
