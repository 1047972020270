import { gql } from "@apollo/client";
import request from "./request";
import { Customer } from "./customers";
import { Nullable } from "src/types";
import { ElectronicInvoice } from "./afip";
import { Store } from "./stores";
import { PriceListItem } from "./price-lists";

export enum TiendaNubeOrderStatus {
  Open = "open",
  Closed = "closed",
  Cancelled = "cancelled",
}

export type TiendaNubeProduct = {
  variantId: number;

  id: number;
  name: string;
  sku: string;
  cost: number;
  price: number;
  variants: {
    price: number;
  }[];

  url: string;

  darumaId: string;
  darumaName: string;
  darumaSku: string;

  priceListItem: PriceListItem;
};

export type TiendaNubeOrderItem = {
  id: number;
  name: string;
  price: number;
  productId: number;
  quantity: number;
  variantId: number;
  sku: string;
  cost: number;
};

export type TiendaNubeWebhook = {
  id: number;
  event: string;
  url: string;
};

export type TiendaNubeOrder = {
  id: number;
  number: number;
  status: TiendaNubeOrderStatus;
  items: TiendaNubeOrderItem[];
  summary: {
    discount: number;
    subtotal: number;
  };
  customer: Customer;
  date: Date;
  createdAt: Date;
  updatedAt: Date;
  store: Store;
  invoices: ElectronicInvoice[];
};

export type TiendaNubeOrderCheck = {
  customer: {
    tiendaNubeCustomerId: number;
    customerId: Nullable<number>;
    connected: boolean;
    downloaded: boolean;
  };
  sale: {
    tiendaNubeOrderId: number;
    saleId: Nullable<number>;
    connected: boolean;
    downloaded: boolean;
    downloadable: boolean;
  };
  items: {
    tiendaNubeVariantId: Nullable<number>;
    tiendaNubeProductId: Nullable<number>;
    productId: number;
    itemId: Nullable<number>;
    connected: boolean;
    downloaded: boolean;
  }[];
};

export const LIST_PRODUCTS = gql`
  query tiendaNubeProducts($filters: TiendaNubeProductsFilters) {
    data: tiendaNubeProducts(filters: $filters) {
      data {
        id
        name
        sku
        cost
        price

        variantId
        url

        priceListItem {
          id
          productId

          product {
            sku
            name
            cost
            price
          }
        }

        darumaId
        darumaName
        darumaSku
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const CONNECT_PRODUCT = gql`
  mutation connectProduct(
    $darumaProductId: Int!
    $tiendaNubeProductId: Int!
    $tiendaNubeSKU: String
  ) {
    data: connectProduct(
      darumaProductId: $darumaProductId
      tiendaNubeProductId: $tiendaNubeProductId
      tiendaNubeSKU: $tiendaNubeSKU
    ) {
      id
      name
      sku
    }
  }
`;

export const DOWNLOAD_PRODUCTS = gql`
  mutation downloadTiendaNubeProducts($priceListId: Int!) {
    data: downloadTiendaNubeProducts(priceListId: $priceListId) {
      data {
        id
        name
        price
        sku
      }
      meta {
        lastPage
      }
    }
  }
`;

export const DOWNLOAD_CUSTOMERS = gql`
  mutation downloadCustomers($storeId: Int!) {
    data: downloadCustomers(storeId: $storeId) {
      data {
        id
      }
      meta {
        lastPage
      }
    }
  }
`;

export const LIST_SALES = gql`
  query getTiendaNubeOrders($filters: TiendaNubeOrdersFilters!) {
    data: getTiendaNubeOrders(filters: $filters) {
      data {
        id
        number
        date
        status
        customer {
          name
        }
        summary {
          subtotal
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_SALE = gql`
  query getTiendaNubeOrder($storeId: Int!, $orderId: Int!) {
    data: getTiendaNubeOrder(storeId: $storeId, orderId: $orderId) {
      id
      number
      date
      status
      customer {
        id
        name
        createdAt
      }
      items {
        productId
        variantId
        quantity
        name
        price
      }
      summary {
        discount
        subtotal
      }
      store {
        id
        name
        companyId

        config {
          invoiceTypes {
            id
            name
            anonymous
          }
        }
      }
      invoices {
        utid
        cae
        createdAt

        voucher {
          dniNumber
          total

          document {
            id
            name
          }

          pointOfSale {
            id
            stringType
          }

          voucher {
            name
          }

          seller {
            legalName
            address {
              streetName
              streetNumber
              zipCode
              province
            }
            startDate
          }

          payer {
            legalName
          }

          qr
        }
      }
    }
  }
`;

export const CHECK_SALE = gql`
  query checkTiendaNubeOrder($storeId: Int!, $orderId: Int!) {
    data: checkTiendaNubeOrder(storeId: $storeId, orderId: $orderId) {
      customer {
        tiendaNubeCustomerId
        customerId
        connected
        downloaded
      }
      sale {
        tiendaNubeOrderId
        saleId
        connected
        downloaded
        downloadable
      }
      items {
        tiendaNubeProductId
        tiendaNubeVariantId
        productId
        itemId
        connected
        downloaded
      }
    }
  }
`;

export const DOWNLOAD_ORDER = gql`
  mutation downloadOrder($storeId: Int!, $orderId: Int!) {
    data: downloadOrder(storeId: $storeId, orderId: $orderId) {
      id
    }
  }
`;

export const DOWNLOAD_IMAGES = gql`
  mutation downloadTiendaNubeImages($storeId: Int!) {
    data: downloadTiendaNubeImages(storeId: $storeId) {
      data {
        id
      }
    }
  }
`;

export const SYNC_PRODUCT = gql`
  mutation syncTiendaNubeProduct($itemId: Int!) {
    data: syncTiendaNubeProduct(itemId: $itemId) {
      id
    }
  }
`;

export const SYNC_PRODUCTS = gql`
  mutation syncTiendaNubeProducts($itemIds: [Int!]!) {
    data: syncTiendaNubeProducts(itemIds: $itemIds) {
      id
    }
  }
`;

export const connectStore = (code: string) =>
  request.post(`/tienda-nube/connect`, { code });

export const LIST_WEBHOOKS = gql`
  query tiendaNubeWebhooks($filters: TiendaNubeOrdersFilters) {
    data: tiendaNubeWebhooks(filters: $filters) {
      data {
        id
        event
        url
        store {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_WEBHOOKS = gql`
  mutation createTiendaNubeWebhooks($input: TiendaNubeWebhookInput!) {
    data: createTiendaNubeWebhooks(input: $input) {
      data {
        id
        event
        url
      }
    }
  }
`;

export const DELETE_WEBHOOK = gql`
  mutation deleteTiendaNubeWebhook($storeId: Int!, $id: Int!) {
    data: deleteTiendaNubeWebhook(storeId: $storeId, id: $id) {
      id
    }
  }
`;

export const UPDATE_SKU_FROM_TIENDA_NUBE = gql`
  mutation updateProductSKUFromTiendaNube($productId: Int!, $sku: String!) {
    data: updateProductSKUFromTiendaNube(productId: $productId, sku: $sku) {
      id
    }
  }
`;

export const UPDATE_SKUS_FROM_TIENDA_NUBE = gql`
  mutation updateProductsSKUFromTiendaNube(
    $products: [TiendaNubeProductSkuInput!]!
  ) {
    data: updateProductsSKUFromTiendaNube(products: $products) {
      id
    }
  }
`;

export const UPDATE_SKU_FROM_DARUMA = gql`
  mutation updateProductSKUFromDaruma(
    $productId: Int!
    $variantId: Int!
    $storeId: Int!
    $sku: String!
  ) {
    data: updateProductSKUFromDaruma(
      productId: $productId
      sku: $sku
      variantId: $variantId
      storeId: $storeId
    ) {
      id
    }
  }
`;

export const UPDATE_SKUS_FROM_DARUMA = gql`
  mutation updateProductsSKUFromDaruma(
    $storeId: Int!
    $products: [DarumaProductSkuInput!]!
  ) {
    data: updateProductsSKUFromDaruma(storeId: $storeId, products: $products) {
      id
    }
  }
`;

export const DOWNLOAD_TIENDA_NUBE_PRODUCT = gql`
  mutation downloadTiendaNubeProduct(
    $storeId: Int!
    $productId: Int!
    $variantId: Int!
  ) {
    data: downloadTiendaNubeProduct(
      storeId: $storeId
      productId: $productId
      variantId: $variantId
    ) {
      id
    }
  }
`;

export const DISCONNECT_STORE = gql`
  mutation disconnectTiendaNubeStore($storeId: Int!) {
    data: disconnectTiendaNubeStore(storeId: $storeId) {
      id
    }
  }
`;
