import { gql } from "@apollo/client";

export const SELLS_BY_CATEGORY_AND_DATE = gql`
  query sellsByCategoriesAndDate($filters: DateAndStoreFilters!) {
    data: sellsByCategoriesAndDate(filters: $filters) {
      category {
        id
        name
      }
      items {
        product {
          id
          name
        }
        quantity
        cost
        price
      }
    }
  }
`;
