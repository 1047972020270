import yup from "src/helpers/validation";
import { gql } from "@apollo/client";
import { Company } from "./companies";
import request from "./request";
import { Product } from "./products";

export const CategorySchema = yup.object().shape({
  name: yup.string().required(),
  companyId: yup.string().integer().required(),
  identifier: yup.string().required(),
});

export type Category = {
  id: number;
  name: string;
  identifier: string;
  notes: string;
  companyId: number;
  company: Company;
  products: Product[];
};

export type CreateCategory = Omit<Category, "id" | "company" | "products">;

export type UpdateCategory = CreateCategory;

export const LIST_CATEGORIES = gql`
  query getCategories($filters: CategoryFilters) {
    data: categories(filters: $filters) {
      data {
        id
        name
        identifier
        notes
        company {
          id
          name
        }
        products {
          id
          deletedAt
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query getCategory($id: Int!) {
    data: category(id: $id) {
      id
      name
      identifier
      notes
      company {
        id
        name
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $companyId: Int!
    $name: String!
    $identifier: String!
    $notes: String
  ) {
    data: createCategory(
      companyId: $companyId
      name: $name
      identifier: $identifier
      notes: $notes
    ) {
      id
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $id: Int!
    $companyId: Int!
    $name: String!
    $identifier: String!
    $notes: String
  ) {
    data: updateCategory(
      id: $id
      companyId: $companyId
      name: $name
      identifier: $identifier
      notes: $notes
    ) {
      id
    }
  }
`;

export const UPDATE_PRODUCTS = gql`
  mutation updateCategoryProductsConfig(
    $categoryId: Int!
    $config: ProductsConfigInput!
  ) {
    data: updateCategoryProductsConfig(
      categoryId: $categoryId
      config: $config
    ) {
      id
    }
  }
`;

export const downloadStockReport = (categoryId: number) => {
  return request.post(
    `/stock/${categoryId}`,
    {},
    {
      responseType: "blob",
    }
  );
};
