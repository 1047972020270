import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react-pro";
import {
  cilSettings,
  cilAccountLogout,
  cilPeople,
  cilUser,
  cilPhone,
  cilListNumbered,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import Icon from "src/components/Icon";
import { AdminStore, useAdminStore } from "src/store";
import { logEvent } from "src/helpers/analytics";
import { useMutation } from "@apollo/client";
import Api from "src/api";
import Config from "src/config";

const AppHeaderDropdown = () => {
  const { user, currentCompany } = useAdminStore();

  const [createMutation, { loading }] = useMutation(
    Api.Support.CREATE_REQUEST,
    {
      onCompleted: ({ data }) => {
        logEvent("create_support_request.success", {
          id: data.id,
          companyId: currentCompany?.id,
          reporterId: user?.id,
          origin: "ADMIN",
          currentState: AdminStore.getState(),
        });

        openLink(
          data.id,
          `*Incidencia ${data.id.toString().padStart(6, "0")}.* `
        );
      },
      onError: () => {
        logEvent("create_support_request.error", {
          companyId: currentCompany?.id,
          reporterId: user?.id,
          origin: "ADMIN",
          currentState: AdminStore.getState(),
        });

        openLink();
      },
    }
  );

  const openLink = (requestId?: number, prefix?: string) => {
    const message = `${prefix}Hola, soy _${user?.name} ${user?.lastname} (${user?.id})_ y te hablo desde ${currentCompany?.name}.`;

    logEvent("create_support_request.redirect", {
      message,
      requestId,
      companyId: currentCompany?.id,
      reporterId: user?.id,
      origin: "ADMIN",
      currentState: AdminStore.getState(),
    });

    window.open(`${Config.whatsAppUrl}&text=${message}`, "_blank");
  };

  const createSupportRequest = () => {
    if (!loading) {
      createMutation({
        variables: {
          input: {
            companyId: currentCompany?.id,
            reporterId: user?.id,
            origin: "ADMIN",
          },
        },
      });
    }
  };

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle className="py-0" caret={false}>
        <Icon name="user" color="black" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-body-secondary fw-semibold py-2 rounded-top">
          {user?.name} {user?.lastname}
        </CDropdownHeader>
        <CDropdownItem href="/#/me">
          <CIcon icon={cilUser} className="me-2" />
          Mi perfil
        </CDropdownItem>
        {!user?.isAdmin ? (
          <CDropdownItem href="/#/users">
            <CIcon icon={cilPeople} className="me-2" />
            Empleados
          </CDropdownItem>
        ) : (
          <CDropdownItem href="/#/versions">
            <CIcon icon={cilListNumbered} className="me-2" />
            Versiones
          </CDropdownItem>
        )}
        <CDropdownDivider />
        {!user?.isAdmin && (
          <CDropdownItem href="/#/settings">
            <CIcon icon={cilSettings} className="me-2" />
            Configuraciones
          </CDropdownItem>
        )}
        {!user?.isAdmin ? (
          <CDropdownItem
            onClick={() => {
              createSupportRequest();
            }}
          >
            <CIcon icon={cilPhone} className="me-2" />
            Atención al Cliente
          </CDropdownItem>
        ) : (
          <CDropdownItem href="/#/support">
            <CIcon icon={cilPhone} className="me-2" />
            Incidencias
          </CDropdownItem>
        )}
        <CDropdownItem href="/#/logout">
          <CIcon icon={cilAccountLogout} className="me-2" />
          Salir
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
