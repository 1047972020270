import { useLazyQuery, useQuery } from "@apollo/client";
import { CChart } from "@coreui/react-chartjs";
import { CCol, CFormLabel, CRow } from "@coreui/react-pro";
import { useMemo, useState } from "react";
import Api from "src/api";
import { Product } from "src/api/products";
import { AppLoader } from "src/components/Loader/Loader";
import SmartSelect from "src/components/SmartSelect";
import { dateFormat } from "src/helpers/dates";
import { GraphQLFind, GraphQLMeta } from "src/types";

type ProductSale = {
  quantity: number;
  invoiced: number;
  profit: number;
  date: string;
};

const ProductSalesReport = ({ companyId }: { companyId: number }) => {
  const [productSales, setProductSales] = useState<ProductSale[]>();
  const [monthLabels, setMonthLabels] = useState<string[]>([]);

  const { data: productsList } = useQuery<GraphQLMeta<Product>>(
    Api.Products.LIST_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          companyId,
          trashed: 0,
          limit: 0,
        },
      },
    }
  );

  const [fetchProductSales] = useLazyQuery<GraphQLFind<ProductSale[]>>(
    Api.Reports.PRODUCTS_SALES,
    {
      onCompleted: ({ data }) => {
        setProductSales(data);
        setMonthLabels(data.map((sale) => dateFormat(sale.date, "dd/MM/yy")));
      },
    }
  );

  const products = useMemo(() => {
    if (!productsList?.data.data) {
      return [];
    }

    return productsList.data.data.map((product) => ({
      name: `${product.name} - ${product.barcodes
        .map(({ barcode }) => barcode)
        .join(", ")}`,
      value: product.id,
    }));
  }, [productsList]);

  if (!productsList?.data.data) {
    return <AppLoader />;
  }

  return (
    <>
      <CRow>
        <CCol className="product-search" sm={6}>
          <CFormLabel>Producto</CFormLabel>
          <SmartSelect
            name="productId"
            search
            placeholder="Selecciona un producto"
            options={products}
            onChange={(e) => {
              fetchProductSales({
                variables: {
                  productId: e,
                },
              });
            }}
          />
        </CCol>
      </CRow>
      {productSales && (
        <CRow className="mt-3">
          <CCol sm={6}>
            <CChart
              height={120}
              data={{
                labels: monthLabels,
                datasets: [
                  {
                    label: "Cantidades Vendidas",
                    backgroundColor: "#f87979",
                    data: productSales.map((sale) => sale.quantity),
                  },
                ],
              }}
              type="line"
              wrapper
            />
          </CCol>
          <CCol sm={6}>
            <CChart
              height={120}
              data={{
                labels: monthLabels,
                datasets: [
                  {
                    label: "Ganancias",
                    backgroundColor: "#418ba8",
                    data: productSales.map((sale) => sale.profit),
                  },
                  {
                    label: "Facturado",
                    backgroundColor: "#49ad65",
                    data: productSales.map((sale) => sale.invoiced),
                  },
                ],
              }}
              type="line"
              wrapper
            />
          </CCol>
        </CRow>
      )}
    </>
  );
};

export default ProductSalesReport;
