import { Link, useNavigate } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
  CButton,
} from "@coreui/react-pro";

import { useQuery } from "@apollo/client";

import { usePagination } from "src/hooks/pagination";
import { GraphQLMeta } from "src/types";
import Api from "src/api";
import { Company } from "src/api/companies";
import Pagination from "src/components/Pagination";

const CompaniesScreen = () => {
  const navigate = useNavigate();
  const { page, pageChange } = usePagination("companies");
  const { data: companies, loading } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
        },
      },
    }
  );

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow className="align-items-center justify-content-center">
              <CCol sm={6} xs="6" className="px-0">
                Empresas
              </CCol>
              <CCol sm={6} xs="6" className="row justify-content-end">
                <Link
                  to="/companies/new"
                  className="flex justify-content-end p-0"
                >
                  <CButton color="primary" size="sm">
                    Crear empresa
                  </CButton>
                </Link>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CSmartTable
              itemsPerPage={20}
              loading={loading}
              items={companies?.data.data || []}
              columns={[
                { key: "id", label: "ID" },
                { key: "description", label: "Descripción" },
                {
                  key: "name",
                  label: "Nombre",
                  _props: { className: "font-weight-bold" },
                },
                { key: "legalName", label: "Razón Social" },
                { key: "address", label: "Dirección" },
              ]}
              scopedColumns={{
                address: (item: Company) => (
                  <td>
                    {item.streetName} {item.streetNumber}
                  </td>
                ),
                description: (item: Company) => <td>{item.description}</td>,
              }}
              tableProps={{
                striped: true,
                hover: true,
              }}
              clickableRows
              onRowClick={(item) => navigate(`/companies/${item.id}`)}
            />

            <Pagination meta={companies} page={page} pageChange={pageChange} />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default CompaniesScreen;
