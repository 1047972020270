import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
  CFormSelect,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import { Store } from "src/api/stores";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Api from "src/api";
import { useAdminStore } from "src/store";
import { AppLoader } from "src/components/Loader/Loader";

const NewStore = () => {
  const { currentCompany } = useAdminStore();
  const { data: stores } = useQuery(Api.Stores.LIST_STORES, {
    skip: !currentCompany?.id,
    variables: {
      filters: {
        companyId: currentCompany?.id,
      },
    },
  });

  if (!stores?.data.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form stores={stores.data.data} />
    </CCol>
  );
};

const Form = ({ stores }: { stores: Store[] }) => {
  const navigate = useNavigate();

  const [mutation, { loading }] = useMutation(Api.MercadoPago.LINK_STORE, {
    onCompleted: () => {
      navigate(`/mercado-pago`);
    },
  });

  const formik = useFormik<any>({
    initialValues: {
      storeId: 0,
      name: "",
    },
    onSubmit: (data) => {
      if (!loading) {
        mutation({
          variables: {
            input: {
              storeId: data.storeId,
            },
          },
        });
      }
    },
  });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>Nuevo Punto de Venta</CCardHeader>
        <CCardBody>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel>Punto de Venta</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormSelect
                id="storeId"
                name="storeId"
                defaultValue={formik.values.storeId}
                onChange={formik.handleChange}
              >
                <option key="0" value="0">
                  Seleccione Punto de Venta
                </option>
                {stores.map((store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </CRow>
          <CRow className="form-row">
            <CCol md="3">
              <CFormLabel htmlFor="name">Nombre</CFormLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CFormInput
                id="name"
                placeholder="Nombre"
                name="name"
                onChange={formik.handleChange}
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow className="px-0 align-items-center">
            <CCol md="6"></CCol>
            <CCol md="6" className="flex justify-content-end">
              <CButton
                color="primary"
                size="sm"
                disabled={loading || !formik.isValid}
                type="submit"
              >
                Crear
              </CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CForm>
  );
};

export default NewStore;
