import { gql } from "@apollo/client";
import { Nullable } from "src/types";
import { ElectronicInvoice } from "./afip";
import { Store } from "./stores";

export type MercadoLibreSaleItem = {
  itemId: string;
  name: string;
  price: number;
  quantity: number;
  fee: number;
};

export type MercadoLibreProduct = {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  url: string;
  images: string[];
  sku: string;
  price: number;
  stock: number;
  channels: string[];
  isCatalog: boolean;
  catalogId: string;
};

export enum MercadoLibreStatus {
  Paid = "paid",
  Approved = "approved",
  Rejected = "rejected",
}

export type MercadoLibreSalePayment = {
  id: number;
  name: string;
  amounts: {
    paid: number;
    total: number;
    shipping: number;
    taxes: number;
    transaction: number;
  };
  paymentMethod: {
    id: string;
    name: string;
  };
};

export type MercadoLibreOrderCheck = {
  customer: {
    mercadoLibreCustomerId: number;
    customerId: Nullable<number>;
    connected: boolean;
    downloaded: boolean;
  };
  sale: {
    mercadoLibreOrderId: number;
    saleId: Nullable<number>;
    connected: boolean;
    downloaded: boolean;
    downloadable: boolean;
  };
  items: {
    mercadoLibreProductId: Nullable<number>;
    productId: number;
    itemId: Nullable<number>;
    connected: boolean;
    downloaded: boolean;
  }[];
};

export type MercadoLibreSale = {
  id: number;
  status: MercadoLibreStatus;
  customer: {
    id: number;
    username: string;
    url: string;
  };
  amounts: {
    paid: number;
    total: number;
    shipping: number;
    fee: number;
    transaction: number;
  };
  closedAt: Date;
  payments: MercadoLibreSalePayment[];
  items: MercadoLibreSaleItem[];
  store: Store;
  invoices: ElectronicInvoice[];
};

export const DOWNLOAD_MP_PRODUCTS = gql`
  mutation downloadMercadoLibreProducts($priceListId: Int!) {
    data: downloadMercadoLibreProducts(priceListId: $priceListId) {
      data {
        id
        name
        price
        sku
      }
      meta {
        lastPage
      }
    }
  }
`;

export const LIST_ML_SALES = gql`
  query mercadoLibreSales($filters: MercadoLibreSalesFilters!) {
    data: mercadoLibreSales(filters: $filters) {
      data {
        id
        status
        customer {
          id
          username
          url
        }
        amounts {
          paid
          total
          shipping
        }
        closedAt
        payments {
          id
          name
          amounts {
            paid
            total
            shipping
          }
        }
        items {
          itemId
          name
          price
          quantity
          fee
        }
      }
      meta {
        perPage
        lastPage
        total
      }
    }
  }
`;

export const GET_ML_SALE = gql`
  query mercadoLibreSale($storeId: Int!, $orderId: BigInt!) {
    data: mercadoLibreSale(storeId: $storeId, orderId: $orderId) {
      id
      status
      customer {
        id
        username
        url
      }
      amounts {
        paid
        total
        shipping
      }
      closedAt
      payments {
        id
        name
        amounts {
          paid
          taxes
          shipping
          transaction
        }
        paymentMethod {
          id
          name
        }
      }
      items {
        itemId
        name
        price
        quantity
        fee
      }
      store {
        id
        name
        companyId

        config {
          invoiceTypes {
            id
            name
            anonymous
          }
        }
      }
      invoices {
        utid
        cae
        createdAt

        voucher {
          dniNumber
          total

          document {
            id
            name
          }

          pointOfSale {
            id
            stringType
          }

          voucher {
            name
          }

          seller {
            legalName
            address {
              streetName
              streetNumber
              zipCode
              province
            }
            startDate
          }

          payer {
            legalName
          }

          qr
        }
      }
    }
  }
`;

export const CHECK_SALE = gql`
  query checkMercadoLibreOrder($storeId: Int!, $orderId: BigInt!) {
    data: checkMercadoLibreOrder(storeId: $storeId, orderId: $orderId) {
      customer {
        mercadoLibreCustomerId
        customerId
        connected
        downloaded
      }
      sale {
        mercadoLibreOrderId
        saleId
        connected
        downloaded
        downloadable
      }
      items {
        mercadoLibreProductId
        productId
        itemId
        connected
        downloaded
      }
    }
  }
`;

export const DOWNLOAD_ORDER = gql`
  mutation downloadMercadoLibreOrder($storeId: Int!, $orderId: BigInt!) {
    data: downloadMercadoLibreOrder(storeId: $storeId, orderId: $orderId) {
      id
    }
  }
`;

export const SYNC_PRODUCT = gql`
  mutation syncMercadoLibreProduct($itemId: Int!) {
    data: syncMercadoLibreProduct(itemId: $itemId) {
      id
    }
  }
`;

export const SYNC_PRODUCTS = gql`
  mutation syncMercadoLibreProducts($itemIds: [Int!]!) {
    data: syncMercadoLibreProducts(itemIds: $itemIds) {
      id
    }
  }
`;
