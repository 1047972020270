import { OrderItem, OrderStatus } from "src/api/orders";
import { getTaxValue } from "./numbers";

export const calculateSummary = (values: OrderItem[], discount: number) => {
  const response = values.reduce(
    (prev, curr) => {
      const tax = getTaxValue(curr.confirmedPrice, curr.confirmedTax);

      return {
        ...prev,
        subtotal:
          prev.subtotal + (curr.confirmedPrice - tax) * curr.confirmedQuantity,
        tax: prev.tax + tax * curr.confirmedQuantity,
        total: prev.total + curr.confirmedPrice * curr.confirmedQuantity,
      };
    },
    {
      total: 0,
      tax: 0,
      subtotal: 0,
      discount: 0,
    }
  );

  return {
    tax: response.tax,
    total: response.total - Number(discount),
    subtotal: response.subtotal,
    discount: Number(discount),
  };
};

export const calculateItem = (item: OrderItem, status: OrderStatus) => {
  const quantity =
    status === OrderStatus.Confirmed
      ? item.confirmedQuantity
      : item.requestedQuantity;
  const price =
    status === OrderStatus.Confirmed
      ? item.confirmedPrice
      : item.requestedPrice;
  const tax =
    status === OrderStatus.Confirmed ? item.confirmedTax : item.requestedTax;

  return {
    quantity,
    price,
    tax,
    subtotal: quantity * price,
  };
};
