import { gql } from "@apollo/client";
import { Customer } from "./customers";
import { Product } from "./products";
import { Store } from "./stores";
import { PriceListItem } from "./price-lists";

export type CreateOrder = Pick<
  Order,
  "discount" | "items" | "companyId" | "customerId"
>;

export type UpdateOrder = Pick<
  Order,
  "discount" | "items" | "companyId" | "customerId" | "status"
>;

export type Order = {
  companyId: number;
  customerId: number;
  id: number;
  date: string;
  discount: number;
  customer: Customer;
  store: Store;
  items: OrderItem[];
  status: OrderStatus;
  deletedAt: Date | null;
};

export type OrderTotals = {
  subtotal: number;
  subtotals: [number, number];
  discount: number;
  tax: number;
  total: number;
};

export type OrderItem = {
  id: number;
  order: Order;
  product: Product;
  priceListItem: PriceListItem;

  requestedQuantity: number;
  requestedTax: number;
  requestedPrice: number;
  confirmedQuantity: number;
  confirmedTax: number;
  confirmedPrice: number;
  deliveredQuantity: number;
  deliveredTax: number;
  deliveredPrice: number;

  summary: {
    quantity: number;
    price: number;
    tax: number;
    subtotal: number;
  };
};

export type QuoteOrderItem = {
  productId: number;
  quantity: number;
  price: number;
  name: string;
  tax: number;
};

export enum OrderStatus {
  InProgress = "IN_PROGRESS",
  Cancelled = "CANCELLED",
  Prepared = "PREPARED",
  Paid = "PAID",
  Delivered = "DELIVERED",
  Confirmed = "CONFIRMED",
  Pending = "PENDING",
}

export const parseOrderStatus = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.Cancelled:
      return {
        label: "Cancelado",
        color: "danger",
      };

    case OrderStatus.Confirmed:
      return {
        label: "Confirmado",
        color: "info",
      };

    case OrderStatus.Delivered:
      return {
        label: "Entregado",
        color: "success",
      };

    case OrderStatus.Pending:
      return {
        label: "Pendiente",
        color: "warning",
      };

    case OrderStatus.InProgress:
      return {
        label: "En progreso",
        color: "warning",
      };

    case OrderStatus.Paid:
      return {
        label: "Pagado",
        color: "primary",
      };

    case OrderStatus.Prepared:
      return {
        label: "Preparado",
        color: "primary",
      };
  }
};

export const statuses = [
  { value: OrderStatus.Cancelled, name: "Cancelado" },
  { value: OrderStatus.Confirmed, name: "Confirmado" },
  { value: OrderStatus.Delivered, name: "Entregado" },
  { value: OrderStatus.InProgress, name: "En progreso" },
  { value: OrderStatus.Paid, name: "Pagado" },
  { value: OrderStatus.Prepared, name: "Preparado" },
  { value: OrderStatus.Pending, name: "Pendiente" },
];

export const LIST_ORDERS = gql`
  query getOrders($filters: OrderFilters) {
    data: orders(filters: $filters) {
      data {
        id
        date
        status
        customer {
          id
          utid
          name
          lastname
          email
        }
        items {
          product {
            id
            name
          }

          requestedQuantity
          requestedTax
          requestedPrice
          confirmedQuantity
          confirmedTax
          confirmedPrice
          deliveredQuantity
          deliveredTax
          deliveredPrice
        }
        store {
          name
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: Int!) {
    data: order(id: $id) {
      id
      date
      status
      discount
      customer {
        id
        utid
        name
        lastname
        email
      }
      items {
        id

        product {
          id
          name
        }

        priceListItem {
          id
          price
          tax
        }

        requestedQuantity
        requestedTax
        requestedPrice
        confirmedQuantity
        confirmedTax
        confirmedPrice
        deliveredQuantity
        deliveredTax
        deliveredPrice
      }
      store {
        name
      }
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder($id: Int!, $input: UpdateOrderInput!) {
    data: updateOrder(id: $id, input: $input) {
      id
      status
    }
  }
`;
