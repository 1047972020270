import { useState } from "react";
import {
  CCol,
  CRow,
  CButton,
  CCardFooter,
  CForm,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CModal,
  CModalHeader,
  CModalFooter,
  CCardBody,
  CCard,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import {
  User,
  UserUpdateForm,
  CompanyUser,
  UserPermission,
  UpdateUserSchema,
} from "src/api/users";
import { GraphQLFind, GraphQLMeta } from "src/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";

import Api from "src/api";
import { useAdminStore } from "src/store";
import { AppLoader } from "src/components/Loader/Loader";
import ChangePasswordForm from "./components/ChangePasswordForm";
import UserDetail from "./components/UserDetail";
import Companies from "./components/Companies";
import Stores from "./components/Stores";
import CompanyPermissions from "./components/CompanyPermissions";
import StorePermissions from "./components/StorePermissions";
import { PermissionGroup } from "src/api/permissions";
import { logEvent } from "src/helpers/analytics";

const mapIntoForm = (permissions: string[]) => {
  const items = permissions
    .map((permission) => {
      const [type, entityId, permissionId] = permission.split("_");

      const entity = type === "c" ? "companyId" : "storeId";

      if (entityId === "null" || entityId === null) {
        return null;
      }

      return {
        permissionId: Number(permissionId),
        value: JSON.stringify([{ [entity]: Number(entityId), value: true }]),
      };
    })
    .filter((i) => i !== null);

  return items;
};

const mapIntoArray = (permissions: UserPermission[]) => {
  const items = permissions.map((userPermission) => {
    const type = userPermission.permission.type;
    const prefix = type === "Company" ? "c" : "s";
    const key = type === "Company" ? "companyId" : "storeId";
    const value = JSON.parse(userPermission.value)[0];

    return `${prefix}_${value[key]}_${userPermission.permissionId}`;
  });

  return items;
};

const EditUser = () => {
  const { user: currentUser } = useAdminStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  let userId = pathname === "/me" ? currentUser?.id : Number(params.id);
  const itsMe = userId === currentUser?.id;

  if (!userId) {
    navigate("/users");
  }

  const { data: user } = useQuery<GraphQLFind<User>>(Api.Users.GET_USER, {
    fetchPolicy: "no-cache",
    variables: {
      id: userId,
    },
    onError: () => {
      navigate(-1);
    },
  });

  const { data: groups } = useQuery<GraphQLMeta<PermissionGroup>>(
    Api.Permissions.LIST_GROUPS,
    {
      variables: {
        filters: {
          limit: 0,
          companyId: user?.data.companies[0]?.companyId,
        },
      },
    }
  );

  if (!user?.data) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <Form user={user.data} groups={groups?.data.data ?? []} itsMe={itsMe} />

      <ChangePasswordForm userId={userId} />
    </CCol>
  );
};

const Form = ({
  groups,
  itsMe,
  user,
}: {
  groups: PermissionGroup[];
  itsMe?: boolean;
  user: User;
}) => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { user: currentUser, hasPermission } = useAdminStore();

  const onDelete = () => {
    deleteMutation({
      variables: {
        id: user.id,
      },
    });
  };

  const [updateMutation, { loading: updLoading }] = useMutation(
    Api.Users.UPDATE_USER,
    {
      onCompleted: () => window.location.reload(),
    }
  );

  const [deleteMutation, { loading: delLoading }] = useMutation(
    Api.Users.DELETE_USER,
    {
      onCompleted: () => navigate("/users"),
      onError: () => {
        setShowModal(false);
      },
    }
  );

  const formik = useFormik<UserUpdateForm>({
    initialValues: {
      name: user.name,
      lastname: user.lastname,
      loginCode: user.loginCode ?? undefined,
      userLoginCode: user.userLoginCode ?? undefined,
      companies: new Map(
        user.companies.map((companyUser) => [
          companyUser.companyId,
          companyUser.role,
        ])
      ),
      stores: new Map(
        user.stores.map((storeUser) => [
          storeUser.storeId,
          `${storeUser.companyId}-${storeUser.role}`,
        ])
      ),
      permissions: mapIntoArray(user.permissions),
      userAccess: user.userAccess,
    },
    validationSchema: UpdateUserSchema,
    onSubmit: (data) => {
      if (!updLoading) {
        const companies: CompanyUser[] = Array.from(data.companies).map(
          ([companyId, role]) => ({
            companyId,
            role,
          })
        );

        const stores = Array.from(data.stores).map(([storeId, value]) => ({
          storeId,
          companyId: Number(value.split("-")[0]),
          role: value.split("-")[1],
        }));

        const input = {
          name: data.name,
          lastname: data.lastname,
          loginCode: data.loginCode ? Number(data.loginCode) : null,
          userLoginCode: data.userLoginCode ? Number(data.userLoginCode) : null,
          companies,
          stores,
          permissions: mapIntoForm(data.permissions),
          userAccess: data.userAccess,
        };

        logEvent("user.update.submit", {
          user,
          input,
        });

        updateMutation({
          variables: {
            id: user.id,
            input,
          },
        });
      }
    },
  });

  const userCompanyId = user?.companies[0]?.companyId;

  const canUpdate =
    currentUser?.isAdmin ||
    hasPermission("UPDATE_USER", {
      type: "company",
      id: userCompanyId,
    });

  return (
    <CForm onSubmit={formik.handleSubmit}>
      <CCard>
        <CCardHeader>
          Usuario: {user.name} {user.lastname}
        </CCardHeader>

        <CCardBody>
          <CNav variant="tabs" role="tablist">
            <CNavItem>
              <CNavLink
                onClick={() => setCurrentTab(0)}
                active={currentTab === 0}
              >
                Datos
                <span className="mobile-hidden"> Personales</span>
              </CNavLink>
            </CNavItem>
            {(currentUser?.isAdmin || canUpdate) && !user.isAdmin && (
              <>
                {currentUser?.isAdmin && (
                  <CNavItem>
                    <CNavLink
                      onClick={() => setCurrentTab(1)}
                      active={currentTab === 1}
                    >
                      Empresas
                    </CNavLink>
                  </CNavItem>
                )}
                <CNavItem>
                  <CNavLink
                    onClick={() => setCurrentTab(2)}
                    active={currentTab === 2}
                  >
                    Puntos de Venta
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    onClick={() => setCurrentTab(3)}
                    active={currentTab === 3}
                  >
                    <span className="mobile-hidden">Permisos de Empresa</span>
                    <span className="web-hidden">Perm. Empresa</span>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    onClick={() => setCurrentTab(4)}
                    active={currentTab === 4}
                  >
                    <span className="mobile-hidden">
                      Permisos de Punto de Venta
                    </span>
                    <span className="web-hidden">Perm. Punto de Venta</span>
                  </CNavLink>
                </CNavItem>
              </>
            )}
          </CNav>
          <CTabContent className="px-0">
            <CTabPane visible={currentTab === 0}>
              <UserDetail user={user} formik={formik} itsMe={itsMe} />
            </CTabPane>
            <CTabPane visible={currentTab === 1}>
              <Companies formik={formik} />
            </CTabPane>

            <CTabPane visible={currentTab === 2}>
              <Stores formik={formik} />
            </CTabPane>

            <CTabPane visible={currentTab === 3}>
              <CompanyPermissions formik={formik} groups={groups} />
            </CTabPane>

            <CTabPane visible={currentTab === 4}>
              <StorePermissions
                formik={formik}
                groups={groups}
                storesList={Array.from(formik.values.stores.keys())}
              />
            </CTabPane>
          </CTabContent>
        </CCardBody>

        <CCardFooter className="px-3">
          <CRow className="px-0 align-items-center">
            <CCol md="6"></CCol>
            <CCol md="6" className="flex justify-content-end">
              {!user.isAdmin && user.id !== currentUser?.id && (
                <CButton
                  type="button"
                  color="danger"
                  size="sm"
                  className="mr-2"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar
                </CButton>
              )}

              <CModal
                alignment="center"
                visible={showModal}
                onClose={() => setShowModal(false)}
              >
                <CModalHeader closeButton>
                  ¿Estás seguro de eliminar este{" "}
                  {currentUser?.isAdmin ? "usuario" : "empleado"}?
                </CModalHeader>
                <CModalFooter>
                  <CButton
                    size="sm"
                    color="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancelar
                  </CButton>
                  <CButton
                    size="sm"
                    color="danger"
                    disabled={delLoading}
                    onClick={onDelete}
                  >
                    Si, quiero eliminarlo
                  </CButton>
                </CModalFooter>
              </CModal>

              <CLoadingButton
                size="sm"
                loading={updLoading}
                disabled={updLoading}
                color="primary"
                type="submit"
                onClick={() => formik.handleSubmit()}
              >
                Guardar
              </CLoadingButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CForm>
  );
};

export default EditUser;
