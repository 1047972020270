import {
  CCol,
  CRow,
  CCardFooter,
  CForm,
  CFormInput,
  CFormLabel,
  CAlert,
  CCardBody,
  CCard,
  CCardHeader,
  CLoadingButton,
} from "@coreui/react-pro";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";

import Api from "src/api";

const ChangePasswordForm = ({ userId }) => {
  const [passwordMutation, { loading: pwdLoading, error: pwdError }] =
    useMutation(Api.Users.UPDATE_USER_PASSWORD, {
      onCompleted: () => window.location.reload(),
    });

  const formik = useFormik<{ password: string; rePassword: string }>({
    initialValues: {
      password: "",
      rePassword: "",
    },
    onSubmit: (data) => {
      const { password, rePassword } = data;

      if (!password || password === "") {
        return formik.setFieldError("password", "");
      }

      if (password !== rePassword) {
        return formik.setFieldError("rePassword", "");
      }

      passwordMutation({
        variables: {
          id: userId,
          password,
        },
      });
    },
  });

  return (
    <CCol sm={9}>
      <CCard className="mt-4">
        <CForm onSubmit={formik.handleSubmit}>
          <CCardHeader>Cambiar contraseña</CCardHeader>
          <CCardBody>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="password">Nueva contraseña</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="password"
                  type="password"
                  valid={!!formik.errors.password}
                  placeholder="Contraseña"
                  name="password"
                  onChange={formik.handleChange}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="rePassword">Repetir contraseña</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="rePassword"
                  type="password"
                  valid={!!formik.errors.rePassword}
                  placeholder="Repetir Contraseña"
                  name="rePassword"
                  onChange={formik.handleChange}
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter className="px-3">
            <CRow className="px-0 align-items-center">
              <CCol md="9">
                {pwdError && (
                  <CAlert color="danger" className="my-0">
                    Ha ocurrido un error. Vuelve a intentar
                  </CAlert>
                )}
              </CCol>
              <CCol md="3" className="flex justify-content-end">
                <CLoadingButton
                  loading={pwdLoading}
                  size="sm"
                  color="primary"
                  disabled={pwdLoading}
                  type="submit"
                >
                  Guardar
                </CLoadingButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  );
};

export default ChangePasswordForm;
