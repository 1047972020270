import { CLoadingButton } from "@coreui/react-pro";
import { VoidFunction } from "src/types";

const SaveButton = ({
  disabled,
  onSubmit,
  text = "Crear",
  loading,
}: {
  disabled?: boolean;
  onSubmit?: VoidFunction;
  text?: string;
  loading?: boolean;
}) => {
  return (
    <CLoadingButton
      color="primary"
      loading={loading}
      size="sm"
      disabled={disabled}
      onClick={onSubmit}
      type="submit"
    >
      {text}
    </CLoadingButton>
  );
};

export default SaveButton;
