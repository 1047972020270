import { useMutation, useQuery } from "@apollo/client";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Api from "src/api";
import { AfipDocument, CreateSaleInvoiceSchema } from "src/api/afip";
import { Customer } from "src/api/customers";
import { Sale } from "src/api/sales";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { logEvent } from "src/helpers/analytics";
import { ForwardedModalProps, GraphQLFind } from "src/types";

type Invoice = {
  saleId: number;
  invoiceTypeId: number;
  customerId: number | "new";
  dni: number;
  genre: string;
  dniType: number;
};

type CreateInvoice = {
  saleId?: number;
  invoiceTypeId: number;
  customer: {
    id: number;
    dniType: number;
    dni: string;
  };
  genre?: string;
};

const InvoicingModal: ForwardRefRenderFunction<ForwardedModalProps> = (
  _,
  ref
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [sale, setSale] = useState<Sale>();
  const [newCustomer, setNewCustomer] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Afip.CREATE_SALE_INVOICE, {
    onCompleted: () => {
      window.location.reload();
      handleDismiss();
    },
    onError: (error) => {
      let errorMessage =
        "Ha ocurrido un error al intentar crear la factura. Vuelva a intentar.";

      switch (error.message) {
        case "missing_cbte_asoc_periodo_asoc":
          errorMessage = "El comprobante asociado no tiene periodo asociado";
          break;

        case "invalid_cbtes_asoc":
          errorMessage = "El comprobante asociado es inválido";
          break;

        case "invalid_cuil_for_doc_type":
          errorMessage = "Documento inválido para el tipo de factura";
          break;
      }

      toastRef.current?.show(errorMessage);
    },
  });

  const { data: documentTypes } = useQuery<GraphQLFind<AfipDocument[]>>(
    Api.Afip.LIST_DOCUMENT_TYPES,
    {
      fetchPolicy: "no-cache",
      skip: !sale?.store,
      variables: {
        companyId: sale?.store.company.id,
        storeId: sale?.store.id,
      },
    }
  );

  const handleDismiss = () => {
    setVisible(false);
    setSale(undefined);
  };

  const formik = useFormik<Invoice>({
    initialValues: {
      saleId: sale?.id ?? 0,
      customerId: 0,
      genre: "",
      invoiceTypeId: 0,
      dniType: 0,
      dni: 0,
    },
    isInitialValid: false,
    validationSchema: CreateSaleInvoiceSchema,
    onSubmit: (values) => {
      if (!loading) {
        const input: CreateInvoice = {
          saleId: sale?.id,
          invoiceTypeId: Number(values.invoiceTypeId),
          customer: {
            id: Number(values.customerId),
            dniType: Number(values.dniType),
            dni: values.dni.toString(),
          },
        };

        if (input.customer?.dniType === 96) {
          input.genre = values.genre;
        }

        logEvent("sale.create-invoice", {
          sale,
          input,
        });

        const confirm =
          (sale?.invoices?.length ?? 0) > 0
            ? window.confirm(
                "Esta venta ya posee una factura. ¿Estas seguro de querer crear una nueva factura?"
              )
            : true;

        if (confirm) {
          mutation({
            variables: {
              input: {
                ...input,
                force: true,
              },
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    if (Number(formik.values.customerId) === -1) {
      setNewCustomer(true);
    } else {
      setNewCustomer(false);
    }
  }, [formik, formik.values.customerId]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (s: Sale, c: Customer[]) => {
        setVisible(true);
        setSale(s);
        setCustomers(c);
      },
    }),
    []
  );

  useEffect(() => {
    if (Number(formik.values.customerId) > 0) {
      const customer = customers.find(
        (customer) => customer.id === Number(formik.values.customerId)
      );

      if (customer) {
        formik.setFieldValue("dniType", customer.utidType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, formik.values.customerId]);

  const config = sale?.store.config;
  const dniTypes =
    documentTypes?.data.filter((dniType) => {
      return (
        dniType.name.includes("DNI") ||
        dniType.name.includes("CUIL") ||
        dniType.name.includes("CUIT")
      );
    }) ?? [];

  return (
    <CModal
      size="xl"
      alignment="center"
      visible={visible}
      onClose={handleDismiss}
    >
      <CModalHeader closeButton>Facturar Venta #{sale?.id}</CModalHeader>
      <CModalBody>
        <CForm>
          <CRow className="mt-2">
            <CCol sm={4}>
              <CFormSelect
                onChange={formik.handleChange}
                floatingLabel="Tipo de Factura"
                name="invoiceTypeId"
              >
                <option value={0}>Seleccione Tipo de Factura</option>
                {config?.invoiceTypes
                  .filter((type) => !type.name.includes("Nota"))
                  .map((invoice) => (
                    <option key={invoice.id} value={invoice.id}>
                      {invoice.name}
                    </option>
                  ))}
              </CFormSelect>
            </CCol>
            <CCol sm={4}>
              <CFormSelect
                floatingLabel="Cliente"
                name="customerId"
                onChange={formik.handleChange}
              >
                <option value={0}>Consumidor Final</option>
                <option value={-1}>Nuevo cliente</option>
                {customers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {`${customer.id} - ${
                      customer.name !== ""
                        ? `${customer.name} - ${customer.utid}`
                        : customer.utid
                    }`}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
            {Number(formik.values.customerId) >= 1 && (
              <CCol sm={4}>
                <CFormSelect
                  floatingLabel="Tipo de Documento"
                  name="dniType"
                  onChange={formik.handleChange}
                >
                  <option value="0">Seleccione Tipo de Documento</option>
                  {dniTypes.map((document) => (
                    <option key={document.id} value={document.id}>
                      {document.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            )}
          </CRow>

          {newCustomer && (
            <CRow className="mt-3">
              <CCol sm="5">
                <CFormSelect
                  floatingLabel="Tipo de Documento"
                  name="dniType"
                  onChange={formik.handleChange}
                >
                  <option value="0">Seleccione Tipo de Documento</option>
                  {dniTypes.map((document) => (
                    <option key={document.id} value={document.id}>
                      {document.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
              {formik.values.dniType.toString() === "96" && (
                <CCol sm="2">
                  <CFormSelect
                    floatingLabel="Sexo / Tipo"
                    name="genre"
                    onChange={formik.handleChange}
                  >
                    <option value="0">Seleccione</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                    <option value="S">Empresa</option>
                    <option value="U">Otro</option>
                  </CFormSelect>
                </CCol>
              )}
              <CCol sm={formik.values.dniType.toString() === "96" ? "5" : "7"}>
                <CFormInput
                  name="dni"
                  type="number"
                  onChange={formik.handleChange}
                  floatingLabel="Nro de DNI / CUIL / CUIT"
                />
              </CCol>
            </CRow>
          )}
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={() => handleDismiss()}>
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          loading={loading}
          disabled={!formik.isValid}
          color="success"
          onClick={() => formik.handleSubmit()}
        >
          Facturar
        </CLoadingButton>
      </CModalFooter>

      <Toast ref={toastRef} text="Error al facturar" />
    </CModal>
  );
};

export default forwardRef(InvoicingModal);
