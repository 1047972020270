import { gql } from "@apollo/client";
import { Company } from "./companies";

export type Permission = {
  id: number;
  entity: string;
  name: string;
  description: string | null;
  key: string;
};

export type PermissionGroup = {
  id: number;
  name: string;
  companyId?: number;
  permissionsCount: number;
  permissions: Permission[];

  company?: Company;
};

export type CreatePermissionGroup = Pick<
  PermissionGroup,
  "companyId" | "name"
> & {
  permissions: string[];
};

export type UpdatePermissionGroup = CreatePermissionGroup;

export type PermissionMap = {
  label: string;
  entity: string;
  CREATE: Pick<Permission, "id" | "key" | "name">;
  UPDATE: Pick<Permission, "id" | "key" | "name">;
  SHOW: Pick<Permission, "id" | "key" | "name">;
  LIST: Pick<Permission, "id" | "key" | "name">;
  DELETE: Pick<Permission, "id" | "key" | "name">;
};

export const LIST_PERMISSIONS = gql`
  query getPermissions($filters: PermissionFilters) {
    data: permissions(filters: $filters) {
      data {
        id
        entity
        key
        name
      }
      meta {
        total
      }
    }
  }
`;

export const LIST_GROUPS = gql`
  query permissionGroups($filters: PermissionFilters) {
    data: permissionGroups(filters: $filters) {
      data {
        id
        name

        permissionsCount

        company {
          id
          name
        }

        permissions {
          key
        }
      }
      meta {
        perPage
        lastPage
        total
      }
    }
  }
`;

export const GET_GROUP = gql`
  query permissionGroup($id: Int!) {
    data: permissionGroup(id: $id) {
      id
      name
      companyId

      permissions {
        key
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation createPermissionGroup($input: CreatePermissionGroupInput!) {
    data: createPermissionGroup(input: $input) {
      id
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updatePermissionGroup(
    $id: Int!
    $input: CreatePermissionGroupInput!
  ) {
    data: updatePermissionGroup(id: $id, input: $input) {
      id
    }
  }
`;
