import { useMutation, useQuery } from "@apollo/client";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormSelect,
  CLink,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Api from "src/api";
import { Store, StoreConfig, StoreType } from "src/api/stores";
import { TiendaNube } from "src/components/Icon";
import { AppLoader } from "src/components/Loader/Loader";
import Toast, { ForwardedToastProps } from "src/components/Toast";

import FeatureAlert from "src/containers/FeatureAlert";
import { logEvent } from "src/helpers/analytics";
import { useAdminStore } from "src/store";
import { GraphQLFind, GraphQLMeta } from "src/types";
import AfipConnected from "../invoicing/components/AfipConnected";
import { AfipStatus } from "src/api/afip";

const StoreConfigRow = ({ store }: { store: Store }) => {
  const { hasPermission, user } = useAdminStore();
  const navigate = useNavigate();
  const canEdit = hasPermission("UPDATE_STORE");
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Stores.UPDATE_CONFIG, {
    onCompleted: () => {
      toastRef.current?.show();
    },
  });

  const [downloadMutation, { loading: downloading }] = useMutation(
    Api.TiendaNube.DOWNLOAD_IMAGES,
    {
      onCompleted: () => {
        toastRef.current?.show(
          "Las imagenes de productos han sido actualizadas"
        );
      },
    }
  );

  const formik = useFormik<Pick<StoreConfig, "autoPropagate">>({
    initialValues: {
      autoPropagate: store.config.autoPropagate,
    },
    onSubmit: (values) => {
      if (!loading) {
        const input = {
          autoPropagate: values.autoPropagate.toString() === "1",
        };

        logEvent("tienda-nube-store.config.update", {
          store,
          input,
        });

        mutation({
          variables: {
            id: store.id,
            input,
          },
        });
      }
    },
  });

  return (
    <div className="position-relative">
      <CRow>
        <h5>
          <TiendaNube size={24} />{" "}
          <CLink
            href={`https://${store.metadata?.tiendaNubeUrl}`}
            target="_blank"
          >
            {store.name}
          </CLink>
        </h5>
      </CRow>
      <CRow>
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            floatingLabel="¿Auto propagación de productos?"
            name="autoPropagate"
            onChange={formik.handleChange}
            defaultValue={formik.values.autoPropagate ? 1 : 0}
          >
            <option value={1}>Si</option>
            <option value={0}>No</option>
          </CFormSelect>
        </CCol>
      </CRow>

      <Toast
        color="primary"
        autohide
        visible={false}
        ref={toastRef}
        text="Configuración actualizada"
      />

      <CRow className="mt-3">
        {!canEdit ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            {user?.isAdmin && (
              <CButton
                size="sm"
                color="default"
                className="mr-2"
                onClick={() => navigate(`/tienda-nube/${store.id}/webhooks`)}
              >
                Webhooks
              </CButton>
            )}
            <CLoadingButton
              size="sm"
              color="warning"
              loading={downloading}
              className="mr-2"
              onClick={() =>
                downloadMutation({
                  variables: {
                    storeId: store.id,
                  },
                })
              }
            >
              Descargar imagenes
            </CLoadingButton>
            <CButton
              size="sm"
              color="danger"
              className="mr-2"
              onClick={() => navigate(`/tienda-nube/${store.id}/products`)}
            >
              Auditoria productos
            </CButton>
            <CButton
              size="sm"
              color="info"
              className="mr-2"
              onClick={() => navigate(`/tienda-nube/${store.id}/sales`)}
            >
              Ver ventas
            </CButton>
            <CLoadingButton
              size="sm"
              color="primary"
              loading={loading}
              disabled={loading}
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </div>
  );
};

const TiendaNubeScreen = () => {
  const { user } = useAdminStore();
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          type: StoreType.TiendaNube,
        },
      },
    }
  );

  if (!stores?.data?.data) {
    return <AppLoader />;
  }

  const companyId = stores.data.data[0].company.id;

  return (
    <>
      <FeatureAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>Configuración de Tiendas</CCardHeader>
            <CCardBody>
              {stores.data.data.map((store, index) => (
                <Fragment key={store.id}>
                  {stores.data.data.length > 1 && index > 0 && <hr />}
                  <StoreConfigRow store={store} key={store.id} />
                </Fragment>
              ))}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {!user?.isAdmin && <AfipConfiguration companyId={companyId} />}
    </>
  );
};

const AfipConfiguration = ({ companyId }) => {
  const { data: connection } = useQuery<GraphQLFind<AfipStatus[]>>(
    Api.Afip.GET_STATUS,
    {
      fetchPolicy: "no-cache",
      variables: {
        companyId,
      },
    }
  );

  return (
    <div className="mt-4">
      {connection?.data
        .filter(
          (currentConnection) =>
            currentConnection.status === "CONNECTED" &&
            currentConnection.store.type === StoreType.TiendaNube
        )
        .map((currentConnection, index) => (
          <Fragment key={currentConnection.store.id}>
            {connection?.data.length > 1 && index > 0 && <hr />}
            <AfipConnected
              companyId={currentConnection.company.id}
              storeId={currentConnection.store.id}
              type={StoreType.TiendaNube}
              services={currentConnection.services ?? []}
            />
          </Fragment>
        ))}
    </div>
  );
};

export default TiendaNubeScreen;
