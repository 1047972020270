import {
  CButton,
  CCol,
  CFormLabel,
  CFormSwitch,
  CRow,
} from "@coreui/react-pro";

const ConnectionRow = ({ htmlFor, label, value, onConnect }) => {
  return (
    <CRow className="form-row">
      <CCol md="3">
        <CFormLabel htmlFor={htmlFor}>{label}</CFormLabel>
      </CCol>
      <CCol xs="12" md="9">
        {value ? (
          <CFormSwitch readOnly checked={true} label={"Conectado"} />
        ) : (
          <CButton size="sm" color="primary" onClick={onConnect}>
            Conectar
          </CButton>
        )}
      </CCol>
    </CRow>
  );
};

export default ConnectionRow;
