import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
} from "@coreui/react-pro";

import FeatureAlert from "src/containers/FeatureAlert";

const EditBankPromotion = () => {
  return (
    <>
      <FeatureAlert />

      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={12} className="px-0">
                  Promoción Bancaria
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={false}
                items={[]}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: "name",
                    label: "Nombre",
                    _props: { className: "font-weight-bold" },
                  },
                  { key: "store", label: "Punto de Venta" },
                  { key: "dateFrom", label: "Fecha de Inicio" },
                  { key: "dateTo", label: "Fecha de Fin" },
                  {
                    key: "updatedAt",
                    label: "Última Actualización",
                    _props: { className: "text-right" },
                  },
                  {
                    key: "actions",
                    label: "Acciones",
                    _props: { className: "text-right" },
                  },
                ]}
                scopedColumns={{}}
                tableProps={{
                  striped: true,
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default EditBankPromotion;
