import { forwardRef } from "react";
import {
  CCol,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CTabContent,
  CTabPane,
  CCard,
  CCardHeader,
  CFormCheck,
} from "@coreui/react-pro";
import { ApolloError, useMutation } from "@apollo/client";
import {
  UserCreation,
  UserSchema,
  User,
  userAccess,
  adminUserAccess,
} from "src/api/users";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import SaveButton from "src/components/SaveButton";
import ErrorMessage from "src/components/ErrorMessage";
import Api from "src/api";
import { useAdminStore } from "src/store";
import { GraphQLMutation } from "src/types";
import { getValidity } from "src/helpers/validation";
import { useCanNavigate } from "src/helpers/permissions";

const CreateUser = () => {
  const navigate = useNavigate();
  const { user } = useAdminStore();

  useCanNavigate("CREATE_USER", { route: "/users", condition: user?.isAdmin });

  const [mutation, { loading, error }] = useMutation(Api.Users.CREATE_USER, {
    onCompleted: (res: GraphQLMutation<Pick<User, "id">>) => {
      navigate(`/users/${res.data.id}`);
    },
    onError: (error: ApolloError) => {
      if (error.message === "email_already_exists") {
        formik.setFieldError("email", "Este email ya se encuentra en uso");
      }
    },
  });

  const formik = useFormik<UserCreation>({
    initialValues: {
      name: "",
      email: "",
      lastname: "",
      password: "",
      loginCode: undefined,
      userLoginCode: undefined,
      userAccess: [],
    },
    onSubmit: (data) => {
      if (!loading) {
        const {
          name,
          email,
          lastname,
          password,
          loginCode,
          userLoginCode,
          userAccess,
        } = data;

        mutation({
          variables: {
            input: {
              name,
              email,
              lastname,
              password,
              loginCode: loginCode ? Number(loginCode) : null,
              userLoginCode: userLoginCode ? Number(userLoginCode) : null,
              userAccess,
              type: "employee",
            },
          },
        });
      }
    },
    validationSchema: UserSchema,
  });

  const handleAccessChange = (value: string, checked: boolean) => {
    if (checked) {
      formik.setFieldValue("userAccess", [...formik.values.userAccess, value]);
    } else {
      formik.setFieldValue(
        "userAccess",
        formik.values.userAccess.filter((item) => item !== value)
      );
    }
  };

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Datos Personales</CCardHeader>
          <CTabContent>
            <CTabPane visible>
              <CCol xs="12">
                <CRow className="form-row">
                  <CCol md="3" className="table-center">
                    <CFormLabel>Email</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="email"
                      placeholder="Email"
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      {...getValidity(formik.values.email, formik.errors.email)}
                    />
                  </CCol>
                </CRow>
                <CRow className="form-row">
                  <CCol md="3" className="table-center">
                    <CFormLabel htmlFor="name">Nombre</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="name"
                      placeholder="Nombre"
                      name="name"
                      onChange={formik.handleChange}
                      {...getValidity(formik.values.name, formik.errors.name)}
                    />
                  </CCol>
                </CRow>
                <CRow className="form-row">
                  <CCol md="3" className="table-center">
                    <CFormLabel htmlFor="lastname">Apellido</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="lastname"
                      placeholder="Apellido"
                      name="lastname"
                      onChange={formik.handleChange}
                      {...getValidity(
                        formik.values.lastname,
                        formik.errors.lastname
                      )}
                    />
                  </CCol>
                </CRow>
                <CRow className="form-row">
                  <CCol md="3" className="table-center">
                    <CFormLabel htmlFor="password">Contraseña</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="password"
                      placeholder="Contraseña"
                      type="password"
                      name="password"
                      onChange={formik.handleChange}
                      {...getValidity(
                        formik.values.password,
                        formik.errors.password
                      )}
                    />
                  </CCol>
                </CRow>
                <CRow className="form-row">
                  <CCol md="3" className="table-center">
                    <CFormLabel htmlFor="loginCode">
                      Código de Ingreso
                    </CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="loginCode"
                      placeholder="1234"
                      type="number"
                      name="loginCode"
                      onChange={formik.handleChange}
                      {...getValidity(
                        formik.values.loginCode,
                        formik.errors.loginCode
                      )}
                    />
                  </CCol>
                </CRow>
                <CRow className="form-row">
                  <CCol md="3">
                    <CFormLabel htmlFor="userLoginCode">
                      Código de Ingreso Personal
                    </CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CFormInput
                      id="userLoginCode"
                      type="number"
                      placeholder="5423"
                      name="userLoginCode"
                      onChange={formik.handleChange}
                      {...getValidity(
                        formik.values.userLoginCode,
                        formik.errors.userLoginCode
                      )}
                    />
                  </CCol>
                </CRow>
                <CRow className="form-row mt-3">
                  <CCol md="3">
                    <CFormLabel htmlFor="userAccess">Accesos</CFormLabel>
                  </CCol>
                  <CCol xs="12" md="9">
                    <CRow className="d-flex w-full align-items-center">
                      {(user?.isAdmin ? adminUserAccess : userAccess).map(
                        (access) => (
                          <CCol md="2" key={access.id}>
                            <CFormCheck
                              defaultChecked={formik.values.userAccess.includes(
                                access.value
                              )}
                              name="userAccess"
                              id={access.value}
                              onChange={(e) => {
                                handleAccessChange(
                                  e.target.value,
                                  e.target.checked
                                );
                              }}
                              label={access.label}
                              value={access.value}
                            />
                          </CCol>
                        )
                      )}
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CTabPane>
          </CTabContent>

          <CCardFooter className="px-3">
            <CRow className="px-0 align-items-center">
              <CCol md="9">
                {error && <ErrorMessage error={error.message} />}
              </CCol>
              <CCol md="3" className="flex justify-content-end">
                <SaveButton
                  loading={loading}
                  disabled={loading || !formik.isValid}
                />
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default forwardRef(CreateUser);
