import {
  CButton,
  CCol,
  CDatePicker,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CTimePicker,
} from "@coreui/react-pro";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  CalendarModalProps,
  ForwardedCalendarModalProps,
} from "./CalendarModal.types";
import { dateFormat } from "src/helpers/dates";

const tomorrow = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss", {
  add: { days: 1 },
});

const DATE_FORMAT = "yyyy-MM-dd";
const TIME_FORMAT = "HH:mm:ss";

const CalendarModal: ForwardRefRenderFunction<
  ForwardedCalendarModalProps,
  CalendarModalProps
> = ({ onSubmit, loading, withTime }, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();

  const handleDismiss = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    if (date && time) {
      onSubmit(dateFormat(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`));
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (d: Date) => {
        setVisible(true);
        setDate(dateFormat(d, DATE_FORMAT));
        setTime(dateFormat(d, TIME_FORMAT));
      },
    }),
    []
  );

  return (
    <CModal alignment="center" visible={visible} onClose={handleDismiss}>
      <CModalHeader closeButton>Seleccionar Fecha</CModalHeader>
      <CModalBody>
        <CRow>
          <CCol sm={withTime ? 7 : 12}>
            <CDatePicker
              maxDate={tomorrow}
              date={date}
              onDateChange={(e) => {
                if (e) {
                  setDate(dateFormat(e, DATE_FORMAT));
                }
              }}
            />
          </CCol>
          {withTime && (
            <CCol sm={5}>
              <CTimePicker
                time={time}
                onTimeChange={(_, d) => {
                  if (d) {
                    setTime(d);
                  }
                }}
              />
            </CCol>
          )}
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={loading}
          size="sm"
          color="secondary"
          onClick={() => handleDismiss()}
        >
          Cancelar
        </CButton>
        <CLoadingButton
          loading={loading}
          disabled={loading}
          size="sm"
          color="success"
          onClick={() => handleSubmit()}
        >
          Cambiar y guardar
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(CalendarModal);
