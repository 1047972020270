import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CCol,
  CRow,
  CLoadingButton,
  CFormInput,
} from "@coreui/react-pro";
import Api from "src/api";
import { useFormik } from "formik";
import { useAdminStore } from "src/store";
import { Entity } from "src/types";

export type BatchStockModalForwardedRef = {
  open: (entityType: Entity, entityId: number) => void;
  close: () => void;
};

export type BatchStockModalProps = {};

const BatchStockModal: ForwardRefRenderFunction<
  BatchStockModalForwardedRef,
  BatchStockModalProps
> = (_, ref) => {
  const { currentCompany } = useAdminStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentEntityType, setCurrentEntityType] = useState<Entity>();

  const formik = useFormik({
    initialValues: {
      entityId: 0,
      amount: 0,
      companyId: currentCompany?.id ?? 0,
    },
    onSubmit: async (data) => {
      if (data.amount === 0) {
        return handleClose();
      }

      if (!loading) {
        setLoading(true);

        const amountToUpdate = data.amount;

        if (currentEntityType === Entity.Category) {
          await Api.Products.categoriesBatchStockUpdate(
            data.companyId,
            data.entityId,
            amountToUpdate
          );

          setLoading(false);

          alert("Alerta de stock actualizado correctamente");

          window.location.reload();
          return;
        }

        if (currentEntityType === Entity.Supplier) {
          await Api.Products.suppliersBatchStockUpdate(
            data.companyId,
            data.entityId,
            amountToUpdate
          );

          setLoading(false);

          alert("Alerta de stock actualizado correctamente");

          window.location.reload();
          return;
        }

        setLoading(false);
      }
    },
  });

  const handleClose = useCallback(() => {
    formik.resetForm();
    setShowModal(false);
    setCurrentEntityType(undefined);
  }, [formik]);

  useImperativeHandle(
    ref,
    () => ({
      open: (entityType: Entity, entityId: number) => {
        setShowModal(true);
        setCurrentEntityType(entityType);
        formik.setFieldValue("entityId", entityId);
        formik.setFieldValue("companyId", currentCompany?.id ?? 0);
      },
      close: () => {
        handleClose();
      },
    }),
    [currentCompany?.id, formik, handleClose]
  );

  return (
    <CModal
      alignment="center"
      size="sm"
      visible={showModal}
      onClose={() => {
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>
        Actualizar Alerta de Stock
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol md="12">
            <CFormInput
              floatingLabel="Stock"
              name="amount"
              defaultValue={formik.values.amount}
              autoFocus
              min={0}
              max={999}
              onChange={formik.handleChange}
              type="number"
            />
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          disabled={loading}
          onClick={() => {
            handleClose();
          }}
        >
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          loading={loading}
          disabled={loading}
          onClick={() => formik.handleSubmit()}
          color="success"
        >
          Continuar
        </CLoadingButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(BatchStockModal);
