import { gql } from "@apollo/client";
import {
  MercadoLibreSale,
  MercadoLibreSaleItem,
  MercadoLibreSalePayment,
} from "./mercado-libre";

export type MercadoShopsSaleItem = MercadoLibreSaleItem;

export type MercadoShopsSalePayment = MercadoLibreSalePayment;

export type MercadoShopsSale = Omit<MercadoLibreSale, "payments" | "items"> & {
  payments: MercadoShopsSalePayment[];
  items: MercadoShopsSaleItem[];
};

export const DOWNLOAD_MS_PRODUCTS = gql`
  mutation downloadMercadoShopsProducts($priceListId: Int!) {
    data: downloadMercadoShopsProducts(priceListId: $priceListId) {
      data {
        id
        name
        price
        sku
      }
      meta {
        lastPage
      }
    }
  }
`;

export const LIST_MS_SALES = gql`
  query mercadoShopsSales($filters: MercadoLibreSalesFilters!) {
    data: mercadoShopsSales(filters: $filters) {
      data {
        id
        status
        customer {
          id
          username
          url
        }
        amounts {
          paid
          total
          shipping
        }
        closedAt
        payments {
          id
          name
          amounts {
            paid
            total
            shipping
          }
        }
        items {
          itemId
          name
          price
          quantity
          fee
        }
      }
      meta {
        perPage
        lastPage
        total
      }
    }
  }
`;

export const GET_MS_SALE = gql`
  query mercadoShopsSale($orderId: BigInt!, $storeId: Int!) {
    data: mercadoShopsSale(orderId: $orderId, storeId: $storeId) {
      id
      status
      customer {
        id
        username
        url
      }
      amounts {
        paid
        total
        shipping
      }
      closedAt
      payments {
        id
        name
        amounts {
          paid
          taxes
          shipping
          transaction
        }
        paymentMethod {
          id
          name
        }
      }
      items {
        itemId
        name
        price
        quantity
        fee
      }
      store {
        id
        name
        companyId

        config {
          invoiceTypes {
            id
            name
            anonymous
          }
        }
      }
      invoices {
        utid
        cae
        createdAt

        voucher {
          dniNumber
          total

          document {
            id
            name
          }

          pointOfSale {
            id
            stringType
          }

          voucher {
            name
          }

          seller {
            legalName
            address {
              streetName
              streetNumber
              zipCode
              province
            }
            startDate
          }

          payer {
            legalName
          }

          qr
        }
      }
    }
  }
`;
