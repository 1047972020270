import { CCard, CCardBody, CCardHeader } from "@coreui/react-pro";
import { memo, useState } from "react";
import { CChart } from "@coreui/react-chartjs";
import { dateFormat } from "src/helpers/dates";
import { capitalize } from "lodash";
import { SummaryByMonth } from "src/api/dashboard";
import { useQuery } from "@apollo/client";
import Api from "src/api";
import { useAdminStore } from "src/store";
import { isNumber } from "src/helpers/comparators";
import { sub } from "date-fns/sub";

const toArray = (array: SummaryByMonth[]) => {
  const income = new Array(13).fill(0).map((i) => i);
  const profit = new Array(13).fill(0).map((i) => i);
  const innerArray =
    array.length < 13
      ? [...new Array(13 - array.length).fill(0), ...array]
      : array;

  innerArray.forEach((monthValue, index) => {
    if (isNumber(monthValue) && monthValue === 0) {
      income[index] = 0;
      profit[index] = 0;
    } else {
      income[index] = monthValue.income < 0 ? 0 : monthValue.income;
      profit[index] = monthValue.profit < 0 ? 0 : monthValue.profit;
    }
  });

  return {
    income,
    profit,
  };
};

const generateMonths = () =>
  new Array(12).fill(0).map((_, i) => {
    return capitalize(dateFormat(new Date(2000, Number(i), 1), "MMM"));
  });

const generateMonthsKey = () => {
  const today = new Date();

  return new Array(13)
    .fill(0)
    .map((_, i) => {
      return dateFormat(sub(today, { months: i }), "yyMM");
    })
    .reverse();
};

const generateLabels = (array: SummaryByMonth[]) => {
  const months = generateMonths();
  const monthsKeys = generateMonthsKey();

  const innerArray =
    array.length < 13
      ? [...new Array(13 - array.length).fill(0), ...array]
      : array;

  return innerArray.map((monthValue, idx) => {
    const getMonthIndex =
      isNumber(monthValue) && monthValue === 0
        ? Number(monthsKeys[idx].substring(2)) - 1
        : Number(monthValue.date.substring(2)) - 1;
    const year =
      isNumber(monthValue) && monthValue === 0
        ? Number(`20${monthsKeys[idx].substring(0, 2)}`)
        : Number(monthValue.year);
    const month = months[getMonthIndex];

    return `${month} - ${year}`;
  });
};

const InvoicingDashboard = ({ storeId }) => {
  const { user } = useAdminStore();
  const [screenData, setScreenData] = useState<{
    income: number[];
    profit: number[];
  }>();
  const [monthLabels, setMonthLabels] = useState<string[]>([]);

  useQuery<{ data: SummaryByMonth[] }>(Api.Dashboard.SUMMARY_BY_MONTH, {
    variables: {
      storeId,
    },
    onCompleted: ({ data: summaryByMonth }) => {
      setScreenData(toArray(summaryByMonth));
      setMonthLabels(generateLabels(summaryByMonth));
    },
  });

  if (!screenData) {
    return null;
  }

  return (
    <CCard className="mt-3 mb-3 mobile-hidden">
      <CCardHeader>Facturación últimos 12 meses ($)</CCardHeader>
      <CCardBody>
        <CChart
          height={150}
          data={{
            labels: monthLabels,
            datasets: [
              {
                label: "Facturación",
                backgroundColor: "#f87979",
                data: screenData.income,
              },
              {
                label: "Ganancia",
                backgroundColor: "#af0079",
                data: screenData.profit,
              },
            ],
          }}
          customTooltips
          type={user?.isAdmin ? "bar" : "line"}
          wrapper
        />
      </CCardBody>
    </CCard>
  );
};

export default memo(InvoicingDashboard);
