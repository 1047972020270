import { gql } from "@apollo/client";
import { Company } from "./companies";
import { User } from "./users";
import { Store } from "./stores";
import { CashRegister } from "./registers";

export type SupportRequest = {
  id: number;
  company: Pick<Company, "id" | "name">;
  reporter: Pick<User, "id" | "name" | "lastname">;
  store: Pick<Store, "id" | "name"> | null;
  register: Pick<CashRegister, "id" | "name"> | null;
  status: string;
  notes: string;
  createdAt: string;
  updatedAt: string;
};

export const LIST_SUPPORTS = gql`
  query supportRequests($filters: SupportRequestFilters) {
    data: supportRequests(filters: $filters) {
      data {
        id
        company {
          id
          name
        }
        reporter {
          id
          name
          lastname
        }
        store {
          id
          name
        }
        register {
          id
          name
        }
        status
        createdAt
        updatedAt
      }
      meta {
        perPage
        total
        lastPage
      }
    }
  }
`;

export const GET_SUPPORT = gql`
  query supportRequest($id: Int!) {
    data: supportRequest(id: $id) {
      id
      company {
        id
        name
      }
      reporter {
        id
        name
        lastname
      }
      store {
        id
        name
      }
      register {
        id
        name
      }
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_REQUEST = gql`
  mutation createSupportRequest($input: CreateSupportRequestInput!) {
    data: createSupportRequest(input: $input) {
      id
    }
  }
`;
