import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CAlert,
  CCol,
  CForm,
  CFormSelect,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { Fragment, useMemo, useRef } from "react";
import Api from "src/api";
import { CompanyConfig } from "src/api/companies";
import { PaymentMethod } from "src/api/sales";
import { StockType } from "src/api/stock";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { logEvent } from "src/helpers/analytics";
import { roundingExamples } from "src/helpers/numbers";
import { paymentMethods } from "src/helpers/payments";
import { useAdminStore } from "src/store";
import { Currency } from "src/types";

const currencies = [
  {
    id: Currency.Peso,
    label: "Peso Argentino",
    symbol: "$",
  },
  {
    id: Currency.Dolar,
    label: "Dolar",
    symbol: "$",
  },
  {
    id: Currency.Euro,
    label: "Euro",
    symbol: "€",
  },
  {
    id: Currency.Real,
    label: "Real",
    symbol: "R$",
  },
];

const CompanySettings = ({
  companyId,
  config,
  isFranchise,
}: {
  companyId: number;
  config: CompanyConfig;
  isFranchise: boolean;
}) => {
  const { hasPermission, setCurrentCompany } = useAdminStore();
  const canEdit = hasPermission("UPDATE_COMPANY") && !isFranchise;
  const canEditWithFranchise = hasPermission("UPDATE_COMPANY") && isFranchise;
  const toastRef = useRef<ForwardedToastProps>(null);

  const [fetchCompany] = useLazyQuery(Api.Companies.GET_COMPANY, {
    variables: {
      id: companyId,
    },
    onCompleted: ({ data: company }) => {
      setCurrentCompany(company);
    },
  });

  const [mutation, { loading }] = useMutation(Api.Companies.UPDATE_CONFIG, {
    onCompleted: () => {
      toastRef.current?.show();

      fetchCompany();
    },
  });

  const formik = useFormik<CompanyConfig>({
    initialValues: {
      ...config,
      roundingMethod: {
        floor: config.roundingMethod?.floor ?? 5,
        none: config.roundingMethod?.none ?? null,
        ceil: config.roundingMethod?.ceil ?? 6,
      },
    },
    onSubmit: (values) => {
      if (!loading) {
        const input = {
          defaultPaymentMethod: values.defaultPaymentMethod,
          profit: Number(values.profit),
          stock: values.stock,
          calculate: values.calculate,
          rounding: values.rounding,
          currency: values.currency,
          roundingMethod: values.roundingMethod,
          allowNegativeStock:
            values.stock === StockType.Unlimited
              ? false
              : values.allowNegativeStock.toString() === "1",
        };

        logEvent("company.config.update", {
          companyId,
          input,
        });

        mutation({
          variables: {
            id: companyId,
            input,
          },
        });
      }
    },
  });

  const onMethodChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    type: "ceil" | "floor"
  ) => {
    if (formik.values.rounding !== "CUSTOM") {
      return null;
    }

    formik.setFieldValue("roundingMethod", {
      ...formik.values.roundingMethod,
      [type]: Number(e.target.value),
    });
  };

  const ceilNumbers = useMemo(() => {
    const ceilNumber = (formik.values.roundingMethod?.floor ?? 1) + 1;

    if (ceilNumber > 9) {
      formik.setFieldValue("roundingMethod", {
        ...formik.values.roundingMethod,
        ceil: null,
      });

      return [];
    }

    formik.setFieldValue("roundingMethod", {
      ...formik.values.roundingMethod,
      ceil: Number(ceilNumber),
    });

    return [ceilNumber];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.roundingMethod?.floor]);

  return (
    <CForm>
      <CRow>
        <CCol md="2">
          <CFormSelect
            disabled={!canEdit}
            name="stock"
            onChange={formik.handleChange}
            value={formik.values.stock}
            defaultValue={formik.values.stock}
            floatingLabel="Stock"
          >
            <option value={StockType.Limited}>Stock Limitado</option>
            <option value={StockType.Mixed}>Stock Mixto</option>
            <option value={StockType.Unlimited}>Stock Ilimitado</option>
          </CFormSelect>
        </CCol>
        {[StockType.Limited, StockType.Mixed].includes(formik.values.stock) && (
          <CCol md="2">
            <CFormSelect
              disabled={!canEdit}
              name="allowNegativeStock"
              onChange={formik.handleChange}
              defaultValue={formik.values.allowNegativeStock ? 1 : 0}
              floatingLabel="¿Stock Negativo?"
            >
              <option value={1}>Si</option>
              <option value={0}>No</option>
            </CFormSelect>
          </CCol>
        )}
        <CCol md="3">
          <CFormSelect
            disabled={!canEdit}
            onChange={formik.handleChange}
            floatingLabel="Método de Pago por Defecto"
            name="defaultPaymentMethod"
            defaultValue={formik.values.defaultPaymentMethod}
          >
            <option value="">Ninguno</option>
            {paymentMethods
              .filter(
                (paymentMethod) =>
                  ![
                    PaymentMethod.Discount,
                    PaymentMethod.CustomerDiscount,
                  ].includes(paymentMethod.type)
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((paymentMethod) => (
                <option value={paymentMethod.type} key={paymentMethod.id}>
                  {paymentMethod.name}
                </option>
              ))}
          </CFormSelect>
        </CCol>
        <CCol md="3">
          <CFormSelect
            floatingLabel="Moneda"
            id="currency"
            name="currency"
            onChange={formik.handleChange}
            value={formik.values.currency}
          >
            <option key="0" value="0">
              Selecciona Moneda
            </option>
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.label} - {currency.symbol}
              </option>
            ))}
          </CFormSelect>
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CCol md="2">
          <CFormSelect
            disabled={!canEdit && !canEditWithFranchise}
            name="calculate"
            floatingLabel="Cálculo automático"
            onChange={formik.handleChange}
            defaultValue={formik.values.calculate}
          >
            <option value="NONE">Sin calcular</option>
            <option value="PROFIT">Ganancia</option>
            <option value="PRICE">Precio</option>
          </CFormSelect>
        </CCol>
        <CCol md="2">
          <CFormSelect
            disabled={!canEdit && !canEditWithFranchise}
            name="rounding"
            floatingLabel="Redondeo"
            onChange={formik.handleChange}
            defaultValue={formik.values.rounding}
          >
            <option value="NONE">Sin redondear</option>
            <option value="CEIL">Hacia Arriba</option>
            <option value="FLOOR">Hacia Abajo</option>
            <option value="CUSTOM">Personalizado</option>
          </CFormSelect>
        </CCol>

        {formik.values.rounding === "CUSTOM" && (
          <>
            <CCol md="2">
              <CFormSelect
                disabled={!canEdit && !canEditWithFranchise}
                name="roundingMethodFloor"
                onChange={(e) => onMethodChange(e, "floor")}
                floatingLabel="Hacia abajo"
                defaultValue={formik.values.roundingMethod?.floor}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
            <CCol md="2">
              <CFormSelect
                disabled
                name="roundingMethodCeil"
                floatingLabel="Hacia arriba"
              >
                {ceilNumbers.map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </CFormSelect>
            </CCol>
          </>
        )}
      </CRow>
      {formik.values.rounding !== "NONE" && (
        <CRow className="mt-3">
          <CCol md="12">
            Ejemplos de Redondeo: <br />
            {roundingExamples(formik.values).map((rounded, index) => {
              return (
                <Fragment key={index}>
                  {rounded.join(" | ")}
                  <br />
                </Fragment>
              );
            })}
          </CCol>
        </CRow>
      )}

      <CRow>
        {!canEdit && !canEditWithFranchise ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            <CLoadingButton
              size="sm"
              loading={loading}
              disabled={loading}
              color="primary"
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>

      <Toast
        color="primary"
        autohide
        visible={false}
        ref={toastRef}
        text="Configuración actualizada"
      />
    </CForm>
  );
};

export default CompanySettings;
