import { useQuery } from "@apollo/client";
import { CFormCheck, CSmartTable } from "@coreui/react-pro";
import Api from "src/api";
import { Company } from "src/api/companies";
import { UserUpdateForm } from "src/api/users";
import { AppLoader } from "src/components/Loader/Loader";
import { GraphQLMeta } from "src/types";

const UserDetail = ({ formik }) => {
  const { data: companies } = useQuery<GraphQLMeta<Company>>(
    Api.Companies.LIST_COMPANIES,
    {
      variables: {
        filters: {
          limit: 0,
        },
      },
    }
  );

  if (!companies?.data.data) {
    return <AppLoader />;
  }

  const changeType = (e, id: number) => {
    const companies: UserUpdateForm["companies"] = formik.values.companies;

    if (e.target.value === "NONE") {
      companies.delete(id);
    } else {
      companies.set(id, e.target.value);
    }

    formik.setFieldValue("companies", companies);
  };

  return (
    <>
      <CSmartTable
        itemsPerPage={companies.data.data.length}
        items={companies.data.data}
        columns={[
          {
            key: "name",
            label: "Empresa",
          },
          {
            key: "owner",
            label: "Dueño",
            _props: {
              className: "text-center",
            },
          },
          {
            key: "branch",
            label: "Franquiciado",
            _props: {
              className: "text-center",
            },
          },
          {
            key: "employee",
            label: "Empleado",
            _props: {
              className: "text-center",
            },
          },
          {
            key: "none",
            label: "Nada",
            _props: {
              className: "text-center",
            },
          },
        ]}
        scopedColumns={{
          name: (item: Company) => (
            <td className="font-weight-bold">
              <span className="font-weight-bold">
                {item.name} {item.description ? `(${item.description})` : ""}
              </span>
            </td>
          ),
          owner: (item: Company) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`company-${item.id}`}
                value="OWNER"
                disabled={item.isFranchise}
                defaultChecked={
                  formik.values.companies.get(item.id) === "OWNER"
                }
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
          branch: (item: Company) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`company-${item.id}`}
                value="BRANCH"
                disabled={!item.isFranchise}
                defaultChecked={
                  formik.values.companies.get(item.id) === "BRANCH"
                }
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
          employee: (item: Company) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`company-${item.id}`}
                value="EMPLOYEE"
                defaultChecked={
                  formik.values.companies.get(item.id) === "EMPLOYEE"
                }
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
          none: (item: Company) => (
            <td className="text-center">
              <CFormCheck
                type="radio"
                name={`company-${item.id}`}
                value="NONE"
                defaultChecked={!formik.values.companies.get(item.id)}
                onChange={(e) => changeType(e, item.id)}
              />
            </td>
          ),
        }}
      />
    </>
  );
};

export default UserDetail;
