import {
  CCol,
  CButton,
  CCardFooter,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
  CAlert,
  CFormSelect,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react-pro";
import { useMutation, useQuery } from "@apollo/client";
import {
  PriceList,
  PriceListCreation,
  PriceListSchema,
} from "src/api/price-lists";
import { GraphQLMeta, GraphQLMutation } from "src/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import Api from "src/api";
import { Store, StoreType } from "src/api/stores";
import { getValidity } from "src/helpers/validation";
import { dateFormat } from "src/helpers/dates";
import { useAdminStore } from "src/store";
import { useMemo } from "react";
import { storesWithPermission } from "src/helpers/permissions";
import { logEvent } from "src/helpers/analytics";

const NewPriceList = () => {
  const location = useLocation();
  const { state } = location;
  const { user } = useAdminStore();
  const navigate = useNavigate();
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          limit: 0,
          type: StoreType.Store,
        },
      },
    }
  );

  const [mutation, { loading, error }] = useMutation(
    Api.PriceLists.CREATE_PRICE_LIST,
    {
      onCompleted: (res: GraphQLMutation<Pick<PriceList, "id">>) => {
        navigate(`/price-lists/${res.data.id}`);
      },
    }
  );

  const formik = useFormik<PriceListCreation>({
    initialValues: {
      name: "",
      profit: 0,
      storeId: state?.storeId ?? 0,
      dateFrom: new Date(),
      dateTo: null,
    },
    onSubmit: (body) => {
      if (!loading) {
        const input = {
          name: body.name,
          profit: Math.abs(Number(body.profit)),
          dateFrom: body.dateFrom,
          dateTo: body.dateTo,
          storeId: Number(body.storeId),
        };

        logEvent("price-list.new-price-list", {
          input,
        });

        mutation({
          variables: {
            input,
          },
        });
      }
    },
    validateOnChange: true,
    validationSchema: PriceListSchema,
  });

  const filteredStores = useMemo(() => {
    if (!stores?.data.data) {
      return [];
    }

    const storesIds = storesWithPermission(
      user?.permissions ?? [],
      "CREATE_PRICE_LIST"
    );

    return stores.data.data
      .filter((store) => user?.isAdmin || storesIds.includes(store.id))
      .sort((a, b) => a.company.name.localeCompare(b.company.name));
  }, [stores?.data.data, user?.isAdmin, user?.permissions]);

  return (
    <CCol lg={12}>
      <CForm onSubmit={formik.handleSubmit}>
        <CCard>
          <CCardHeader>Crear Lista de Precios</CCardHeader>
          <CCardBody>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="name">Nombre</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="name"
                  placeholder="Nombre"
                  name="name"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.name, formik.errors.name)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="storeId">Punto de Venta</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormSelect
                  id="storeId"
                  name="storeId"
                  defaultValue={formik.values.storeId}
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.storeId, formik.errors.storeId)}
                >
                  <option key="0" value="0">
                    Seleccione Punto de Venta
                  </option>
                  {filteredStores.map((store) => (
                    <option key={store.id} value={store.id}>
                      {user?.isAdmin ? `${store?.company?.name} - ` : ""}
                      {store.name}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="profit">Ganancia por Defecto</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="profit"
                  placeholder="0.00"
                  defaultValue={formik.values.profit}
                  name="profit"
                  type="number"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.profit, formik.errors.profit)}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="dateFrom">Fecha de Inicio</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="dateFrom"
                  type="date"
                  placeholder="Fecha de Inicio"
                  name="dateFrom"
                  defaultValue={dateFormat(
                    formik.values.dateFrom,
                    "yyyy-MM-dd"
                  )}
                  onChange={formik.handleChange}
                  {...getValidity(
                    formik.values.dateFrom,
                    formik.errors.dateFrom
                  )}
                />
              </CCol>
            </CRow>
            <CRow className="form-row">
              <CCol md="3">
                <CFormLabel htmlFor="dateTo">Fecha de Fin</CFormLabel>
              </CCol>
              <CCol xs="12" md="9">
                <CFormInput
                  id="dateTo"
                  type="date"
                  placeholder="Fecha de Fin"
                  name="dateTo"
                  onChange={formik.handleChange}
                  {...getValidity(formik.values.dateTo, formik.errors.dateTo)}
                />
              </CCol>
            </CRow>
          </CCardBody>

          <CCardFooter className="px-3">
            <CRow className="align-items-center">
              <CCol md="9">
                {error && (
                  <CAlert color="danger" className="my-0">
                    Ha ocurrido un error. Vuelve a intentar
                  </CAlert>
                )}
              </CCol>
              <CCol md="3" className="flex justify-content-end">
                <CButton size="sm" color="primary" type="submit">
                  Crear
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default NewPriceList;
