import { gql } from "@apollo/client";

export type LoginUser = {
  email: string;
  password: string;
  version: string;
  service: "ADMIN";
};

export const LOGIN = gql`
  mutation ($input: LoginUserInput!) {
    user: loginUser(input: $input) {
      id
      name
      lastname
      email

      isAdmin
      isP

      permissions {
        key
        value
      }

      companies {
        id
        name
        utid
        isFranchise
        paymentStatus {
          statuses {
            companyId
            storeId
            paymentStatus
          }
        }

        config {
          defaultPaymentMethod
          profit
          stock
          calculate
          rounding
          roundingMethod {
            ceil
            none
            floor
          }
        }

        stores {
          id
          name
          type

          connections {
            tiendaNubeConnected
            mercadoLibreConnected
            mercadoShopsConnected
            mercadoPagoConnected
            rappiConnected
          }
        }
      }

      companyUsers {
        companyId
        userId
        role
      }

      storeUsers {
        companyId
        storeId
        userId
        role
      }

      accessToken
    }
  }
`;

export const REFRESH_USER = gql`
  mutation refreshUser($n: Int) {
    user: refreshUser(n: $n) {
      id
      name
      lastname
      email

      isAdmin

      permissions {
        key
        value
      }

      companies {
        id
        name
        utid
        isFranchise

        paymentStatus {
          statuses {
            companyId
            storeId
            paymentStatus
          }
        }

        config {
          defaultPaymentMethod
          profit
          stock
          calculate
          rounding
          roundingMethod {
            ceil
            none
            floor
          }
        }

        stores {
          id
          type

          connections {
            tiendaNubeConnected
            mercadoLibreConnected
            mercadoShopsConnected
            mercadoPagoConnected
            rappiConnected
          }
        }
      }

      accessToken
    }
  }
`;
