import { CCardBody, CSmartTable } from "@coreui/react-pro";
import { Order, OrderItem } from "src/api/orders";
import { formatCurrency } from "src/helpers/numbers";
import { calculateItem, calculateSummary } from "src/helpers/orders";
import SummaryRow from "./SummaryRow";

const ConfirmedOrder = ({ order }: { order: Order }) => {
  const summary = calculateSummary(order.items, order.discount);

  return (
    <CCardBody>
      <CSmartTable
        items={order.items.map((item) => ({
          ...item,
          summary: calculateItem(item, order.status),
        }))}
        columns={[
          {
            key: "quantity",
            label: "Cantidad",
            _props: { className: "text-left" },
          },
          { key: "product", label: "Producto" },
          {
            key: "price",
            label: "Precio",
            _props: { className: "text-right" },
          },
          {
            key: "tax",
            label: "IVA",
            _props: { className: "text-right" },
          },
          {
            key: "subtotal",
            label: "Subtotal",
            _props: { className: "text-right" },
          },
        ]}
        scopedColumns={{
          product: (item: OrderItem) => <td>{item.product.name}</td>,
          quantity: (item: OrderItem) => (
            <td className="text-left">{item.summary.quantity}</td>
          ),
          price: (item: OrderItem) => (
            <td className="text-right">{formatCurrency(item.summary.price)}</td>
          ),
          tax: (item: OrderItem) => (
            <td className="text-right">{formatCurrency(item.summary.tax)}</td>
          ),
          subtotal: (item: OrderItem) => (
            <td className="text-right">
              {formatCurrency(item.summary.subtotal)}
            </td>
          ),
        }}
        tableProps={{
          striped: true,
        }}
      />

      <SummaryRow label="Subtotal" value={summary.subtotal} />
      <SummaryRow label="IVA" value={summary.tax} />
      <SummaryRow label="Descuento" value={summary.discount} />
      <SummaryRow label="Total" value={summary.total} />
    </CCardBody>
  );
};

export default ConfirmedOrder;
