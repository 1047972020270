import { CButton, CFormInput } from "@coreui/react-pro";
import { CFormInputProps } from "@coreui/react-pro/dist/esm/components/form/CFormInput";
import classNames from "classnames";
import Icon from "../Icon";
import { useState } from "react";

const PasswordInput = (props: CFormInputProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="position-relative">
      <CFormInput
        {...props}
        type={isVisible ? "text" : "password"}
        className={classNames(props.className, "with-validation")}
      />
      <CButton
        onClick={() => setIsVisible((prev) => !prev)}
        variant="ghost"
        className="h-full stack-top-right"
      >
        <Icon
          name={isVisible ? "eye-slash" : "eye"}
          containerClassName="w-2 h-2"
          className="w-2 h-2"
          color="#dbdfe6"
        />
      </CButton>
    </div>
  );
};

export default PasswordInput;
