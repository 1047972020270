import { Sale, SaleItem } from "src/api/sales";
import { fixedSum, getTaxValue } from "src/helpers/numbers";

export const useSale = () => {
  const calculateItem = (item: SaleItem) => {
    const tax = getTaxValue(item.price, item.tax);
    const recharge = Number(item.recharge) ?? 0;
    const discount = Number(item.discount) ?? 0;

    const response = {
      price: item.price - tax,
      recharge,
      discount,
      tax,
      total: item.quantity * (item.price - discount + recharge),
    };

    return response;
  };

  const calculateSummary = (sale: Sale, fee: number = 0) => {
    const items = sale.items.map((item) => ({
      ...item,
      summary: calculateItem(item),
    }));

    const response = items.reduce(
      (prev, curr) => ({
        subtotal: prev.subtotal + fixedSum(curr.summary.price * curr.quantity),
        recharge: prev.recharge + curr.summary.recharge,
        saleDiscount: sale.discount,
        itemsDiscount:
          prev.itemsDiscount + fixedSum(curr.summary.discount * curr.quantity),
        customerDiscount: sale.customerDiscount,
        tax: prev.tax + fixedSum(curr.summary.tax * curr.quantity),
        total: prev.total + curr.summary.total,
      }),
      {
        subtotal: 0,
        recharge: 0,
        saleDiscount: 0,
        itemsDiscount: 0,
        customerDiscount: 0,
        tax: 0,
        total: 0,
      }
    );

    return {
      ...response,
      customerDiscount: sale.customerDiscount,
      total:
        response.subtotal +
        response.tax -
        response.itemsDiscount -
        response.saleDiscount -
        fee -
        sale.customerDiscount +
        response.recharge,
    };
  };

  return {
    calculateItem,
    calculateSummary,
  };
};
