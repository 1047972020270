import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
} from "@coreui/react-pro";

export type AlertDialogForwardedRef = {
  show: (title: string, content: string) => void;
  hide: () => void;
};

export type AlertDialogProps = {};

const AlertDialog: ForwardRefRenderFunction<
  AlertDialogForwardedRef,
  AlertDialogProps
> = (_, ref) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const handleClose = useCallback(() => {
    setShowModal(false);
    setTitle("");
    setContent("");
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      show: (title: string, content: string) => {
        setShowModal(true);
        setTitle(title);
        setContent(content);
      },
      hide: () => {
        handleClose();
      },
    }),
    [handleClose]
  );

  return (
    <CModal
      alignment="center"
      visible={showModal}
      onClose={() => {
        handleClose();
      }}
      backdrop="static"
      keyboard={false}
    >
      <CModalHeader closeButton={false}>{title}</CModalHeader>
      <CModalBody>{content}</CModalBody>
      <CModalFooter>
        <CButton size="sm" onClick={() => handleClose()} color="success">
          Cerrar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(AlertDialog);
