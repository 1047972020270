const SvgComponent = ({
  containerClassName,
  size = 24,
}: {
  containerClassName?: string;
  size: number;
}) => (
  <svg
    viewBox="0 0 850.28 267.22"
    height={size}
    width={size}
    className={containerClassName ?? ""}
  >
    <path
      d="M839.23 84.8c-44.73 39.16-84.41 41.62-132.06 6.54-21.91-16.11-41.53-35.31-63.5-51.33-17-12.4-34.96-24.71-54.36-32.37-33.56-13.28-67.61-8.77-98.26 9.86-20.82 12.65-39.79 28.42-59.1 43.45-2.71 2.08-4.74 3.23-6.8 3.14-2.06.09-4.08-1.06-6.8-3.14-19.31-15.03-38.28-30.79-59.1-43.45-30.62-18.62-64.7-23.14-98.27-9.86-19.4 7.66-37.36 19.97-54.36 32.37-21.97 16.02-41.59 35.22-63.5 51.33-47.65 35.08-87.33 32.62-132.06-6.54C7.37 81.57 3.69 78.34 0 75.14c6.66 36.85 21.02 69.9 42.22 99.89 27.05 38.28 62.53 65.7 107.06 80.75 104.09 35.19 235.58-13.86 275.86-106.44C465.42 241.92 596.91 290.97 701 255.78c44.53-15.05 80.01-42.48 107.06-80.75 21.19-29.99 35.56-63.04 42.22-99.89-3.69 3.2-7.37 6.43-11.05 9.66"
      strokeWidth={0}
      fill="#fd6250"
    />
  </svg>
);

export default SvgComponent;
