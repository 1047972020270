import OwnerDashboard from "./OwnerDashboard";
import { memo } from "react";

const AdminDashboard = ({ today = false }: { today?: boolean }) => {
  return (
    <>
      <OwnerDashboard today={today} />
    </>
  );
};

export default memo(AdminDashboard);
