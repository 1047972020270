import { gql } from "@apollo/client";
import { CashRegisterSession } from "./registers";
import { CashRegisterAction, PaymentMethod, Sale, SalePayment } from "./sales";
import { ProductAudit } from "./products";

export const parseAuditAction = (item: ProductAudit) => {
  const { action } = item;

  switch (action) {
    case "CREATE":
      return "Creación";

    case "UPDATE":
      const changes = JSON.parse(item.to);

      if (changes?.deletedAt === null) {
        return "Restauración";
      }

      return "Modificación";

    case "TOUCH":
      return "Modificación";

    case "DELETE":
      return "Eliminación";
  }
};

export const parseAuditChanges = (action: string, values: string) => {
  if (action === "DELETE" || action === "CREATE") {
    return "-";
  }

  const changes = JSON.parse(values);

  return Object.entries(changes)
    .map(([key, value]) => `${key}: ${value}`)
    .join(`\n`);
};

export type AuditRegisterValues = {
  session: CashRegisterSession;
  audit: {
    register: {
      amount: number;
      type: CashRegisterAction;
    }[];
    payments: {
      amount: number;
      type: PaymentMethod;
    }[];
    sales: {
      spent: number;
      itemDiscount: number;
      itemRecharge: number;
      saleDiscount: number;
    };
  };
  sales: Sale[];
  payments: SalePayment[];
};

export const GET_AUDIT = gql`
  query ($id: Int!) {
    auditSession(id: $id) {
      session {
        id
        openedAt
        closedAt

        user {
          name
          lastname
        }

        register {
          id
          name

          store {
            id
            name
          }
        }
      }

      audit {
        register {
          amount
          type
        }

        payments {
          amount
          type
        }

        sales {
          spent
          itemDiscount
          itemRecharge
          saleDiscount
        }
      }

      payments {
        saleId
        type
        amount
      }

      sales {
        id
        discount

        items {
          price
          quantity
          discount
          recharge
        }
      }
    }
  }
`;
