import { gql } from "@apollo/client";
import { Company } from "./companies";
import { Store } from "./stores";

export type TopSellerDate = {
  index: number;
  date: Date;
  amount: number;
  store: Store;
};

export type SalesPerHour = {
  index: number;
  date: string;
  amount: number;
  store: Store;
};

export type TopSellerProduct = {
  index: number;
  id: number;
  name: string;
  amount: number;
  company: Company;
};

export const TOP_SELL_DATES = gql`
  query ($filters: TopSalesFilters) {
    data: topSellDates(filters: $filters) {
      date
      amount
      store {
        id
        name
      }
    }
  }
`;

export const TOP_SELL_PRODUCTS = gql`
  query ($filters: TopSalesFilters) {
    data: topSellProducts(filters: $filters) {
      id
      name
      amount
      company {
        id
        name
      }
    }
  }
`;

export const SELL_PRODUCTS_BY_DATE = gql`
  query ($filters: TopSalesFilters) {
    data: productsSalesByDate(filters: $filters) {
      id
      name
      amount
      company {
        id
        name
      }
    }
  }
`;

export const SALES_PER_HOUR = gql`
  query ($filters: TopSalesFilters) {
    data: salesPerHour(filters: $filters) {
      date
      amount
      store {
        id
        name
      }
    }
  }
`;

export const PRODUCTS_UPDATED = gql`
  query ($filters: TopSalesFilters) {
    data: productsUpdated(filters: $filters) {
      id
      name
      barcodes {
        barcode
      }
      price
      updatedAt
      company {
        id
        name
      }
    }
  }
`;

export const PRODUCTS_SALES = gql`
  query ($productId: Int!) {
    data: productSales(productId: $productId) {
      date
      quantity
      invoiced
      profit
    }
  }
`;
