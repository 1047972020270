import { CContainer, CRow, CSpinner } from "@coreui/react-pro";

type LoaderProps = {
  className?: string;
  color?: string;
  size?: "sm";
};

export const AppLoader = ({ className, color = "primary" }: LoaderProps) => (
  <div
    className={`bg-light h-100 d-flex flex-row align-items-center ${className}`}
  >
    <CContainer>
      <CRow className="justify-content-center">
        <CSpinner color={color} />
      </CRow>
    </CContainer>
  </div>
);

export const Loader = ({ className, size, color = "primary" }: LoaderProps) => (
  <div className={`d-flex flex-row align-items-center ${className}`}>
    <CContainer>
      <CRow className="justify-content-center">
        <CSpinner size={size} color={color} className="p-0" />
      </CRow>
    </CContainer>
  </div>
);
