import { gql } from "@apollo/client";
import { Store } from "./stores";
import { Company, PaymentStatus } from "./companies";
import request from "./request";

export type Plan = {
  id: number;
  key: PlanType;
  name: string;
  price: number;

  users: number;
  support: string;
  customPermissions: boolean;

  invoicing: number;
  fixedPriceList: boolean;
};

export type StorePlan = {
  id: number;
  companyPlanId: number;
  type: PlanType;

  status: PaymentStatus;

  price: number;
  discount: number;
  invoicing: number;
  fixedPriceList: boolean;

  dateFrom: Date;
  dateTo: Date;

  paymentLink: string;
  paymentId: string;

  payedAt: Date | null;
  updatedAt: Date;

  store: Store;
};

export type CompanyPlan = {
  id: number;
  type: PlanType;

  status: PaymentStatus;

  users: number;
  support: string;
  customPermissions: boolean;

  dateFrom: Date;
  dateTo: Date;

  plan: Plan;
  company: Company;
  stores: StorePlan[];
};

export enum PlanType {
  Free = "FREE",
  Trial = "TRIAL",
  Entrepeneur = "ENTREPENEUR",
  Pymes = "PYMES",
  Pro = "PRO",
  Plus = "PLUS",
  Business = "BUSINESS",
  Afip = "AFIP",
  MercadoLibre = "MERCADO_LIBRE",
  MercadoPago = "MERCADO_PAGO",
  MercadoShops = "MERCADO_SHOPS",
  TiendaNube = "TIENDA_NUBE",
  Rappi = "RAPPI",
}

export const LIST_PLANS = gql`
  query plans {
    data: plans {
      data {
        id
        key
        name
        price
        discount

        users
        support
        customPermissions

        invoicing
        fixedPriceList
      }
    }
  }
`;

export const GET_PLAN = gql`
  query plan($id: Int!) {
    data: plan(id: $id) {
      id
      key
      name
      price
      discount

      users
      support
      customPermissions

      invoicing
      fixedPriceList
    }
  }
`;

export const GET_COMPANY_PLAN = gql`
  query companyPlan($companyId: Int!) {
    data: companyPlan(companyId: $companyId) {
      id
      status
      type

      users
      support
      customPermissions

      dateFrom
      dateTo

      stores {
        id
        companyPlanId

        status
        type

        invoicing
        fixedPriceList

        dateFrom
        dateTo

        updatedAt

        store {
          id
          name
        }
      }
    }
  }
`;

export const LIST_COMPANY_PLANS = gql`
  query companyPlans($filters: CompanyPlansFilters) {
    data: companyPlans(filters: $filters) {
      data {
        id
        status
        type

        users
        support
        customPermissions

        dateFrom
        dateTo

        updatedAt

        company {
          name
        }

        stores {
          id
          companyPlanId
          status
          type

          invoicing
          fixedPriceList
          price
          discount

          paymentLink
          paymentId
          payedAt

          dateFrom
          dateTo

          updatedAt

          store {
            id
            name
            type
          }
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlan($id: Int!, $input: UpdatePlanInput!) {
    data: updatePlan(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_COMPANY_PLAN = gql`
  mutation updateCompanyPlan(
    $companyId: Int!
    $input: CompanyPlanUpdateInput!
  ) {
    data: updateCompanyPlan(companyId: $companyId, input: $input) {
      id
    }
  }
`;

export const CREATE_SALE_INVOICE = gql`
  mutation createPlanInvoice(
    $companyId: Int!
    $planId: Int!
    $price: Float!
    $dateFrom: String!
    $dateTo: String!
  ) {
    data: createPlanInvoice(
      companyId: $companyId
      planId: $planId
      price: $price
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
    }
  }
`;

export const EXPORT_INVOICE = (id: string) =>
  request.post(`/plans/invoice/${id}`, {}, { responseType: "blob" });

export const PAY_PLAN = gql`
  mutation payPlan($companyPlanId: Int!, $storePlanId: Int) {
    data: payPlan(companyPlanId: $companyPlanId, storePlanId: $storePlanId) {
      id
    }
  }
`;

export const CREATE_PAYMENT_LINK = gql`
  mutation createPaymentLink($storePlanId: Int!) {
    data: createPaymentLink(storePlanId: $storePlanId) {
      id
    }
  }
`;
