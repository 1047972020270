import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { logEvent } from "src/helpers/analytics";

import { useAdminStore } from "src/store";

const VersionsScreen = () => {
  const { user } = useAdminStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState<Record<string, string>>({});
  const toastRef = useRef<ForwardedToastProps>(null);

  if (!user?.isAdmin) {
    navigate("/");
  }

  const fetchVersions = async () => {
    try {
      const data = await Api.AppData.GET_VERSIONS();

      setVersions(data);
    } catch (e: any) {
      logEvent("versions.error", {
        error: e.message,
      });
    }
  };

  useEffect(() => {
    fetchVersions();
  }, []);

  const handleSubmit = async (key: string) => {
    const version = versions[key];

    try {
      setLoading(true);

      await Api.AppData.UPDATE_VERSION({
        service: key,
        value: version,
      });

      toastRef.current?.show("Versión actualizada correctamente");
    } catch (e: any) {
      logEvent("versions.update.error", {
        key,
        version,
        error: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  if (isEmpty(versions)) {
    return <AppLoader />;
  }

  return (
    <CCol lg={12}>
      <CForm>
        <CCard>
          <CCardHeader>Versiones</CCardHeader>
          <CCardBody>
            {Object.entries(versions).map(([key, value], index) => (
              <CRow className="form-row" key={index}>
                <CCol md="4" className="align-items-center d-flex">
                  <CFormLabel htmlFor={key} className="mb-0">
                    {key}
                  </CFormLabel>
                </CCol>
                <CCol xs="12" md="6">
                  <CFormInput
                    id={key}
                    defaultValue={value}
                    name={key}
                    onChange={(e) => {
                      setVersions((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }));
                    }}
                  />
                </CCol>
                <CCol className="justify-content-end" xs="12" md="2">
                  <CLoadingButton
                    type="button"
                    disabled={loading}
                    loading={loading}
                    onClick={() => handleSubmit(key)}
                    size="sm"
                    color="success"
                  >
                    Guardar
                  </CLoadingButton>
                </CCol>
              </CRow>
            ))}

            <Toast ref={toastRef} text="" />
          </CCardBody>
        </CCard>
      </CForm>
    </CCol>
  );
};

export default VersionsScreen;
