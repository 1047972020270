const parseEmptyString = (value: string | undefined): string => {
  if (!value) {
    return "";
  }

  return value.toString();
};

const parseString = (value: string | undefined): string => {
  return (value ?? "").toString();
};

const parseNumber = (value: string | undefined): number => {
  return Number(value ?? 0);
};

const parseBoolean = (value: string | boolean | undefined): boolean => {
  if (!value) {
    return false;
  }

  if (value === "true" || value === true) {
    return true;
  }

  return false;
};

const Config = {
  useMixpanelAnalytics: parseBoolean(
    process.env.REACT_APP_USE_MIXPANEL_ANALYITCS
  ),
  mixpanelToken: parseString(process.env.REACT_APP_MIXPANEL_TOKEN),

  sessionKey: parseEmptyString(process.env.REACT_APP_SESSION_KEY),

  socketEnabled: parseBoolean(process.env.REACT_APP_SOCKET_ENABLED),
  wsUrl: parseEmptyString(process.env.REACT_APP_WS_API_URL),
  apiUrl: parseEmptyString(process.env.REACT_APP_GRAPHQL_API_URL),

  env: parseString(process.env.NODE_ENV),
  siteEnv: parseString(process.env.REACT_APP_SITE_ENV),
  publicUrl: parseString(process.env.PUBLIC_URL),

  isProduction: parseString(process.env.NODE_ENV) === "production",
  isLocal: parseString(process.env.NODE_ENV) === "local",
  isDev: parseString(process.env.NODE_ENV) === "development",
  isQA: parseString(process.env.NODE_ENV) === "qa",
  isDemo: parseString(process.env.NODE_ENV) === "demo",

  mlClientId: parseString(process.env.REACT_APP_MP_CLIENT_ID),
  mpClientId: parseString(process.env.REACT_APP_MP_CLIENT_ID),
  mpRedirectUrl: parseString(process.env.REACT_APP_ML_REDIRECT_URL),
  mlRedirectUrl: parseString(process.env.REACT_APP_ML_REDIRECT_URL),

  tiendaNubeClientId: parseNumber(process.env.REACT_APP_TN_CLIENT_ID),

  firebaseConfig: {
    apiKey: parseString(process.env.REACT_APP_FIREBASE_API_KEY),
    authDomain: parseString(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN),
    databaseUrl: parseString(process.env.REACT_APP_FIREBASE_DATABASE_URL),
    projectId: parseString(process.env.REACT_APP_FIREBASE_PROJECT_ID),
    storageBucket: parseString(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET),
    messagingSenderId: parseString(
      process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
    ),
    appId: parseString(process.env.REACT_APP_FIREBASE_APP_ID),
    measurementId: parseString(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID),
  },

  whatsAppUrl: `https://api.whatsapp.com/send?phone=5491124087628`,
};

export default Config;
