import { CToast, CToastBody, CToastClose } from "@coreui/react-pro";
import { CToastProps } from "@coreui/react-pro/dist/esm/components/toast/CToast";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

export type ToastProps = Pick<CToastProps, "autohide" | "visible" | "color"> & {
  text: string;
};

export type ForwardedToastProps = {
  show: (t?: string) => void;
  hide: () => void;
};

const Toast: ForwardRefRenderFunction<ForwardedToastProps, ToastProps> = (
  { color = "primary", text = "", visible = false, autohide = true },
  ref
) => {
  const [isVisible, setIsVisible] = useState<boolean>(visible);
  const [innerText, setInnerText] = useState<string>(text);

  useImperativeHandle(
    ref,
    () => ({
      show: (t?: string) => {
        if (t) {
          setInnerText(t);
        }
        setIsVisible(true);
      },
      hide: () => {
        setIsVisible(false);
        setInnerText(text);
      },
    }),
    [text]
  );

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  useEffect(() => {
    setInnerText(text);
  }, [text]);

  return (
    <CToast
      autohide={autohide}
      visible={isVisible}
      color={color}
      delay={3000}
      onClose={() => setIsVisible(false)}
      className="text-white align-items-center stack-top-right"
    >
      <div className="d-flex">
        <CToastBody>{innerText}</CToastBody>
        {!autohide && (
          <CToastClose
            onClick={() => setIsVisible(false)}
            className="me-2 m-auto"
            white
          />
        )}
      </div>
    </CToast>
  );
};

export default forwardRef(Toast);
