import FeatureAlert from "src/containers/FeatureAlert";
import SalesReport from "./components/SalesReport";
import { useAdminStore } from "src/store";
import { PaymentStatus } from "src/api/companies";
import { getCompanyPaymentStatus } from "src/helpers/payments";

const Excels = () => {
  const { currentCompany } = useAdminStore();

  const isDebtor =
    getCompanyPaymentStatus(currentCompany?.paymentStatus) ===
    PaymentStatus.DEBTOR;

  return (
    <>
      <FeatureAlert />

      {!isDebtor && <SalesReport />}
    </>
  );
};

export default Excels;
